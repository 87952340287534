import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LocalAuthService, AuthModel} from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(
        private authService: LocalAuthService
    )
    {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return from(
            this.authService.getLocalAuth()
        ).pipe(
            switchMap(
                (resp: AuthModel) =>
                {
                    if (!req.headers)
                    {
                        req = req.clone({
                            setHeaders: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }
                    if (!req.headers.has('Content-Type') || !req.headers.get('Content-Type') || req.headers.get('Content-Type') === 'text/plain')
                    {
                        req.headers.set('Content-Type', 'application/json');
                    }
                    // ignore google calls
                    if ((resp && resp.getToken() && !resp.tokenExpired()))
                    {
                        req = req.clone({
                            setHeaders: {
                                Authorization: 'Bearer ' + resp.getToken(),
                            }
                        });
                        return next.handle(req);

                    } else
                    {
                        return next.handle(req);
                    }
                }
            )
        );
    }
}
