import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentItemComponent} from './document-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [DocumentItemComponent],
  declarations: [DocumentItemComponent]
})
export class DocumentItemModule { }
