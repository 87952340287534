<div class="d-flex align-items-center auth px-0 mt-5">
  <div class="row w-100 mx-0">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-center py-5 px-4 px-sm-5">
        <div class="brand-logo">
          <img src="assets/images/logo_bookitit.svg" alt="logo">
        </div>
        <h4>You are now logged out</h4>
        <h4>Thank you and have a great day</h4>
      </div>
    </div>
  </div>
</div>
