import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})

export class IframeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    this.getAuth();
  }


  private getAuth(): void {
    window.onmessage = (e) => {
      if (!environment.allowedOrigins.includes(e.origin)) {
        // console.log("not allowed");
        // console.log(e.origin);
        // console.log(environment.allowedOrigins);
         return;
      }
      // skip webpack messages
      if (e.data?.type && e.data.type.includes('webpack')) {
        return;
      }

      let payload;
      try {
        payload = JSON.parse(e.data);
      } catch (exc) {
        console.warn('error ', exc, ' parsing json: ', e.data);
        return;
      }
      const parent = window.parent;
      let returnPayload;
      switch (payload.method) {
        case 'set':
          localStorage.setItem(payload.key, JSON.stringify(payload.data));
          returnPayload = {
            method: 'storage#get',
            data: {'stored': true}
          };
          parent.postMessage(JSON.stringify(returnPayload), '*');
          break;
        case 'get':
          const data = localStorage.getItem(payload.key);
          returnPayload = {
            method: 'storage#get',
            data
          };
          parent.postMessage(JSON.stringify(returnPayload), '*');
          break;
        case 'remove':
          localStorage.removeItem(payload.key);
          break;
      }
    };
  }
}
