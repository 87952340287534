import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgendasAgendaRead} from "../../../swagger/models/agendas-agenda-read";
import {ServicesServicesRead} from "../../../swagger/models/services-services-read";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-summary-cmp',
  templateUrl: './summary-cmp.component.html',
  styleUrls: ['./summary-cmp.component.scss']
})
export class SummaryCmpComponent implements OnInit {
  @Input() currentAgenda: AgendasAgendaRead;
  @Input() stepNumber: number;
  @Input() serviceList: ServicesServicesRead[];
  @Input() timeZoneString: string;
  @Input() showMobile = false;
  @Output() serviceListChange: EventEmitter<ServicesServicesRead[]> = new EventEmitter();
  @Output() serviceUpdate: EventEmitter<ServicesServicesRead> = new EventEmitter();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon('delete',this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/icono-borrar.svg'));
  }

  ngOnInit(): void {
  }

  emitServicesChange(index: number): void {
    this.serviceList.splice(index,1);
    this.serviceListChange.emit(this.serviceList);
  }


}
