import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {LocalAuthService} from "./services/auth.service";
import {STORAGE_KEY_LOGIN,STORAGE_KEY_AUTH,STORAGE_KEY_LANG} from './hooks/constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'demo1';

  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;
  compactMode: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: LocalAuthService
  ) {


  }


  ngOnInit() {
    // Scroll to top after route change
    this.activatedLayout();
    this.checkUrl();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.initTranslate();
    });
  }

  initTranslate() {

    if (localStorage.getItem(STORAGE_KEY_LANG)) {
      let lang = localStorage.getItem(STORAGE_KEY_LANG);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
    } else {
      if (this.translate.getBrowserLang() !== undefined) {
        this.translate.setDefaultLang(this.translate.getBrowserLang());
        this.translate.use(this.translate.getBrowserLang());
      } else {
        this.translate.use(this.translate.getDefaultLang());
      }
    }
  }

  activatedLayout() {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    this.authService.getLocalAuth().then((local) => {
      if (local) {
        this.compactMode = local.getCompact();
      }
      let domainOrigin = location.ancestorOrigins?.length ?? 1;
      this.compactMode = this.compactMode == true && domainOrigin > 0;
      if (this.compactMode) {
        this.showSidebar = false;
        this.showNavbar = false;
        this.showFooter = false;
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');

      } else {
        document.querySelector('body').classList.remove('body-app');
      }


    }).catch((error) => {
      console.log("error", error);
      this.checkUrl();
    })


    // Spinner for lazyload modules

  }

  checkUrl() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if ((event['url'] == '/login') || (event['url'] == '/error-pages/404') || (event['url'] == '/wizard')|| (event['url'] == '/logout')) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg',);
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if ((event['url'] == '/error-pages/404') ) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });
    this.router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
  }
}
