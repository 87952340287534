import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-widget-card',
  templateUrl: './widget-card.component.html',
  styleUrls: ['./widget-card.component.scss']
})
export class WidgetCardComponent implements OnInit {
  @Input() title: string;
  @Input() icon:string;
  @Input() dataText:string;
  @Input() dataNumber:string;
  constructor() { }

  ngOnInit(): void {
  }

}
