/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GCalendarItemGcRead } from '../models/gcalendar-item-gc-read';
import { GCalendarItemGcList } from '../models/gcalendar-item-gc-list';
import { GCalendarItemGcRead_gcList } from '../models/gcalendar-item-gc-read-_gc-list';
import { GCalendarItemGcWrite } from '../models/gcalendar-item-gc-write';
@Injectable({
  providedIn: 'root',
})
class GCalendarItemService extends __BaseService {
  static readonly syncCalGCalendarItemItemPath = '/api/calendarSync/{id}';
  static readonly getGCalendarItemCollectionPath = '/api/g-calendar-items';
  static readonly postGCalendarItemCollectionPath = '/api/g-calendar-items';
  static readonly getGCalendarItemItemPath = '/api/g-calendar-items/{id}';
  static readonly putGCalendarItemItemPath = '/api/g-calendar-items/{id}';
  static readonly deleteGCalendarItemItemPath = '/api/g-calendar-items/{id}';
  static readonly listCalendarGCalendarItemCollectionPath = '/api/google/calendar/list/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves a GCalendarItem resource.
   * @param id undefined
   * @return GCalendarItem resource response
   */
  syncCalGCalendarItemItemResponse(id: string): __Observable<__StrictHttpResponse<GCalendarItemGcRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendarSync/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GCalendarItemGcRead>;
      })
    );
  }
  /**
   * Retrieves a GCalendarItem resource.
   * @param id undefined
   * @return GCalendarItem resource response
   */
  syncCalGCalendarItemItem(id: string): __Observable<GCalendarItemGcRead> {
    return this.syncCalGCalendarItemItemResponse(id).pipe(
      __map(_r => _r.body as GCalendarItemGcRead)
    );
  }

  /**
   * Retrieves the collection of GCalendarItem resources.
   * @return GCalendarItem collection response
   */
  getGCalendarItemCollectionResponse(): __Observable<__StrictHttpResponse<Array<GCalendarItemGcList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/g-calendar-items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GCalendarItemGcList>>;
      })
    );
  }
  /**
   * Retrieves the collection of GCalendarItem resources.
   * @return GCalendarItem collection response
   */
  getGCalendarItemCollection(): __Observable<Array<GCalendarItemGcList>> {
    return this.getGCalendarItemCollectionResponse().pipe(
      __map(_r => _r.body as Array<GCalendarItemGcList>)
    );
  }

  /**
   * Creates a GCalendarItem resource.
   * @param gCalendarItem The new GCalendarItem resource
   * @return GCalendarItem resource created
   */
  postGCalendarItemCollectionResponse(gCalendarItem?: GCalendarItemGcWrite): __Observable<__StrictHttpResponse<GCalendarItemGcRead_gcList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = gCalendarItem;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/g-calendar-items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GCalendarItemGcRead_gcList>;
      })
    );
  }
  /**
   * Creates a GCalendarItem resource.
   * @param gCalendarItem The new GCalendarItem resource
   * @return GCalendarItem resource created
   */
  postGCalendarItemCollection(gCalendarItem?: GCalendarItemGcWrite): __Observable<GCalendarItemGcRead_gcList> {
    return this.postGCalendarItemCollectionResponse(gCalendarItem).pipe(
      __map(_r => _r.body as GCalendarItemGcRead_gcList)
    );
  }

  /**
   * retrives specific google calendar item data
   * @param id undefined
   * @return GCalendarItem resource response
   */
  getGCalendarItemItemResponse(id: string): __Observable<__StrictHttpResponse<GCalendarItemGcRead_gcList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/g-calendar-items/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GCalendarItemGcRead_gcList>;
      })
    );
  }
  /**
   * retrives specific google calendar item data
   * @param id undefined
   * @return GCalendarItem resource response
   */
  getGCalendarItemItem(id: string): __Observable<GCalendarItemGcRead_gcList> {
    return this.getGCalendarItemItemResponse(id).pipe(
      __map(_r => _r.body as GCalendarItemGcRead_gcList)
    );
  }

  /**
   * Update specific google calendar item data
   * @param params The `GCalendarItemService.PutGCalendarItemItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `gCalendarItem`: The updated GCalendarItem resource
   *
   * @return GCalendarItem resource updated
   */
  putGCalendarItemItemResponse(params: GCalendarItemService.PutGCalendarItemItemParams): __Observable<__StrictHttpResponse<GCalendarItemGcRead_gcList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.gCalendarItem;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/g-calendar-items/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GCalendarItemGcRead_gcList>;
      })
    );
  }
  /**
   * Update specific google calendar item data
   * @param params The `GCalendarItemService.PutGCalendarItemItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `gCalendarItem`: The updated GCalendarItem resource
   *
   * @return GCalendarItem resource updated
   */
  putGCalendarItemItem(params: GCalendarItemService.PutGCalendarItemItemParams): __Observable<GCalendarItemGcRead_gcList> {
    return this.putGCalendarItemItemResponse(params).pipe(
      __map(_r => _r.body as GCalendarItemGcRead_gcList)
    );
  }

  /**
   * Delete specific google calendar item data
   * @param id undefined
   */
  deleteGCalendarItemItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/g-calendar-items/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific google calendar item data
   * @param id undefined
   */
  deleteGCalendarItemItem(id: string): __Observable<null> {
    return this.deleteGCalendarItemItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Retrieves the collection of GCalendarItem resources.
   * @param id undefined
   * @return GCalendarItem collection response
   */
  listCalendarGCalendarItemCollectionResponse(id: string): __Observable<__StrictHttpResponse<Array<GCalendarItemGcList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/google/calendar/list/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GCalendarItemGcList>>;
      })
    );
  }
  /**
   * Retrieves the collection of GCalendarItem resources.
   * @param id undefined
   * @return GCalendarItem collection response
   */
  listCalendarGCalendarItemCollection(id: string): __Observable<Array<GCalendarItemGcList>> {
    return this.listCalendarGCalendarItemCollectionResponse(id).pipe(
      __map(_r => _r.body as Array<GCalendarItemGcList>)
    );
  }
}

module GCalendarItemService {

  /**
   * Parameters for putGCalendarItemItem
   */
  export interface PutGCalendarItemItemParams {
    id: string;

    /**
     * The updated GCalendarItem resource
     */
    gCalendarItem?: GCalendarItemGcWrite;
  }
}

export { GCalendarItemService }
