<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile" *ngIf="showProfile">
      <a href="javascript:;" class="nav-link">
        <div class="nav-profile-image">
          <img src="assets/images/faces/face1.jpg" alt="profile">
          <span class="login-status online"></span> <!--change to offline or busy as needed-->
        </div>
        <div class="nav-profile-text">
          <span class="font-weight-bold mb-2">David Grey. H</span>
          <span class="text-secondary text-small">Project Manager</span>
        </div>
        <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': dashboard.isActive }">
      <a class="nav-link" routerLink="/dashboard" routerLinkActive #dashboard="routerLinkActive">
        <i class="mdi mdi-home menu-icon"></i>
        <span class="menu-title header-style">{{"side_bar.panel"|translate}}</span>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': calendarSync.isActive }">
      <a class="nav-link" routerLink="/google-sync" routerLinkActive #calendarSync="routerLinkActive">
        <i class="mdi mdi-calendar menu-icon"></i>
        <span class="menu-title header-style">{{"side_bar.calendars_sync"|translate}}</span>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': booking.isActive }">
      <a class="nav-link" routerLink="/booking-online" routerLinkActive #booking="routerLinkActive">
        <i class="mdi mdi-ballot-outline menu-icon"></i>
        <span class="menu-title header-style">{{"side_bar.booking_online"|translate}}</span>
      </a>
    </li>

  </ul>
</nav>
