import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetsLabelsWlRead} from "../../../../swagger/models/widgets-labels-wl-read";
@Component({
  selector: 'app-tab3-cmp',
  templateUrl: './tab3-cmp.component.html',
  styleUrls: ['./tab3-cmp.component.scss']
})
export class Tab3CmpComponent implements OnInit {
  @Input() widgetLabel: WidgetsLabelsWlRead;
  @Input() loading = false;
  @Output() saveWidgetLabel: EventEmitter<WidgetsLabelsWlRead> = new EventEmitter();
  @Output() showSpinner: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }


  saveData(): void {
    this.showSpinner.emit(true);
    this.loading = true;
    console.log(this.widgetLabel);
    this.saveWidgetLabel.emit(this.widgetLabel);
  }

}
