import {Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProgressBarMode} from "@angular/material/progress-bar";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

const AGENDA_STEP = "agenda";
const SCHEDULE_STEP = "schedule";
const SERVICE_STEP = "services";
const USER_STEP = "user";
const _CSS_STEP_COMPLETED = "step--complete";
const _CSS_STEP_ACTIVE = "step--active";
const _CSS_STEP_INCOMPLETE = "step--incomplete"
const _CSS_STEP_INACTIVE = "step--inactive";

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent implements OnInit {
  public loadingState;
  public startWidth;

  constructor(
    public dialogRef: MatDialogRef<ProgressDialogComponent>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loadingState = this.data.loadingState;
    this.matIconRegistry.addSvgIcon('success', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/wizard/Step_03.svg'));

  }

  ngOnInit(): void {
    this.startWidth = 0;
  }


  updateProgressBar(data: any): void {
    this.loadingState = data;
  }

  getClassForStep(step: string) {
    switch (step) {
      case AGENDA_STEP:
        if (this.loadingState === 1) {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_ACTIVE;
        } else {
          return _CSS_STEP_COMPLETED + " " + _CSS_STEP_INACTIVE;
        }

      case SCHEDULE_STEP:
        if (this.loadingState === 2) {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_ACTIVE;
        } else if (this.loadingState > 2) {
          return _CSS_STEP_COMPLETED + " " + _CSS_STEP_INACTIVE;
        } else {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_INACTIVE;
        }
      case SERVICE_STEP:
        if (this.loadingState === 3) {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_ACTIVE;
        } else if (this.loadingState > 3) {
          return _CSS_STEP_COMPLETED + " " + _CSS_STEP_INACTIVE;
        } else {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_INACTIVE;
        }
      case USER_STEP:
        if (this.loadingState === 4) {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_ACTIVE;
        } else if (this.loadingState > 4) {
          return _CSS_STEP_COMPLETED + " " + _CSS_STEP_INACTIVE;
        } else {
          return _CSS_STEP_INCOMPLETE + " " + _CSS_STEP_INACTIVE;
        }
    }
  }


}
