
<div class="container mt-5 mb-5" >
  <div class="section-container-first">
      <div class="checkbox-item small-input" *ngIf="currentUser!=undefined">
        <mat-checkbox class="mr-2" [(ngModel)]="registrationType">{{'booking-online.advanced_text1'|translate}}</mat-checkbox>
        <mat-form-field appearance="fill" class="mr-2">
          <input matInput type="number" [(ngModel)]="currentUser.limitAppointments" [disabled]="!registrationType">
        </mat-form-field>
        <mat-label>{{'booking-online.advanced_text2'|translate}}</mat-label>
        <mat-form-field appearance="fill" class="ml-2 mr-2" >
          <input matInput type="number" [(ngModel)]="currentUser.limitTime" [disabled]="!registrationType">
        </mat-form-field>
        <mat-label>{{'booking-online.time_minutes'|translate}}</mat-label>
      </div>
      <div class="checkbox-item">
        <mat-checkbox class="mr-2" [(ngModel)]="currentUser.limitOptions" >{{'booking-online.advanced_text3'|translate}}</mat-checkbox>
      </div>
    <div class="select-container" *ngIf="widgetConfig!=undefined">
      <mat-label>{{'booking-online.select_text1'|translate}}</mat-label>
      <mat-form-field appearance="fill" class="ml-4">
        <mat-select class="bkt-select" [(value)]="widgetConfig.registrationType" [compareWith]="compareNumbers">
          <mat-option [value]=0>
            {{'booking-online.advanced_option2'|translate}}
          </mat-option>
          <mat-option [value]=1>
            {{'booking-online.advanced_option1'|translate}}
          </mat-option>
          <mat-option [value]=2>
            {{'booking-online.advanced_option3'|translate}}
          </mat-option>
          <mat-option [value]=3>
            {{'booking-online.advanced_option3.1'|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="select-container" *ngIf="widgetConfig!=undefined">
      <mat-label>{{'booking-online.select_text2'|translate}}</mat-label>
      <mat-form-field appearance="fill" class="ml-4">
        <mat-select class="bkt-select" [(value)]="widgetConfig.anyAgenda" [compareWith]="compareNumbers">
          <mat-option [value]=0>
            {{'booking-online.advanced_option4'|translate}}
          </mat-option>
          <mat-option [value]=1>
            {{'booking-online.advanced_option5'|translate}}
          </mat-option>
          <mat-option [value]=2>
            {{'booking-online.advanced_option6'|translate}}
          </mat-option>
          <mat-option [value]=3>
            {{'booking-online.advanced_option7'|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="select-container" *ngIf="widgetConfig!=undefined">
      <mat-label>{{'booking-online.select_text3'|translate}}</mat-label>
      <mat-form-field appearance="fill" class="ml-4">
        <mat-select class="bkt-select" [(value)]="widgetConfig.showFreeSlots" [compareWith]="compareNumbers">
          <mat-option [value]=0>
            {{'booking-online.advanced_option8'|translate}}
          </mat-option>
          <mat-option [value]=1>
            {{'booking-online.advanced_option9'|translate}}
          </mat-option>
          <mat-option [value]=2>
            {{'booking-online.advanced_option10'|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="checkbox-group" *ngIf="widgetConfig!=undefined">
      <div class="checkbox-item">
        <mat-checkbox class="mr-2" [(ngModel)]="widgetConfig.showComments">{{'booking-online.checkbox_text1'|translate}}</mat-checkbox>
      </div>
      <div class="checkbox-item">
        <mat-checkbox class="mr-2" [(ngModel)]="widgetConfig.mandatoryComments">{{'booking-online.checkbox_text2'|translate}}</mat-checkbox>
      </div>
      <div class="checkbox-item">
        <mat-checkbox class="mr-2">{{'booking-online.checkbox_text3'|translate}}</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="section-container-second mt-5" *ngIf="!loading">
    <h3>{{'booking-online.section_title'|translate}}</h3>
    <div class="select-container mt-4">
      <mat-label>{{'booking-online.select_text4'|translate}}</mat-label>
      <mat-form-field appearance="fill" class="ml-4">
        <mat-select class="bkt-select" [value]="currenUserLang" (selectionChange)="selectLang($event.value)" [compareWith]="compareLang">
          <mat-option *ngFor="let lang of langList" [value]="lang">
            {{'lang.'+lang.code|translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-image-container">
      <div class="textarea-group text-area" *ngIf="widgetLabel!=undefined">
        <mat-label>{{'booking-online.text_area_title'|translate}}</mat-label>
        <mat-form-field class="mt-3" appearance="fill">
          <textarea matInput placeholder="{{'booking-online.text_area_placeholder'|translate}}" [(ngModel)]="widgetLabel.labelUsersComments"></textarea>
        </mat-form-field>
      </div>
      <div class="image" *ngIf="currentUser!=undefined">
        <img class="img-bkt" [src]="getSourceImage('img_02.svg')">
      </div>
    </div>
    <div class="form-image-container mt-4" *ngIf="widgetLabel!=undefined">
      <div class="textarea-group text-area">
        <div class="checkbox-item">
          <mat-checkbox class="mr-2" [(ngModel)]="widgetLabel.showExtraInfo">{{'booking-online.checkbox_text4'|translate}}</mat-checkbox>
        </div>
        <div class="select-container mt-3">
          <mat-radio-group [(ngModel)]="widgetLabel.showAt" [disabled]="!widgetLabel.showExtraInfo">
            <mat-radio-button [value]=1> {{'booking-online.advanced_option11'|translate}}</mat-radio-button>
            <mat-radio-button [value]=2>{{'booking-online.advanced_option12'|translate}}</mat-radio-button>
            <mat-radio-button [value]=3> {{'booking-online.advanced_option13'|translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field class="mt-3" appearance="fill">
          <textarea matInput placeholder="{{'booking-online.text_area_placeholder2'|translate}}"  [(ngModel)]="widgetLabel.extraInfo"></textarea>
        </mat-form-field>
      </div>
      <div class="image" *ngIf="currentUser!=undefined">
        <img class="img-bkt" [src]="getSourceImage('img_03.svg')">
      </div>
    </div>
    <div class="button-container">
      <button class="btn btn-bkt__outline-dark mr-3">{{'widget-history.cancel_button'|translate}}</button>
      <button class="btn btn-inverse-success" (click)="saveData()">{{'general.save'|translate}}</button>
    </div>
  </div>
</div>
