/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BlocksBlocksList } from '../models/blocks-blocks-list';
import { BlocksBlocksRead } from '../models/blocks-blocks-read';
import { BlocksBlocksWrite } from '../models/blocks-blocks-write';
@Injectable({
  providedIn: 'root',
})
class BlocksService extends __BaseService {
  static readonly getBlocksCollectionPath = '/api/blocks';
  static readonly postBlocksCollectionPath = '/api/blocks';
  static readonly getBlocksItemPath = '/api/blocks/{id}';
  static readonly putBlocksItemPath = '/api/blocks/{id}';
  static readonly deleteBlocksItemPath = '/api/blocks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Blocks resources.
   * @param params The `BlocksService.GetBlocksCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `agendas.id`:
   *
   * @return Blocks collection response
   */
  getBlocksCollectionResponse(params: BlocksService.GetBlocksCollectionParams): __Observable<__StrictHttpResponse<Array<BlocksBlocksList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.agendasId != null) __params = __params.set('agendas.id', params.agendasId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/blocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BlocksBlocksList>>;
      })
    );
  }
  /**
   * Retrieves the collection of Blocks resources.
   * @param params The `BlocksService.GetBlocksCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `agendas.id`:
   *
   * @return Blocks collection response
   */
  getBlocksCollection(params: BlocksService.GetBlocksCollectionParams): __Observable<Array<BlocksBlocksList>> {
    return this.getBlocksCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<BlocksBlocksList>)
    );
  }

  /**
   * Creates a Blocks resource.
   * @param blocks The new Blocks resource
   * @return Blocks resource created
   */
  postBlocksCollectionResponse(blocks?: BlocksBlocksWrite): __Observable<__StrictHttpResponse<BlocksBlocksRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = blocks;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/blocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BlocksBlocksRead>;
      })
    );
  }
  /**
   * Creates a Blocks resource.
   * @param blocks The new Blocks resource
   * @return Blocks resource created
   */
  postBlocksCollection(blocks?: BlocksBlocksWrite): __Observable<BlocksBlocksRead> {
    return this.postBlocksCollectionResponse(blocks).pipe(
      __map(_r => _r.body as BlocksBlocksRead)
    );
  }

  /**
   * retrieves specific blocks data
   * @param id undefined
   * @return Blocks resource response
   */
  getBlocksItemResponse(id: string): __Observable<__StrictHttpResponse<BlocksBlocksRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/blocks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BlocksBlocksRead>;
      })
    );
  }
  /**
   * retrieves specific blocks data
   * @param id undefined
   * @return Blocks resource response
   */
  getBlocksItem(id: string): __Observable<BlocksBlocksRead> {
    return this.getBlocksItemResponse(id).pipe(
      __map(_r => _r.body as BlocksBlocksRead)
    );
  }

  /**
   * Update specific blocks data
   * @param params The `BlocksService.PutBlocksItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `blocks`: The updated Blocks resource
   *
   * @return Blocks resource updated
   */
  putBlocksItemResponse(params: BlocksService.PutBlocksItemParams): __Observable<__StrictHttpResponse<BlocksBlocksRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.blocks;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/blocks/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BlocksBlocksRead>;
      })
    );
  }
  /**
   * Update specific blocks data
   * @param params The `BlocksService.PutBlocksItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `blocks`: The updated Blocks resource
   *
   * @return Blocks resource updated
   */
  putBlocksItem(params: BlocksService.PutBlocksItemParams): __Observable<BlocksBlocksRead> {
    return this.putBlocksItemResponse(params).pipe(
      __map(_r => _r.body as BlocksBlocksRead)
    );
  }

  /**
   * Delete specific blocks data
   * @param id undefined
   */
  deleteBlocksItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/blocks/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific blocks data
   * @param id undefined
   */
  deleteBlocksItem(id: string): __Observable<null> {
    return this.deleteBlocksItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BlocksService {

  /**
   * Parameters for getBlocksCollection
   */
  export interface GetBlocksCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    agendasId?: any;
  }

  /**
   * Parameters for putBlocksItem
   */
  export interface PutBlocksItemParams {
    id: string;

    /**
     * The updated Blocks resource
     */
    blocks?: BlocksBlocksWrite;
  }
}

export { BlocksService }
