/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExternalAccountExternalAccountRead } from '../models/external-account-external-account-read';
import { ExternalAccountNewExternalAccountDTOExternalAccountWrite } from '../models/external-account-new-external-account-dtoexternal-account-write';
import { ExternalAccountExternalAccountWrite } from '../models/external-account-external-account-write';
@Injectable({
  providedIn: 'root',
})
class ExternalAccountService extends __BaseService {
  static readonly postExternalAccountCollectionPath = '/api/external-accounts';
  static readonly getExternalAccountItemPath = '/api/external-accounts/{id}';
  static readonly deleteExternalAccountItemPath = '/api/external-accounts/{id}';
  static readonly putExternalAccountItemPath = '/api/external-accounts/{id}';
  static readonly patchExternalAccountItemPath = '/api/external-accounts/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a ExternalAccount resource.
   * @param externalAccount The new ExternalAccount resource
   * @return ExternalAccount resource created
   */
  postExternalAccountCollectionResponse(externalAccount?: ExternalAccountNewExternalAccountDTOExternalAccountWrite): __Observable<__StrictHttpResponse<ExternalAccountExternalAccountRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = externalAccount;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/external-accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalAccountExternalAccountRead>;
      })
    );
  }
  /**
   * Creates a ExternalAccount resource.
   * @param externalAccount The new ExternalAccount resource
   * @return ExternalAccount resource created
   */
  postExternalAccountCollection(externalAccount?: ExternalAccountNewExternalAccountDTOExternalAccountWrite): __Observable<ExternalAccountExternalAccountRead> {
    return this.postExternalAccountCollectionResponse(externalAccount).pipe(
      __map(_r => _r.body as ExternalAccountExternalAccountRead)
    );
  }

  /**
   * Retrieves a ExternalAccount resource.
   * @param id undefined
   * @return ExternalAccount resource response
   */
  getExternalAccountItemResponse(id: string): __Observable<__StrictHttpResponse<ExternalAccountExternalAccountRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/external-accounts/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalAccountExternalAccountRead>;
      })
    );
  }
  /**
   * Retrieves a ExternalAccount resource.
   * @param id undefined
   * @return ExternalAccount resource response
   */
  getExternalAccountItem(id: string): __Observable<ExternalAccountExternalAccountRead> {
    return this.getExternalAccountItemResponse(id).pipe(
      __map(_r => _r.body as ExternalAccountExternalAccountRead)
    );
  }

  /**
   * Removes the ExternalAccount resource.
   * @param id undefined
   */
  deleteExternalAccountItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/external-accounts/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes the ExternalAccount resource.
   * @param id undefined
   */
  deleteExternalAccountItem(id: string): __Observable<null> {
    return this.deleteExternalAccountItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Replaces the ExternalAccount resource.
   * @param params The `ExternalAccountService.PutExternalAccountItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `externalAccount`: The updated ExternalAccount resource
   *
   * @return ExternalAccount resource updated
   */
  putExternalAccountItemResponse(params: ExternalAccountService.PutExternalAccountItemParams): __Observable<__StrictHttpResponse<ExternalAccountExternalAccountRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.externalAccount;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/external-accounts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalAccountExternalAccountRead>;
      })
    );
  }
  /**
   * Replaces the ExternalAccount resource.
   * @param params The `ExternalAccountService.PutExternalAccountItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `externalAccount`: The updated ExternalAccount resource
   *
   * @return ExternalAccount resource updated
   */
  putExternalAccountItem(params: ExternalAccountService.PutExternalAccountItemParams): __Observable<ExternalAccountExternalAccountRead> {
    return this.putExternalAccountItemResponse(params).pipe(
      __map(_r => _r.body as ExternalAccountExternalAccountRead)
    );
  }

  /**
   * Updates the ExternalAccount resource.
   * @param params The `ExternalAccountService.PatchExternalAccountItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `externalAccount`: The updated ExternalAccount resource
   *
   * @return ExternalAccount resource updated
   */
  patchExternalAccountItemResponse(params: ExternalAccountService.PatchExternalAccountItemParams): __Observable<__StrictHttpResponse<ExternalAccountExternalAccountRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.externalAccount;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/external-accounts/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalAccountExternalAccountRead>;
      })
    );
  }
  /**
   * Updates the ExternalAccount resource.
   * @param params The `ExternalAccountService.PatchExternalAccountItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `externalAccount`: The updated ExternalAccount resource
   *
   * @return ExternalAccount resource updated
   */
  patchExternalAccountItem(params: ExternalAccountService.PatchExternalAccountItemParams): __Observable<ExternalAccountExternalAccountRead> {
    return this.patchExternalAccountItemResponse(params).pipe(
      __map(_r => _r.body as ExternalAccountExternalAccountRead)
    );
  }
}

module ExternalAccountService {

  /**
   * Parameters for putExternalAccountItem
   */
  export interface PutExternalAccountItemParams {
    id: string;

    /**
     * The updated ExternalAccount resource
     */
    externalAccount?: ExternalAccountExternalAccountWrite;
  }

  /**
   * Parameters for patchExternalAccountItem
   */
  export interface PatchExternalAccountItemParams {
    id: string;

    /**
     * The updated ExternalAccount resource
     */
    externalAccount?: ExternalAccountExternalAccountWrite;
  }
}

export { ExternalAccountService }
