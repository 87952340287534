/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoogleSyncGoogleRead } from '../models/google-sync-google-read';
import { GoogleSyncGoogleWrite } from '../models/google-sync-google-write';
@Injectable({
  providedIn: 'root',
})
class GoogleSyncService extends __BaseService {
  static readonly getGoogleSyncCollectionPath = '/api/google-syncs';
  static readonly postGoogleSyncCollectionPath = '/api/google-syncs';
  static readonly getGoogleSyncItemPath = '/api/google-syncs/{id}';
  static readonly putGoogleSyncItemPath = '/api/google-syncs/{id}';
  static readonly deleteGoogleSyncItemPath = '/api/google-syncs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of GoogleSync resources.
   * @return GoogleSync collection response
   */
  getGoogleSyncCollectionResponse(): __Observable<__StrictHttpResponse<Array<GoogleSyncGoogleRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/google-syncs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GoogleSyncGoogleRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of GoogleSync resources.
   * @return GoogleSync collection response
   */
  getGoogleSyncCollection(): __Observable<Array<GoogleSyncGoogleRead>> {
    return this.getGoogleSyncCollectionResponse().pipe(
      __map(_r => _r.body as Array<GoogleSyncGoogleRead>)
    );
  }

  /**
   * Creates a GoogleSync resource.
   * @param googleSync The new GoogleSync resource
   * @return GoogleSync resource created
   */
  postGoogleSyncCollectionResponse(googleSync?: GoogleSyncGoogleWrite): __Observable<__StrictHttpResponse<GoogleSyncGoogleRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = googleSync;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/google-syncs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoogleSyncGoogleRead>;
      })
    );
  }
  /**
   * Creates a GoogleSync resource.
   * @param googleSync The new GoogleSync resource
   * @return GoogleSync resource created
   */
  postGoogleSyncCollection(googleSync?: GoogleSyncGoogleWrite): __Observable<GoogleSyncGoogleRead> {
    return this.postGoogleSyncCollectionResponse(googleSync).pipe(
      __map(_r => _r.body as GoogleSyncGoogleRead)
    );
  }

  /**
   * retrives specific google sync data
   * @param id undefined
   * @return GoogleSync resource response
   */
  getGoogleSyncItemResponse(id: string): __Observable<__StrictHttpResponse<GoogleSyncGoogleRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/google-syncs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoogleSyncGoogleRead>;
      })
    );
  }
  /**
   * retrives specific google sync data
   * @param id undefined
   * @return GoogleSync resource response
   */
  getGoogleSyncItem(id: string): __Observable<GoogleSyncGoogleRead> {
    return this.getGoogleSyncItemResponse(id).pipe(
      __map(_r => _r.body as GoogleSyncGoogleRead)
    );
  }

  /**
   * Update specific google sync data
   * @param params The `GoogleSyncService.PutGoogleSyncItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `googleSync`: The updated GoogleSync resource
   *
   * @return GoogleSync resource updated
   */
  putGoogleSyncItemResponse(params: GoogleSyncService.PutGoogleSyncItemParams): __Observable<__StrictHttpResponse<GoogleSyncGoogleRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.googleSync;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/google-syncs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoogleSyncGoogleRead>;
      })
    );
  }
  /**
   * Update specific google sync data
   * @param params The `GoogleSyncService.PutGoogleSyncItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `googleSync`: The updated GoogleSync resource
   *
   * @return GoogleSync resource updated
   */
  putGoogleSyncItem(params: GoogleSyncService.PutGoogleSyncItemParams): __Observable<GoogleSyncGoogleRead> {
    return this.putGoogleSyncItemResponse(params).pipe(
      __map(_r => _r.body as GoogleSyncGoogleRead)
    );
  }

  /**
   * Delete specific google sync data
   * @param id undefined
   */
  deleteGoogleSyncItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/google-syncs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific google sync data
   * @param id undefined
   */
  deleteGoogleSyncItem(id: string): __Observable<null> {
    return this.deleteGoogleSyncItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GoogleSyncService {

  /**
   * Parameters for putGoogleSyncItem
   */
  export interface PutGoogleSyncItemParams {
    id: string;

    /**
     * The updated GoogleSync resource
     */
    googleSync?: GoogleSyncGoogleWrite;
  }
}

export { GoogleSyncService }
