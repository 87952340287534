import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientsClientRead} from '../../../swagger/models/clients-client-read';
import {ClientsService} from '../../../swagger/services/clients.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientHistoryClientHRead} from '../../../swagger/models/client-history-client-hread';
import {ClientHistoryService} from '../../../swagger/services/client-history.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ClientHistoryClientHWrite} from '../../../swagger/models/client-history-client-hwrite';
import {ToastrService} from 'ngx-toastr';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import PutClientHistoryItemParams = ClientHistoryService.PutClientHistoryItemParams;
import {DeleteDialogComponent, DialogData} from '../../components/delete-dialog/delete-dialog.component';
import {ErrorStateMatcher} from '@angular/material/core';
import {FileSaverService} from 'ngx-filesaver';
import {ClientHistoryDataHistoryRead} from '../../../swagger/models/client-history-data-history-read';
import {ClientHistoryDataService} from '../../../swagger/services/client-history-data.service';
import GetClientHistoryDataCollectionParams = ClientHistoryDataService.GetClientHistoryDataCollectionParams;
import GetClientHistoryCollectionParams = ClientHistoryService.GetClientHistoryCollectionParams;
import PutClientHistoryDataItemParams = ClientHistoryDataService.PutClientHistoryDataItemParams;
import {SpinnerService} from '../../services/spinner.service';
import {DocumentService} from '../../../swagger/services/document.service';
import {DocumentHistoryRead} from '../../../swagger/models/document-history-read';
import {DialogService} from '../../providers/dialog.service';
import {
  ClientHistoryDataNewHistoryDataDTOHistoryWrite
} from '../../../swagger/models/client-history-data-new-history-data-dtohistory-write';
import {Utils} from '../../helpers/utils';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-customer-history',
  templateUrl: './customer-history.component.html',
  styleUrls: ['./customer-history.component.scss']
})
export class CustomerHistoryComponent implements OnInit {
  public id: string;
  public loading = true;
  public customer: ClientsClientRead;
  public showAddHistory = false;
  public editToogleHistory = false;
  public general_error_msg: string;
  public general_update_msg: string;
  public general_delete_msg: string;
  public next_msg: string;
  public prev_msg: string;
  public selectedHistory: ClientHistoryClientHRead;
  public selectedDataHistory: ClientHistoryDataHistoryRead;
  public listHistory: ClientHistoryClientHRead[] = [];
  public historyDataList: ClientHistoryDataHistoryRead[] = [];
  public historyControl = new FormControl('', [Validators.required]);
  public dataControl = new FormControl('', [Validators.required]);
  public newDocuments: File[] = [];
  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  public selectedHistoryControl = new FormControl('', [Validators.required]);
  public page = 1;
  public itemsPerPage = 10;
  public totalItem = 0;
  matcher = new MyErrorStateMatcher();

  constructor(
    private customerService: ClientsService,
    private customerHistory: ClientHistoryService,
    private historyDataService: ClientHistoryDataService,
    private documentService: DocumentService,
    private toast: ToastrService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    protected router: Router,
    private dialogService: DialogService,
    private spinnerService: SpinnerService,
    private fileSaverService: FileSaverService
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.general_error_msg = this.translateService.instant('customer_track.general_error_msg');
      this.general_update_msg = this.translateService.instant('customer_track.update_msg');
      this.general_delete_msg = this.translateService.instant('customer_track.delete_msg');
      this.next_msg = this.translateService.instant('customer_track.next');
      this.prev_msg = this.translateService.instant('customer_track.prev');
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getCustomerData();
  }

  public getCustomerData(): void {
    this.customerService.getClientsItem(this.id).subscribe((resp) => {
      this.customer = resp;
      this.getHistoryList(this.customer.id);
    }, error => {
      console.log(error);
      this.processError(error);
    });
  }

  public addCustomerHistory(): void {
    const data: ClientHistoryClientHWrite = {
      name: this.historyControl.value,
      clients: {
        id: this.customer.id,
      }
    } as ClientHistoryClientHWrite;
    this.customerHistory.postClientHistoryCollection(data).subscribe((response) => {
      this.selectedHistory = response;
      this.showAddHistory = false;
      this.historyDataList = [];
      this.getHistoryList(this.customer.id);

    }, error => {
      console.error(error);
      this.useToastMsg(this.general_error_msg, false);
    });
  }

  public getHistoryList(clientID): void {
    const params: GetClientHistoryCollectionParams = {
      clientsId: clientID,
    } as GetClientHistoryCollectionParams;
    this.customerHistory.getClientHistoryCollection(params).subscribe((response) => {
      this.listHistory = response;
      if (this.listHistory.length == 0) {
        this.showAddHistory = false;
      }
      this.loading = false;
    }, error => {
      console.error(error);
      this.useToastMsg(this.general_error_msg, false);
    });
  }

  public getHistoryDataList(clientHistoryId): void {
    const params: GetClientHistoryDataCollectionParams = {
      page: this.page,
      clientHistoryId,
    } as GetClientHistoryDataCollectionParams;
    this.historyDataService.getClientHistoryDataCollection(params).subscribe((resp) => {
      this.historyDataList = resp;
      this.spinnerService.closeSpinner();
      this.updateTotalItem();
    }, error => {
      console.error(error);
      this.useToastMsg(this.general_error_msg, false);
    });
  }

  public navigateHistoryDataList($event): void {
    this.page = $event;
    this.getHistoryDataList(this.selectedHistory.id);
  }

  public updateTotalItem(): void {
    if (this.page == 1) {
      this.totalItem = 0;
    }
    if (this.historyDataList.length > 0 && this.historyDataList.length < this.itemsPerPage) {
      this.totalItem += this.historyDataList.length;
    } else {
      this.totalItem += this.historyDataList.length + 1;
    }
  }

  public selectHistory($event): void {
    this.selectedHistory = $event;
    this.page = 1;
    this.totalItem = 0;
    this.showAddHistory = false;
    this.getHistoryDataList(this.selectedHistory.id.toString());
  }

  public addHistoryData(): void {
    const ctx = this;
    ctx.spinnerService.showSpinner();

    // build multipart form data
    const formData: FormData = new FormData();
    formData.append('note', ctx.dataControl.value);
    formData.append('historyId', ctx.selectedHistory.id.toString());

    for (const newDocument of ctx.newDocuments) {
      formData.append('documents[]', newDocument);
    }

    ctx.historyDataService.postClientHistoryDataCollection(formData as ClientHistoryDataNewHistoryDataDTOHistoryWrite).subscribe((resp) => {
      ctx.newDocuments = [];
      ctx.dataControl.reset();
      ctx.getHistoryDataList(ctx.selectedHistory.id.toString());
    }, error => {
      console.error(error);
      ctx.spinnerService.closeSpinner();
      ctx.useToastMsg(ctx.general_error_msg, false);
    });
  }

  public updateHistoryData(dataItem: ClientHistoryDataHistoryRead): void {
    const params: PutClientHistoryDataItemParams = {
      id: dataItem.id.toString(),
      clientHistoryData: {
        note: dataItem.note,
      },
    } as PutClientHistoryDataItemParams;
    this.spinnerService.showSpinner();
    this.historyDataService.putClientHistoryDataItem(params).subscribe((resp) => {
      this.selectedDataHistory = undefined;
      this.dataControl.reset();
      this.getHistoryDataList(this.selectedHistory.id.toString());
      this.useToastMsg(this.general_update_msg, true);
    }, error => {
      console.error(error);
      this.useToastMsg(this.general_error_msg, false);
    });
  }

  refreshHistoryData(dataItem: ClientHistoryDataHistoryRead): void {
    const ctx = this;

    // soft update without reload all data
    const historyDataItemIdx = ctx.historyDataList.findIndex(item => item.id === dataItem.id);
    if (historyDataItemIdx >= 0) {
      ctx.historyDataList[historyDataItemIdx] = dataItem;
      ctx.historyDataList = Object.assign([], ctx.historyDataList);
    }
  }

  public deleteHistoryData(dataItem: ClientHistoryDataHistoryRead): void {
    this.spinnerService.showSpinner();
    const data: DialogData = {
      title: this.translateService.instant('delete_history_dialog.title_history_data'),
      content: undefined,
      buttonText: this.translateService.instant('delete_history_dialog.delete_text'),
    } as DialogData;
    this.dialogService.showDialog(DeleteDialogComponent, data).then((result) => {
      if (result == true) {
        this.historyDataService.deleteClientHistoryDataItem(dataItem.id.toString()).subscribe((resp) => {
          this.getHistoryDataList(this.selectedHistory.id.toString());
          this.useToastMsg(this.general_delete_msg, true);
          this.spinnerService.closeSpinner();
        }, error => {
          console.error(error);
          this.useToastMsg(this.general_error_msg, false);
          this.spinnerService.closeSpinner();
        });
      } else {
        this.spinnerService.closeSpinner();
      }
    }, reason => {
      console.log(reason);
      this.spinnerService.closeSpinner();
    });
  }

  downloadDocument(ev: {
    document: DocumentHistoryRead,
  }): void {
    const ctx = this;
    ctx.spinnerService.showSpinner();
    ctx.documentService.downloadDocumentItem(ev.document.id.toString()).subscribe({
      next: response => {
        if (response?.url) {
          window.open(response?.url, '_blank');
        }
        ctx.spinnerService.closeSpinner();
      },
      error: err => {
        console.error('error downloading document', err);
        ctx.toast.error(Utils.getErrorMessageFromResponse(err), 'Error descargando fichero');
        ctx.spinnerService.closeSpinner();
      },
    });
  }

  deleteDocument(ev: {
    history: ClientHistoryDataHistoryRead,
    document: DocumentHistoryRead,
  }): void {
    const ctx = this;
    ctx.spinnerService.showSpinner();
    const data: DialogData = {
      title: ctx.translateService.instant('documents.delete_dialog.title'),
      content: ctx.translateService.instant('documents.delete_dialog.content'),
      buttonText: ctx.translateService.instant('documents.delete_dialog.button_text'),
    } as DialogData;
    ctx.dialogService.showDialog(DeleteDialogComponent, data).then((result) => {
      if (result === true) {
        ctx.documentService.deleteDocumentItem(ev.document.id.toString()).subscribe({
          next: deleteDocumentResponse => {
            // soft remove document without reload all data
            const historyDataItem = ctx.historyDataList.find(item => item.id === ev.history.id);
            if (historyDataItem) {
              historyDataItem.documents = historyDataItem.documents.filter(item => item.id !== ev.document.id);
            }
            ctx.spinnerService.closeSpinner();
          },
          error: deleteDocumentError => {
            console.log('delete document error', deleteDocumentError);
            ctx.toast.error(
              Utils.getErrorMessageFromResponse(deleteDocumentError),
              ctx.translateService.instant('general.general_error_msg')
            );
            ctx.spinnerService.closeSpinner();
          }
        });
      } else {
        ctx.spinnerService.closeSpinner();
      }
    }, reason => {
      console.log('dialog reject', reason);
      ctx.spinnerService.closeSpinner();
    });
  }

  public editSelectedHistory(): void {
    this.editToogleHistory = true;
    this.selectedHistoryControl.setValue(this.selectedHistory.name);
  }

  public updateSelectedHistory(): void {
    const params: PutClientHistoryItemParams = {
      id: this.selectedHistory.id.toString(),
      clientHistory: {
        name: this.selectedHistoryControl.value,
      },
    } as PutClientHistoryItemParams;
    this.spinnerService.showSpinner();
    this.customerHistory.putClientHistoryItem(params).subscribe((resp) => {
      this.selectedHistory = resp;
      this.editToogleHistory = false;
      this.getHistoryList(this.customer.id);
      this.useToastMsg(this.general_update_msg, true);
    }, error => {
      console.error(error);
      this.processError(error);
    });
  }

  public deleteSelectedHistory(): void {
    const data: DialogData = {
      title: this.translateService.instant('delete_history_dialog.title_client_history'),
      content: this.translateService.instant('delete_history_dialog.content_client_history'),
      buttonText: this.translateService.instant('delete_history_dialog.delete_text'),
    } as DialogData;
    this.dialogService.showDialog(DeleteDialogComponent, data).then((result) => {
      if (result == true) {
        this.deleteCustomerHistory();
      }
    }, reason => {
      console.log(reason);
    });
  }


  private deleteCustomerHistory(): void {
    this.customerHistory.deleteClientHistoryItem(this.selectedHistory.id.toString()).subscribe((resp) => {
      this.selectedHistory = undefined;
      this.historyDataList = [];
      this.toast.success(this.general_delete_msg);
      this.getHistoryList(this.customer.id);
    }, error => {
      console.log(error);
      this.toast.error(this.general_error_msg);
    });
  }

  useToastMsg(msg: string, success: boolean): void {
    if (success) {
      this.toast.success(msg);
    } else {
      this.toast.error(msg);
    }
  }

  processError(error): void {
    switch (error.status) {
      case 403:
        const msg = this.translateService.instant('general.error_denied');
        this.useToastMsg(msg, false);
        break;
      case 401:
        const data: DialogData = {
          title: 'Sesion Expirada',
          content: 'Su sesion ha expirado',
          buttonText: 'Salir',
        } as DialogData;
        this.dialogService.showDialog(DeleteDialogComponent, data).then((result) => {
          if (result == true) {
            this.router.navigate(['/logout']);
          }
        }, reason => {
          console.log(reason);
        });
        break;
      case 428:
        if (error.error?.reason === 'EXTERNAL_AUTH_REQUIRED') {
          // ToDo - Open Google auth dialog
          return;
        }
        break;
      default:
        this.useToastMsg(this.general_error_msg, false);
        break;
    }
  }

  handleError(error: any, callback: (value: any) => void): void {
    if (error?.error instanceof Blob) {
      error.error.text().then(res => {
        callback(res);
      });
    } else {
      callback(error);
    }
  }

  downloadHistory(): void {
    const ctx = this;
    ctx.spinnerService.showSpinner();
    ctx.customerHistory.exportClientHistoryItem(this.selectedHistory.id.toString()).subscribe({
        next: data => {
          const filename = ctx.customer.id + '-' + ctx.customer.name + '-history-' + ctx.selectedHistory.id + '-' + ctx.selectedHistory.name;
          ctx.fileSaverService.save(data, filename);
          ctx.spinnerService.closeSpinner();
        }, error: error => {
          ctx.handleError(error, value => {
            console.log('download error', value);
            ctx.toast.error(
              Utils.getErrorMessageFromResponse(value),
              ctx.translateService.instant('general.general_error_msg')
            );
            ctx.spinnerService.closeSpinner();
          });
        }
    });
  }

  addDocument(): void {
    this.fileDropRef.nativeElement.click();
  }

  selectFile(ev: any): void {
    const ctx = this;
    const files = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;

    if (files?.length > 0) {
      const newFile = files[0];
      const idx = ctx.newDocuments.findIndex(item => {
        return (item.name === newFile.name && item.size === newFile.size && item.lastModified === newFile.lastModified);
      });
      if (idx < 0) { // not duped
        ctx.newDocuments.push(newFile);
      }
    }
  }

  removeNewDocument(i: number): void {
    this.newDocuments.splice(i, 1);
  }
}
