/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventsEventRead } from '../models/events-event-read';
import { EventsEventWrite } from '../models/events-event-write';
@Injectable({
  providedIn: 'root',
})
class EventsService extends __BaseService {
  static readonly getEventsCollectionPath = '/api/events';
  static readonly postEventsCollectionPath = '/api/events';
  static readonly getEventsItemPath = '/api/events/{id}';
  static readonly putEventsItemPath = '/api/events/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Events resources.
   * @param params The `EventsService.GetEventsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Events collection response
   */
  getEventsCollectionResponse(params: EventsService.GetEventsCollectionParams): __Observable<__StrictHttpResponse<Array<EventsEventRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/events`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventsEventRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Events resources.
   * @param params The `EventsService.GetEventsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Events collection response
   */
  getEventsCollection(params: EventsService.GetEventsCollectionParams): __Observable<Array<EventsEventRead>> {
    return this.getEventsCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<EventsEventRead>)
    );
  }

  /**
   * Creates a Events resource.
   * @param events The new Events resource
   * @return Events resource created
   */
  postEventsCollectionResponse(events?: EventsEventWrite): __Observable<__StrictHttpResponse<EventsEventRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = events;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/events`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventsEventRead>;
      })
    );
  }
  /**
   * Creates a Events resource.
   * @param events The new Events resource
   * @return Events resource created
   */
  postEventsCollection(events?: EventsEventWrite): __Observable<EventsEventRead> {
    return this.postEventsCollectionResponse(events).pipe(
      __map(_r => _r.body as EventsEventRead)
    );
  }

  /**
   * retrives specific event bkt data
   * @param id undefined
   * @return Events resource response
   */
  getEventsItemResponse(id: string): __Observable<__StrictHttpResponse<EventsEventRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/events/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventsEventRead>;
      })
    );
  }
  /**
   * retrives specific event bkt data
   * @param id undefined
   * @return Events resource response
   */
  getEventsItem(id: string): __Observable<EventsEventRead> {
    return this.getEventsItemResponse(id).pipe(
      __map(_r => _r.body as EventsEventRead)
    );
  }

  /**
   * Update specific event bkt data
   * @param params The `EventsService.PutEventsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `events`: The updated Events resource
   *
   * @return Events resource updated
   */
  putEventsItemResponse(params: EventsService.PutEventsItemParams): __Observable<__StrictHttpResponse<EventsEventRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.events;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/events/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventsEventRead>;
      })
    );
  }
  /**
   * Update specific event bkt data
   * @param params The `EventsService.PutEventsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `events`: The updated Events resource
   *
   * @return Events resource updated
   */
  putEventsItem(params: EventsService.PutEventsItemParams): __Observable<EventsEventRead> {
    return this.putEventsItemResponse(params).pipe(
      __map(_r => _r.body as EventsEventRead)
    );
  }
}

module EventsService {

  /**
   * Parameters for getEventsCollection
   */
  export interface GetEventsCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putEventsItem
   */
  export interface PutEventsItemParams {
    id: string;

    /**
     * The updated Events resource
     */
    events?: EventsEventWrite;
  }
}

export { EventsService }
