import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApikeysService} from "../../../../swagger/services/apikeys.service";
import {ApikeysApikeyRead} from "../../../../swagger/models/apikeys-apikey-read";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ApikeysApikeyWrite} from "../../../../swagger/models/apikeys-apikey-write";

@Component({
  selector: 'app-tab4-cmp',
  templateUrl: './tab4-cmp.component.html',
  styleUrls: ['./tab4-cmp.component.scss']
})
export class Tab4CmpComponent implements OnChanges {
  @Input() apiKeysList: ApikeysApikeyRead[] = [];
  public filteredApiKeys:ApikeysApikeyRead[]=[];
  public apiKeyItem:ApikeysApikeyWrite = {} as ApikeysApikeyWrite;
  @Output() createApiKey: EventEmitter<ApikeysApikeyWrite>= new EventEmitter();
  @Output() showSpinner: EventEmitter<any> = new EventEmitter();
  @Output() deleteApiKey: EventEmitter<ApikeysApikeyRead> = new EventEmitter();
  displayedColumns: string[] = ['domain', 'public_key', 'private_key', 'action']

  constructor(
    private apiKeyService: ApikeysService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/icono-borrar.svg'));
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.filterApiKeys();
  }

  public filterApiKeys(): void {
    console.log("apiKeyList:"+this.apiKeysList);
    this.filteredApiKeys = this.apiKeysList.filter((i) => {
      return i.type == 1
    });
  }

  saveData(): void {
    this.showSpinner.emit(true);
    this.createApiKey.emit(this.apiKeyItem);
  }
  delete(item:ApikeysApikeyRead){
    this.showSpinner.emit(true);
    this.deleteApiKey.emit(item);
  }

}
