<div class="modal-body">
  <div class="loader-container bkt-spinner mb-4">
    <mat-progress-spinner [mode]="'indeterminate'" diameter="40" color="primary"></mat-progress-spinner>
    <span class="text-primary mt-2">{{'general.saving'|translate}}</span>
  </div>
  <div class="step-container">
    <ul class="steps">
      <li class="step" [ngClass]="getClassForStep('agenda')" >
        <span class="step__icon"></span>
        <span class="step__label"> {{'wizard.agenda'|translate}}</span>
      </li>
      <li class="step" [ngClass]="getClassForStep('schedule')" >
        <span class="step__icon"></span>
        <span class="step__label"> {{'wizard.schedule'|translate}}</span>
      </li>
      <li class="step" [ngClass]="getClassForStep('services')" >
        <span class="step__icon"></span>
        <span class="step__label">{{'wizard.services'|translate}}</span>
      </li>
      <li class="step" [ngClass]="getClassForStep('user')">
        <span class="step__icon"></span>
        <span class="step__label">{{'wizard.center'|translate}}</span>
      </li>
    </ul>
  </div>
</div>
