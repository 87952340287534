<div class="container-md bg-white ">
  <div class="card">
    <div class="text-container mt-3">
      <h2 class="ml-2 page-title font-weight-bold">{{'booking-online.title'|translate}}</h2>
      <p class="text-secondary ml-2 mt-2">
        <span>{{'booking-online.text_1'|translate}}</span>
        <br>
        <span>{{'booking-online.text_2'|translate}}</span>
        <br>
        <span>{{'booking-online.text_3'|translate}}</span>
      </p>
    </div>
    <ngx-spinner></ngx-spinner>
    <mat-tab-group class="bkt-tab-material">

      <mat-tab label="{{'booking-online.tab1_title'|translate}}">
        <app-tab1-cmp [langList]="langList" [currentUser]="currentUser" [apiKeys]="apiKeys"
                      *ngIf="currentUser!=undefined"></app-tab1-cmp>
      </mat-tab>
      <mat-tab *ngIf="false" label="{{'booking-online.tab2_title'|translate}}">

      </mat-tab>
      <mat-tab label="{{'booking-online.tab3_title'|translate}}">

        <app-tab2-cmp [langList]="langList"
                      [currentUser]="currentUser"
                      [widgetLabel]="widgetLabel"
                      [loading]="loading"
                      (saveWidgetLabel)="prepareWidgetLabelData($event)"
                      (showSpinner)="showSpinnerAction()"
                      *ngIf="itsReadyToShow()"></app-tab2-cmp>
      </mat-tab>
      <mat-tab label="{{'booking-online.tab4_title'|translate}}">
        <app-tab3-cmp
          [widgetLabel]="widgetLabel"
          (saveWidgetLabel)="prepareWidgetLabelData($event)"
          (showSpinner)="showSpinnerAction()"
          *ngIf="itsReadyToShow()"></app-tab3-cmp>
      </mat-tab>
      <mat-tab label="{{'booking-online.tab5_title'|translate}}">
        <app-tab4-cmp
          [apiKeysList]="apiKeys"
          (createApiKey)="createApiKey($event)"
          (showSpinner)="showSpinnerAction()"
          (deleteApiKey)="deleteApiKey($event)"
          *ngIf="apiKeys!=undefined"
        ></app-tab4-cmp>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
