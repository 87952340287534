export class Utils {
  public static getErrorMessageFromResponse(err: any): string {
    let message = '';

    if (err && err?.error) {
      if (err.error?.error) { // error from api?
        message += err.error?.error;
      } else if (err.error?.detail) {
        message += err.error?.detail;
      } else {
        message = err.error;
      }
    }
    return message;
  }

}
