/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EventslogsEventLRead } from '../models/eventslogs-event-lread';
import { EventslogsEventLWrite } from '../models/eventslogs-event-lwrite';
@Injectable({
  providedIn: 'root',
})
class EventslogsService extends __BaseService {
  static readonly getEventslogsCollectionPath = '/api/eventslogs';
  static readonly postEventslogsCollectionPath = '/api/eventslogs';
  static readonly getEventslogsItemPath = '/api/eventslogs/{id}';
  static readonly putEventslogsItemPath = '/api/eventslogs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Eventslogs resources.
   * @param params The `EventslogsService.GetEventslogsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `events.id`:
   *
   * @return Eventslogs collection response
   */
  getEventslogsCollectionResponse(params: EventslogsService.GetEventslogsCollectionParams): __Observable<__StrictHttpResponse<Array<EventslogsEventLRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.eventsId != null) __params = __params.set('events.id', params.eventsId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/eventslogs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EventslogsEventLRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Eventslogs resources.
   * @param params The `EventslogsService.GetEventslogsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `events.id`:
   *
   * @return Eventslogs collection response
   */
  getEventslogsCollection(params: EventslogsService.GetEventslogsCollectionParams): __Observable<Array<EventslogsEventLRead>> {
    return this.getEventslogsCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<EventslogsEventLRead>)
    );
  }

  /**
   * Creates a Eventslogs resource.
   * @param eventslogs The new Eventslogs resource
   * @return Eventslogs resource created
   */
  postEventslogsCollectionResponse(eventslogs?: EventslogsEventLWrite): __Observable<__StrictHttpResponse<EventslogsEventLRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = eventslogs;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/eventslogs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventslogsEventLRead>;
      })
    );
  }
  /**
   * Creates a Eventslogs resource.
   * @param eventslogs The new Eventslogs resource
   * @return Eventslogs resource created
   */
  postEventslogsCollection(eventslogs?: EventslogsEventLWrite): __Observable<EventslogsEventLRead> {
    return this.postEventslogsCollectionResponse(eventslogs).pipe(
      __map(_r => _r.body as EventslogsEventLRead)
    );
  }

  /**
   * retrives specific eventLog bkt data
   * @param id undefined
   * @return Eventslogs resource response
   */
  getEventslogsItemResponse(id: string): __Observable<__StrictHttpResponse<EventslogsEventLRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/eventslogs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventslogsEventLRead>;
      })
    );
  }
  /**
   * retrives specific eventLog bkt data
   * @param id undefined
   * @return Eventslogs resource response
   */
  getEventslogsItem(id: string): __Observable<EventslogsEventLRead> {
    return this.getEventslogsItemResponse(id).pipe(
      __map(_r => _r.body as EventslogsEventLRead)
    );
  }

  /**
   * Update specific eventLog bkt data
   * @param params The `EventslogsService.PutEventslogsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `eventslogs`: The updated Eventslogs resource
   *
   * @return Eventslogs resource updated
   */
  putEventslogsItemResponse(params: EventslogsService.PutEventslogsItemParams): __Observable<__StrictHttpResponse<EventslogsEventLRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.eventslogs;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/eventslogs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EventslogsEventLRead>;
      })
    );
  }
  /**
   * Update specific eventLog bkt data
   * @param params The `EventslogsService.PutEventslogsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `eventslogs`: The updated Eventslogs resource
   *
   * @return Eventslogs resource updated
   */
  putEventslogsItem(params: EventslogsService.PutEventslogsItemParams): __Observable<EventslogsEventLRead> {
    return this.putEventslogsItemResponse(params).pipe(
      __map(_r => _r.body as EventslogsEventLRead)
    );
  }
}

module EventslogsService {

  /**
   * Parameters for getEventslogsCollection
   */
  export interface GetEventslogsCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    eventsId?: any;
  }

  /**
   * Parameters for putEventslogsItem
   */
  export interface PutEventslogsItemParams {
    id: string;

    /**
     * The updated Eventslogs resource
     */
    eventslogs?: EventslogsEventLWrite;
  }
}

export { EventslogsService }
