<script type="text/javascript">
    /* <![CDATA[ */
    var google_conversion_id = 1016478153;
    var google_conversion_language = "en";
    var google_conversion_format = "3";
    var google_conversion_color = "ffffff";
    var google_conversion_label = "m29_CNfdxAcQyfPY5AM";
    var google_conversion_value = 0;
    var google_remarketing_only = false;
    /* ]]> */
    </script>
<script type="text/javascript" src="//www.googleadservices.com/pagead/conversion.js">
</script>
<noscript>
  <div style="display:inline;">
    <img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/1016478153/?value=0&amp;label=m29_CNfdxAcQyfPY5AM&amp;guid=ON&amp;script=0"/>
  </div>
</noscript>
<div class="container mt-4">
  <ngx-spinner></ngx-spinner>
  <app-step-progress-cmp [showModal]="showModalPRogress" [loadingState]="loadingState"></app-step-progress-cmp>
  <div class="row card" *ngIf="!loading">
    <div class="col-12 mx-auto">
      <div class="auth-form-light text-center">
        <div class="brand-logo mt-5">
          <img src="assets/images/logo_bookitit.svg" alt="logo">
        </div>
        <div class="stepper mt-3">
          <mat-stepper linear="true" labelPosition="bottom" color="accent" #stepper disableRipple="true">
            <mat-step [stepControl]="agendaFormGroup" editable="false">
              <form [formGroup]="agendaFormGroup">
                <ng-template matStepLabel>{{'wizard.agenda' | translate}}</ng-template>
                <ng-template matStepperIcon="active" class="bkt-icon">
                  <mat-icon  svgIcon="active"></mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done" class="bkt-icon">
                  <mat-icon svgIcon="success"></mat-icon>
                </ng-template>
                <ng-template matStepperIcon="number" class="bkt-icon">
                  <mat-icon svgIcon="inactive"></mat-icon>
                </ng-template>
                <div class="form-group mt-3 mb-5 form-container">
                  <h3 class="header-style"><strong>{{'wizard.welcome_label'| translate}}</strong></h3>
                  <h5 class="header-style">{{'wizard.text_label1'| translate}}</h5>
                  <div class="input-container bkt-input  mt-2">
                    <mat-form-field appearance="fill" class="input-history wd-40 mt-0"
                                    *ngIf="timeZoneList.length!=0">
                      <mat-label>{{'wizard.place_holder_select'|translate}}</mat-label>
                      <input matInput type="text" formControlName="timeZoneControl" [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="timeZoneSelectedChange($event)"
                                        [displayWith]="displayFn">
                        <mat-option *ngFor="let timeZone of filteredTimeZoneList | async" [value]="timeZone">
                          {{timeZone.timezone}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="input-history wd-40 mt-0">
                      <mat-label>{{'wizard.text_workerName'|translate}}</mat-label>
                      <input matInput [(ngModel)]="agenda.name" formControlName="agendaCtrl">
                    </mat-form-field>
                    <button mat-button class="btn btn-inverse-success" matStepperNext
                            [disabled]="!agendaFormGroup.valid"
                            (click)="changeStepNumber(1)">{{'wizard.button_save'| translate}}</button>
                  </div>
                </div>
              </form>
            </mat-step>
            <mat-step editable="false" [stepControl]="scheduleFormGroup">
              <form [formGroup]="scheduleFormGroup">
                <ng-template matStepLabel>{{'wizard.schedule'|translate}}</ng-template>
                <div class="form-group mt-3">
                  <h6 class="text-uppercase mt-3 mb-3 text-left pl-3">{{'wizard.select_schedule' |translate}} <span
                    class="text-primary">{{agenda.name}} </span></h6>
                  <app-schedule-cmp class="mb-4" [showMobile]="isMobile()" formControlName="hiddenCtrl"
                                    (scheduleChange)="updateSchedule($event)"></app-schedule-cmp>
                  <button mat-button [disabled]="!scheduleFormGroup.valid" class="btn btn-inverse-success ml-2 mt-3"
                          matStepperNext (click)="changeStepNumber(1)">{{'wizard.button_save'| translate}}</button>
                </div>
              </form>
            </mat-step>
            <mat-step [stepControl]="serviceFormGroup" editable="false" [completed]="stepNumber>3">
              <form [formGroup]="serviceFormGroup">
                <ng-template matStepLabel>{{'wizard.services'|translate}}</ng-template>
                <h6 class="text-uppercase mt-3 mb-3 text-left">{{'wizard.services_text' |translate}} <span
                  class="text-primary">{{agenda.name}} </span>?</h6>
                <div class="page-container mt-3">
                  <div class="form-group w-100 mr-2 pr-lg-5">
                    <div class="input-container mb-2">
                      <span class="text-dark text-left mb-3">{{'wizard.services_title'| translate}}</span>
                      <mat-form-field appearance="fill">
                        <mat-label>*{{'wizard.services_name_text'|translate}}</mat-label>
                        <input matInput [(ngModel)]="currentService.name" formControlName="nameCtrl">
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                        <mat-label>*{{'wizard.services_time_text'|translate}}</mat-label>
                        <input matInput type="number" [(ngModel)]="currentService.time"  [min]="5" step="5" formControlName="timeCtrl">
                      </mat-form-field>
                    </div>
                    <div class="button-container">
                      <button mat-button [disabled]="!serviceFormGroup.valid"
                              class="btn btn-bkt__outline-dark mb-2"
                              (click)="addNewService()">{{'wizard.services_button_text'| translate}}</button>
                      <span>o</span>
                      <button mat-button matStepperNext class="btn btn-inverse-success mt-3"
                              [disabled]="!serviceFormGroup.valid"
                              (click)="saveServiceAndFinish()"
                      >{{'wizard.button_save'| translate}}</button>
                    </div>
                  </div>
                  <app-summary-cmp class="w-100" [currentAgenda]="agenda"
                                   [showMobile]="isMobile()"
                                   (serviceListChange)="serviceListUpdate($event)" [serviceList]="serviceList"
                                   [timeZoneString]="getTimeZoneString()" [stepNumber]="stepNumber"></app-summary-cmp>
                </div>
              </form>
            </mat-step>
            <mat-step editable="false" [completed]="stepNumber==4">
                <div class="page-container mt-3 mb-5">
                  <ng-template matStepLabel>{{'wizard.summary_title'|translate}}</ng-template>
                  <div class="summary-container w-100 mr-6 mb-4">
                    <div class="figure-img summary-img mb-4 mt-4">
                      <img src="assets/images/wizard/launch.svg">
                    </div>
                    <h2 class="font-weight-bold header-style text-uppercase">{{'wizard.summary_text'|translate}}</h2>
                    <button class="btn btn-bkt__outline-dark text-uppercase mt-4"
                            (click)="copyTextToClipboard()">{{'wizard.summary_button_text'|translate}}</button>
                    <span class="mt-3 header-style">{{'wizard.summary_text_2'|translate}}<span
                      class="ml-2 text-uppercase header-style text-primary">{{'general.online_booking'|translate}}</span></span>
                    <button class="btn btn-inverse-success mt-4" (click)="sendPostMessageToParent()">{{'summary-comp.finish_button'|translate}}</button>
                  </div>
                  <div class="soporte-container figure-img soporte-img">
                    <a [href]="mailto()">
                      <img src="assets/images/soporte.svg">
                      <span class="mt-3 mb-3 ml-1 text-secondary2 header-style">{{'wizard.summary_text3'|translate}}</span>
                    </a>
                  </div>
                </div>
            </mat-step>
          </mat-stepper>
        </div>
      </div>
    </div>
  </div>
</div>
