import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {UsersUserRead} from "../../../../swagger/models/users-user-read";
import {UsersService} from "../../../../swagger/services/users.service";
import {LocalAuthService} from "../../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {STORAGE_KEY_USER, STORAGE_KEY_LANG} from '../../../hooks/constants';
import {Router} from "@angular/router";
import {stringify} from "@angular/compiler/src/util";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  public user: UsersUserRead;
  public loading: boolean = false;
  public userIsLogged = false;

  constructor(
    config: NgbDropdownConfig,
    private userService: UsersService,
    private translate: TranslateService,
    private authService: LocalAuthService,
    private router: Router
  ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.authService.isGranted().then(() => {
      this.getCurrentUser();
    }, reason => {
      console.log(reason);
      this.userIsLogged = false;
    })
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  getCurrentUser() {
    let id = null;
    this.loading = true;
    this.userService.meUsersItem(id).subscribe((resp) => {
      this.loading = false;
      this.userIsLogged = true;
      this.user = resp;
      let lang = this.user.langCode;
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      if (this.user.wizardCompleted == false) {
        this.moveToWizard();
      }
    }, error => {
      console.log(error);
    });

  }

  logout() {
    this.router.navigate(['/logout']);
  }

  moveToWizard() {
    this.router.navigate(['/wizard'], {state: {currentUser: this.user}});
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

}
