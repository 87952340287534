import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss'],
})
export class DocumentTableComponent {
  @Input() documents: any[] = [];
  @Input() columns: string[] = ['name', 'created', 'actions'];
  @Output() onDocumentClicked: EventEmitter<any>  = new EventEmitter();
  @Output() onDeleteClicked: EventEmitter<any>  = new EventEmitter();

  constructor() {
  }

  clickAction(ev: EventEmitter<any>, document: any): void {
    ev.emit(document);
  }
}
