/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ServicesServicesList } from '../models/services-services-list';
import { ServicesServicesRead } from '../models/services-services-read';
import { ServicesServicesWrite } from '../models/services-services-write';
@Injectable({
  providedIn: 'root',
})
class ServicesService extends __BaseService {
  static readonly getServicesCollectionPath = '/api/services';
  static readonly postServicesCollectionPath = '/api/services';
  static readonly getServicesItemPath = '/api/services/{id}';
  static readonly putServicesItemPath = '/api/services/{id}';
  static readonly wizardServicesCollectionPath = '/api/wizard/service';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Services resources.
   * @param params The `ServicesService.GetServicesCollectionParams` containing the following parameters:
   *
   * - `users.id`:
   *
   * - `agendas.id`:
   *
   * @return Services collection response
   */
  getServicesCollectionResponse(params: ServicesService.GetServicesCollectionParams): __Observable<__StrictHttpResponse<Array<ServicesServicesList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.usersId != null) __params = __params.set('users.id', params.usersId.toString());
    if (params.agendasId != null) __params = __params.set('agendas.id', params.agendasId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServicesServicesList>>;
      })
    );
  }
  /**
   * Retrieves the collection of Services resources.
   * @param params The `ServicesService.GetServicesCollectionParams` containing the following parameters:
   *
   * - `users.id`:
   *
   * - `agendas.id`:
   *
   * @return Services collection response
   */
  getServicesCollection(params: ServicesService.GetServicesCollectionParams): __Observable<Array<ServicesServicesList>> {
    return this.getServicesCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<ServicesServicesList>)
    );
  }

  /**
   * Creates a Services resource.
   * @param services The new Services resource
   * @return Services resource created
   */
  postServicesCollectionResponse(services?: ServicesServicesWrite): __Observable<__StrictHttpResponse<ServicesServicesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = services;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesServicesRead>;
      })
    );
  }
  /**
   * Creates a Services resource.
   * @param services The new Services resource
   * @return Services resource created
   */
  postServicesCollection(services?: ServicesServicesWrite): __Observable<ServicesServicesRead> {
    return this.postServicesCollectionResponse(services).pipe(
      __map(_r => _r.body as ServicesServicesRead)
    );
  }

  /**
   * Retrieves specific services data
   * @param id undefined
   * @return Services resource response
   */
  getServicesItemResponse(id: string): __Observable<__StrictHttpResponse<ServicesServicesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/services/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesServicesRead>;
      })
    );
  }
  /**
   * Retrieves specific services data
   * @param id undefined
   * @return Services resource response
   */
  getServicesItem(id: string): __Observable<ServicesServicesRead> {
    return this.getServicesItemResponse(id).pipe(
      __map(_r => _r.body as ServicesServicesRead)
    );
  }

  /**
   * Update specific services data
   * @param params The `ServicesService.PutServicesItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `services`: The updated Services resource
   *
   * @return Services resource updated
   */
  putServicesItemResponse(params: ServicesService.PutServicesItemParams): __Observable<__StrictHttpResponse<ServicesServicesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.services;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/services/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesServicesRead>;
      })
    );
  }
  /**
   * Update specific services data
   * @param params The `ServicesService.PutServicesItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `services`: The updated Services resource
   *
   * @return Services resource updated
   */
  putServicesItem(params: ServicesService.PutServicesItemParams): __Observable<ServicesServicesRead> {
    return this.putServicesItemResponse(params).pipe(
      __map(_r => _r.body as ServicesServicesRead)
    );
  }

  /**
   * Create a service for wizard
   * @param newService undefined
   * @return Services resource created
   */
  wizardServicesCollectionResponse(newService?: {agenda?: number, services?: Array<any>}): __Observable<__StrictHttpResponse<ServicesServicesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = newService;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/wizard/service`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesServicesRead>;
      })
    );
  }
  /**
   * Create a service for wizard
   * @param newService undefined
   * @return Services resource created
   */
  wizardServicesCollection(newService?: {agenda?: number, services?: Array<any>}): __Observable<ServicesServicesRead> {
    return this.wizardServicesCollectionResponse(newService).pipe(
      __map(_r => _r.body as ServicesServicesRead)
    );
  }
}

module ServicesService {

  /**
   * Parameters for getServicesCollection
   */
  export interface GetServicesCollectionParams {
    usersId?: any;
    agendasId?: any;
  }

  /**
   * Parameters for putServicesItem
   */
  export interface PutServicesItemParams {
    id: string;

    /**
     * The updated Services resource
     */
    services?: ServicesServicesWrite;
  }
}

export { ServicesService }
