<div class="container mt-5 mb-5">
  <div class="header-style">
    <h3 class="font-weight-bold">{{'booking-online.api_title'|translate}}</h3>
    <div class="btn btn-bkt__outline-dark">{{'booking-online.doc_text_api'|translate}}</div>
  </div>
  <div class="form-container mt-3">
    <mat-form-field class="mt-3 mr-3" appearance="fill">
      <input matInput type="text" [(ngModel)]="apiKeyItem.siteref">
    </mat-form-field>
    <button class="btn btn-inverse-success mb-2" (click)="saveData()">{{'general.add'|translate}}</button>
  </div>
  <div class="list-container mt-5">
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">{{'booking-online.col_title_domain'|translate}}</th>
        <th scope="col">{{'booking-online.col_title_public'|translate}}</th>
        <th scope="col">{{'booking-online.col_title_private'|translate}}</th>
        <th scope="col">{{'general.actions'|translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of filteredApiKeys, let idx=index">
        <th scope="row">{{idx+1}}</th>
        <td>{{item.siteref}}</td>
        <td>{{item.apikeypub}}</td>
        <td>{{item.apikeypriv}}</td>
        <td><button mat-icon-button class="ml-2 btn-icon" (click)="delete(item)">
          <mat-icon class="icon-bkt__danger icon-wd-16" svgIcon="delete"></mat-icon>
        </button></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
