import {Component, ElementRef, Inject, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {GoogleLoginService} from '../../providers/google-login.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DocumentServiceWithEvents} from '../../services/document-with-events.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ExternalAccountService} from '../../../swagger/services/external-account.service';
import {
  ExternalAccountNewExternalAccountDTOExternalAccountWrite
} from '../../../swagger/models/external-account-new-external-account-dtoexternal-account-write';
import {TranslateService} from '@ngx-translate/core';

const TOAST_TITLE_UPLOAD_DOCUMENT_ERROR = 'Error subiendo documento';

enum State {
  LOADING,
  ERROR,
  UPLOAD,
  AUTH_REQUIRED,
}

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('progressFadeInOut', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),
      transition('void => *', [
        style({opacity: 0}),
        animate(300)
      ]),
      transition('* => void', [
        animate(600, style({opacity: 0}))
      ]),
    ])
  ]
})
export class DocumentAddComponent implements OnInit {
  State = State;

  state: State = State.LOADING;
  disabled = false;
  @Input() minFileSize: number;
  @Input() maxFileSize: number = 100 * 1024 * 1024; // 100Mb

  uploadUrl: string = undefined;

  currentService: {
    provider: string,
    service: string,
    externalId?: string,
  } = undefined;
  currentFileName: string;
  currentFile: File = null;
  progressInfo: any = null;
  duplicateIEEvent: boolean;  // flag to recognize duplicate onchange event for file input

  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  constructor(private dialogRef: MatDialogRef<DocumentAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ngZone: NgZone,
              protected router: Router,
              private toast: ToastrService,
              private translateService: TranslateService,
              private documentServiceWithEvents: DocumentServiceWithEvents,
              private googleService: GoogleLoginService,
              private externalAccountService: ExternalAccountService,
  ) { }

  private static isIE11(): boolean {
    // tslint:disable-next-line:no-string-literal
    return !!window['MSInputMethodContext'] && !!document['documentMode'];
  }

  ngOnInit(): void {
    this.setState(State.LOADING);
    this.checkAccess();
    this.currentFileName = this.translateService.instant('documents.add.selectbox');
  }

  private clearInputElement(): void {
    const ctx = this;
    if (ctx.fileDropRef && ctx.fileDropRef.nativeElement) {
      ctx.fileDropRef.nativeElement.value = '';
    }
  }

  private clearIEInput(): void {
    const ctx = this;
    if (ctx.fileDropRef && ctx.fileDropRef.nativeElement) {
      ctx.duplicateIEEvent = true; // IE11 fix to prevent onFileChange trigger again
      ctx.fileDropRef.nativeElement.value = '';
    }
  }

  choose(): void {
    this.fileDropRef.nativeElement.click();
  }

  setState(newState: State): void {
    switch (newState) {
      case State.LOADING:
        this.dialogRef.disableClose = true;
        break;
      default:
        this.dialogRef.disableClose = false;
        break;
    }
    this.state = newState;
  }

  checkAccess(): void {
    const ctx = this;

    ctx.setState(State.LOADING);
    ctx.documentServiceWithEvents.checkAccess().subscribe({
      next: value => {
        // 200 OK
        if (value?.url) {
          ctx.uploadUrl = value.url;
          ctx.setState(State.UPLOAD);
        }
      },
      error: err => {
        console.log('error occurred', err);

        switch (err.status) {
          case 401:
            // need to refresh token!
            ctx.router.navigate(['/logout']);
            ctx.dialogRef.close(true);
            return;
          case 428:
            if (err.error?.reason === 'EXTERNAL_AUTH_REQUIRED') {
              ctx.currentService = {
                provider: err.error?.provider,
                service: err.error?.service,
                externalId: err.error?.externalId,
              };
              ctx.setState(State.AUTH_REQUIRED);
              return;
            }
            break;
        }

        ctx.setState(State.ERROR);
      }
    });
  }

  loginWithGoogle(): void {
    const ctx = this;
    ctx.googleService.loadGoogleScript(() => {
      ctx.googleService.doGoogleLogin(true, ctx.currentService.externalId).then(loginResponse => {
        console.log('GOT GOOGLE AUTH RESPONSE', loginResponse);

        if (loginResponse?.code) {
          const data: ExternalAccountNewExternalAccountDTOExternalAccountWrite = {
            provider: ctx.currentService.provider,
            service: ctx.currentService.service,
            authInfo: loginResponse.code,
          };
          ctx.externalAccountService.postExternalAccountCollection(data).subscribe({
            next: response => {
              ctx.checkAccess();
            }, error: error => {
              console.error(error);
            }
          });
        }
      }, reason => {
        console.error(reason);
      });
    });
  }

  validate(file: File): boolean {
    const ctx = this;

    if (ctx.minFileSize && file.size < ctx.minFileSize) {
      ctx.toast.error('El fichero es demasiado pequeño', TOAST_TITLE_UPLOAD_DOCUMENT_ERROR);
      return false;
    }

    if (ctx.maxFileSize  && file.size > ctx.maxFileSize) {
      ctx.toast.error('El fichero es demasiado grande', TOAST_TITLE_UPLOAD_DOCUMENT_ERROR);
      return false;
    }

    return true;
  }

  selectFile($event: any): void {
    const ctx = this;
    if ($event.type !== 'drop' && DocumentAddComponent.isIE11() && ctx.duplicateIEEvent) {
      ctx.duplicateIEEvent = false;
      return;
    }

    const files = $event.dataTransfer ? $event.dataTransfer.files : $event.target.files;

    if (files?.length > 0) {
      if (!ctx.validate(files[0])) {
        return;
      }
      ctx.currentFile = files[0];
      ctx.currentFileName = ctx.currentFile.name;
    }

    if ($event.type !== 'drop' && DocumentAddComponent.isIE11()) {
      ctx.clearIEInput();
    } else {
      ctx.clearInputElement();
    }
  }

  private updateProgressInfo(file: File, progress: number): void {
    this.progressInfo = {
      ...this.progressInfo,
      value: progress,
    };
  }

  private clearProgressInfo(file: File): void {
    this.progressInfo = null;
  }

  private upload(targetUrl: string, file: File): void {
    const ctx = this;

    ctx.ngZone.run(() => {
      ctx.progressInfo = {value: 0, fileName: file.name, fileType: file.type, fileSize: file.size};
    });

    // build multipart form data
    const formData: FormData = new FormData();
    if (ctx.data?.clientId) {
      formData.append('clientId', ctx.data?.clientId);
    }
    if (ctx.data?.historyDataId) {
      formData.append('historyDataId', ctx.data?.historyDataId);
    }
    formData.append('file', file);

    ctx.documentServiceWithEvents.uploadNewDocument(formData).subscribe({
      next: event => {
        ctx.ngZone.run(() => {
          if (event.type === HttpEventType.UploadProgress) {
            ctx.updateProgressInfo(file, Math.round(100 * event.loaded / event.total));
          } else if (event instanceof HttpResponse) {
            ctx.clearProgressInfo(file);
            ctx.currentFileName = file.name;
            ctx.dialogRef.close(true);
          }
        });
      },
      error: err => {
        ctx.ngZone.run(() => {
          ctx.clearProgressInfo(file);
          ctx.toast.error('Error subiendo el archivo: ' + err, TOAST_TITLE_UPLOAD_DOCUMENT_ERROR);
        });
      }
    });
  }

  onDragEnter(e: DragEvent): void {
    if (!this.disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDragOver(e: DragEvent): void {
    if (!this.disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDragLeave(e: DragEvent): void {
  }

  onDrop(e: DragEvent): void {
    if (!this.disabled) {
      e.stopPropagation();
      e.preventDefault();
      this.selectFile(e);
    }
  }

  uploadFile(): void {
    const ctx = this;
    if (!ctx.uploadUrl) {
      ctx.toast.error('No se puede subir el archivo: uploadUrl', TOAST_TITLE_UPLOAD_DOCUMENT_ERROR);
    }

    if (ctx.currentFile != null) {
      ctx.progressInfo = null;
      ctx.currentFileName = null;

      ctx.upload(ctx.uploadUrl, ctx.currentFile);
    }
  }
}
