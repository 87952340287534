/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AgendasAgendaList } from '../models/agendas-agenda-list';
import { AgendasAgendaRead } from '../models/agendas-agenda-read';
import { AgendasAgendaWrite } from '../models/agendas-agenda-write';
@Injectable({
  providedIn: 'root',
})
class AgendasService extends __BaseService {
  static readonly getAgendasCollectionPath = '/api/agendas';
  static readonly postAgendasCollectionPath = '/api/agendas';
  static readonly getAgendasItemPath = '/api/agendas/{id}';
  static readonly putAgendasItemPath = '/api/agendas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Agendas resources.
   * @param params The `AgendasService.GetAgendasCollectionParams` containing the following parameters:
   *
   * - `users.id`:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Agendas collection response
   */
  getAgendasCollectionResponse(params: AgendasService.GetAgendasCollectionParams): __Observable<__StrictHttpResponse<Array<AgendasAgendaList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.usersId != null) __params = __params.set('users.id', params.usersId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/agendas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AgendasAgendaList>>;
      })
    );
  }
  /**
   * Retrieves the collection of Agendas resources.
   * @param params The `AgendasService.GetAgendasCollectionParams` containing the following parameters:
   *
   * - `users.id`:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Agendas collection response
   */
  getAgendasCollection(params: AgendasService.GetAgendasCollectionParams): __Observable<Array<AgendasAgendaList>> {
    return this.getAgendasCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<AgendasAgendaList>)
    );
  }

  /**
   * Creates a Agendas resource.
   * @param agendas The new Agendas resource
   * @return Agendas resource created
   */
  postAgendasCollectionResponse(agendas?: AgendasAgendaWrite): __Observable<__StrictHttpResponse<AgendasAgendaRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = agendas;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/agendas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgendasAgendaRead>;
      })
    );
  }
  /**
   * Creates a Agendas resource.
   * @param agendas The new Agendas resource
   * @return Agendas resource created
   */
  postAgendasCollection(agendas?: AgendasAgendaWrite): __Observable<AgendasAgendaRead> {
    return this.postAgendasCollectionResponse(agendas).pipe(
      __map(_r => _r.body as AgendasAgendaRead)
    );
  }

  /**
   * retrives specific agenda data
   * @param id undefined
   * @return Agendas resource response
   */
  getAgendasItemResponse(id: string): __Observable<__StrictHttpResponse<AgendasAgendaRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/agendas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgendasAgendaRead>;
      })
    );
  }
  /**
   * retrives specific agenda data
   * @param id undefined
   * @return Agendas resource response
   */
  getAgendasItem(id: string): __Observable<AgendasAgendaRead> {
    return this.getAgendasItemResponse(id).pipe(
      __map(_r => _r.body as AgendasAgendaRead)
    );
  }

  /**
   * Update specific agenda data
   * @param params The `AgendasService.PutAgendasItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `agendas`: The updated Agendas resource
   *
   * @return Agendas resource updated
   */
  putAgendasItemResponse(params: AgendasService.PutAgendasItemParams): __Observable<__StrictHttpResponse<AgendasAgendaRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.agendas;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/agendas/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgendasAgendaRead>;
      })
    );
  }
  /**
   * Update specific agenda data
   * @param params The `AgendasService.PutAgendasItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `agendas`: The updated Agendas resource
   *
   * @return Agendas resource updated
   */
  putAgendasItem(params: AgendasService.PutAgendasItemParams): __Observable<AgendasAgendaRead> {
    return this.putAgendasItemResponse(params).pipe(
      __map(_r => _r.body as AgendasAgendaRead)
    );
  }
}

module AgendasService {

  /**
   * Parameters for getAgendasCollection
   */
  export interface GetAgendasCollectionParams {
    usersId?: any;

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putAgendasItem
   */
  export interface PutAgendasItemParams {
    id: string;

    /**
     * The updated Agendas resource
     */
    agendas?: AgendasAgendaWrite;
  }
}

export { AgendasService }
