<div class="summary-container w-100 ml-3" *ngIf="!showMobile">
  <div class="container">
    <div class="card-body mt-3">
      <h5 class="text-uppercase mb-3">{{'wizard.summary_title'|translate}}</h5>
      <div class="text-container">
        <h6 class="text-primary">{{'summary-comp.time_zone_text'|translate}}  <span
          class="mt-2  header-style text-capitalize">{{timeZoneString}}</span></h6>
        <h6 class="text-primary mt-2">{{'wizard.agenda'|translate}} <span
          class="mt-2 header-style text-capitalize">{{currentAgenda.name}}</span></h6>
      </div>
      <div class="services-container mt-2" *ngIf="stepNumber>2">
        <h6 class="text-primary">{{'wizard.services'|translate}}</h6>
        <mat-list class="text-small custom-scroll-bar">
              <mat-list-item  class="bkt-list mb-2" *ngFor="let service of serviceList, let idx=index">
                {{service.name}} {{service.time}} min.
                <button mat-icon-button *ngIf="stepNumber!=4" class="ml-2 btn-icon" (click)="emitServicesChange(idx)">
                  <mat-icon class="icon-bkt__danger icon-wd-16" svgIcon="delete"></mat-icon>
                </button>
              </mat-list-item>
        </mat-list>
      </div>
      <div class="finish-container mt-5" *ngIf="stepNumber==4">
        <button mat-button class="btn btn-inverse-success w-100">{{'summary-comp.finish_button'|translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="summary-container-mobile w-100 mt-2" *ngIf="showMobile">
  <mat-list class="text-small custom-scroll-bar mt-4">
    <mat-list-item  class="bkt-list mb-2" *ngFor="let service of serviceList, let idx=index">
      {{service.name}} {{service.time}} min.
      <button mat-icon-button *ngIf="stepNumber!=4" class="ml-2 btn-icon" (click)="emitServicesChange(idx)">
        <mat-icon class="icon-bkt__danger icon-wd-16" svgIcon="delete"></mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>
