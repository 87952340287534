<div class="container mt-3">
  <div class="mobile-container" *ngIf="showMobile">
    <div class="schedule-container" *ngFor="let day of weekDays">
      <mat-checkbox class="mr-4 bkt-check" [checked]="isDayChecked(day.dayNumber)"
                    (change)="checkDay($event.checked,day.dayNumber)">{{day.dayName | translate}}</mat-checkbox>
      <div class="schedule-item" *ngFor="let schedule of scheduleList,let idx=index">
        <div class="schedule" *ngIf="day.dayNumber == schedule.day">
          <mat-form-field appearance="fill" class="mr-2">
            <mat-select class="bkt-select" [(ngModel)]="schedule.startTime">
              <mat-option *ngFor="let hour of hourList" [value]="hour">
                {{hour}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="mr-2">a</span>
          <mat-form-field appearance="fill" class="mr-2">
            <mat-select class="bkt-select" [(ngModel)]="schedule.endTime" (selectionChange)="emitScheduleChange()">
              <mat-option *ngFor="let hour of hourList" [value]="hour"
                          [disabled]="disableHours(schedule.startTime,hour)">
                {{hour}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button class="mb-3 mr-2 btn-icon" (click)="addSchedule(day)">
            <mat-icon class="icon-bkt__primary">add_circle_outline</mat-icon>
          </button>
          <button mat-icon-button class="mb-3 mr-2 btn-icon" [disabled]="!showRemoveIcon(day.dayNumber)"
                  (click)="removeSchedule(idx)">
            <mat-icon
              [ngClass]="{'icon-bkt__primary':showRemoveIcon(day.dayNumber),'icon-bkt__disabled':showRemoveIcon(day.dayNumber)}">
              remove_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="desktop-container" *ngIf="!showMobile">
    <div class="timer-container d-flex">
      <mat-form-field appearance="fill" class="wd-40">
        <mat-select class="bkt-select" [value]="selectedInterval" (selectionChange)="changeInterval($event.value)">
          <mat-option *ngFor="let timeInterval of timeIntervalList" [value]="timeInterval">
            {{'calendar-comp.selectText'|translate}} <span class="font-weight-bold">{{timeInterval}} {{'calendar-comp.minutes'|translate}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="schedule-desk-container">
      <div class="schedule-desk-header">
        <div class="checkbox-item">
          <mat-checkbox class="mr-2"
                        (change)="fillAllScheduleList($event.checked)">{{"calendar-comp.allHours" | translate}}</mat-checkbox>
        </div>
        <div class="checkbox-item" *ngFor="let day of weekDays">
          <mat-checkbox class="mr-2"
                        (change)="addAllTimeSlotForOneDay($event.checked,day.dayNumber)"
                        [checked]="dayCheck(day.dayNumber)">{{day.dayName | translate}}</mat-checkbox>
        </div>
      </div>
      <div class="schedule-desk-body mt-2">
        <div class="range-hour-container" *ngFor="let hour of hourList, let idxH = index">
          <div class="check-container" *ngIf="idxH+1<hourList.length">
            <div class="checkbox-item">
              <mat-checkbox class="mr-2" (change)="addTimeSlotForAllDay($event.checked,idxH)"
                            [checked]="timeSlotCheck(idxH)">{{hour}}
                - {{hourList[idxH + 1]}}</mat-checkbox>
            </div>
            <div class="checkbox-item" *ngFor="let day of weekDays, let idxD=index">
              <mat-checkbox class="mr-2" (change)="addTimeSlotForSelectedDay($event.checked,idxH,day.dayNumber)"
                            [checked]="timeSlotDayCheck(idxH,day.dayNumber)"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
