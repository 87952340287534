import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, Provider} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {ApiConfiguration} from '../swagger/api-configuration';
import {ApiModule} from '../swagger/api.module';
import {LoginComponent} from './pages/user-pages/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// material modules
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatStepperModule} from '@angular/material/stepper';

import {BaseService} from './services/base.service';
import {LocalAuthService} from './services/auth.service';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {AuthInterceptor} from './helpers/AuthInterceptor';
import {IframeComponent} from './pages/iframe/iframe.component';
import {GoogleSyncComponent} from './pages/google-sync/google-sync.component';
import {GoogleLoginService} from './providers/google-login.service';
import {NavbarComponent} from './components/shared/navbar/navbar.component';
import {FooterComponent} from './components/shared/footer/footer.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SpinnerComponent} from './components/shared/spinner/spinner.component';
import {SidebarComponent} from './components/shared/sidebar/sidebar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TodoComponent} from './pages/apps/todo-list/todo/todo.component';
import {TodoListComponent} from './pages/apps/todo-list/todo-list.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {ContentAnimateDirective} from './components/shared/directives/content-animate.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WidgetCardComponent} from './components/widget-card/widget-card.component';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {CustomerHistoryComponent} from './pages/customer-history/customer-history.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {DeleteDialogComponent} from './components/delete-dialog/delete-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {ClickOutSideDirective} from './directive/click-out-side.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AngularAuthModule, LibConfiguration} from '@kresnik87/angular-auth';
import {LogoutComponent} from './pages/user-pages/logout/logout.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FileSaverModule} from 'ngx-filesaver';
import {WizardComponent} from './pages/wizard/wizard.component';
import {ScheduleCmpComponent} from './components/schedule-cmp/schedule-cmp.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SpinnerService} from './services/spinner.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SummaryCmpComponent} from './components/summary-cmp/summary-cmp.component';
import {StepProgressCmpComponent} from './components/step-progress-cmp/step-progress-cmp.component';
import {ProgressDialogComponent} from './components/progress-dialog/progress-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ClipboardModule} from 'ngx-clipboard';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {BookingOnlineComponent} from './pages/booking-online/booking-online.component';
import {MatTabsModule} from '@angular/material/tabs';
import {Tab1CmpComponent} from './components/booking-online/tab1-cmp/tab1-cmp.component';
import {NgxColorsModule} from 'ngx-colors';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {Tab2CmpComponent} from './components/booking-online/tab2-cmp/tab2-cmp.component';
import { Tab3CmpComponent } from './components/booking-online/tab3-cmp/tab3-cmp.component';
import { Tab4CmpComponent } from './components/booking-online/tab4-cmp/tab4-cmp.component';
import {MatTableModule} from '@angular/material/table';
import {DialogService} from './providers/dialog.service';
import {DocumentItemModule} from './components/document-item/document-item.module';
import {HistoryWidgetModule} from './components/history-widget/history-widget.module';
import {DocumentAddModule} from './components/document-add/document-add.module';


export const config: LibConfiguration = {
  rootUrl: environment.ssoUrl,
  client_secret: '',
  grant_type: '',
  client_id: '',
  default_routes: false,
  oauthMode: false
};

function initLibConfiguration(lib: LibConfiguration) {
  return () => {
    lib.rootUrl = environment.ssoUrl;
    lib.grant_type = '';
    lib.client_id = '';
    lib.client_secret = '';
    lib.default_routes = false;
    lib.oauthMode = false;
  };
}

export const INIT_ENVIRONMENT: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initLibConfiguration,
  deps: [LibConfiguration],
  multi: true
};

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = environment.apiUrl;
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true
};

const googleLoginOptions = {
  scope: 'https://www.googleapis.com/auth/calendar',
  return_scopes: true

};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/lang/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    DashboardComponent,
    TodoComponent,
    TodoListComponent,
    SpinnerComponent,
    SidebarComponent,
    LoginComponent,
    IframeComponent,
    ContentAnimateDirective,
    GoogleSyncComponent,
    WidgetCardComponent,
    CustomerHistoryComponent,
    DeleteDialogComponent,
    ClickOutSideDirective,
    LogoutComponent,
    WizardComponent,
    ScheduleCmpComponent,
    SummaryCmpComponent,
    StepProgressCmpComponent,
    ProgressDialogComponent,
    BookingOnlineComponent,
    Tab1CmpComponent,
    Tab2CmpComponent,
    Tab3CmpComponent,
    Tab4CmpComponent
  ],
  imports: [
    BrowserModule,
    ApiModule,
    AppRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatStepperModule,
    MatDialogModule,
    MatIconModule,
    ScrollingModule,
    BrowserAnimationsModule,
    NgbModule,
    ChartsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    FileSaverModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    AngularAuthModule.forRoot(config),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    NgxColorsModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    DocumentAddModule,
    DocumentItemModule,
    HistoryWidgetModule,
  ],
  providers: [
    BaseService,
    LocalAuthService,
    GoogleLoginService,
    DialogService,
    SpinnerService,
    ThemeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    INIT_API_CONFIGURATION
  ],
  entryComponents: [ProgressDialogComponent],
  exports: [
    SpinnerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
