import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';


@Injectable()
export class SpinnerService {

  constructor(
    private spinner: NgxSpinnerService,
  ) {
  }

  public showSpinner(zIndex: number = 200): void {
    this.spinner.show(undefined,
      {
        type: 'ball-fall',
        size: 'medium',
        bdColor: 'rgba(255,254,255, .8)',
        color: '#64adb7',
        fullScreen: false,
        zIndex,
      });
  }

  public closeSpinner(): void {
    this.spinner.hide();
  }


}
