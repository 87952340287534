<div mat-dialog-title>
  <div class="row">
    <span class="col">{{'documents.add.title' | translate}}</span>
    <span class="col-1" mat-dialog-close="false">
      <img class="h-50" src="assets/images/icons/icono-cerrar-gris.svg">
    </span>
  </div>
</div>
<div mat-dialog-content>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="State.LOADING">
      <div class="w-100 h-100 d-flex justify-content-center">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="State.ERROR">
      <div class="w-100 d-flex justify-content-center">
        <span class="d-block mb-2">{{'documents.error.upload' | translate}}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="State.AUTH_REQUIRED">
      <ng-container [ngSwitch]="currentService.provider">
        <ng-container *ngSwitchCase="'google'">
          <div class="w-100 d-flex justify-content-center" style = "padding-top: 10px; padding-bottom: 10px">
            <button type="button" (click)="loginWithGoogle()"
                    class="btn login-with-google-btn">{{'google_sync.google_sign'| translate}}</button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="State.UPLOAD">
      <div class="upload-file-container" (dragenter)="onDragEnter($event)" (dragover)="onDragOver($event)"
           (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
        <div class="upload-file-button-bar row justify-content-between no-gutters">
          <div class="col-sm-12 col-lg-10 mat-raised-button mat-button-base mat-button-disabled" style="margin-bottom: 10px" (click)="choose()">
            <div class="text-left ml-2">{{currentFileName}}</div>
          </div>
          <button mat-button class="col-sm-12 col-lg-1 btn btn-primary" (click)="uploadFile()" [disabled]="!currentFile || progressInfo">{{'general.add' | translate}}</button>
        </div>
        <div *ngIf="progressInfo" [@progressFadeInOut]>
          <span>{{ progressInfo.fileName }}</span>
          <div class="progress">
            <div
              class="progress-bar progress-bar-info progress-bar-striped progress-bar-animated"
              role="progressbar"
              attr.aria-valuenow="{{ progressInfo.value }}"
              aria-valuemin="0"
              aria-valuemax="100"
              [ngStyle]="{ width: progressInfo.value + '%' }"
            >
              {{ progressInfo.value }}%
            </div>
          </div>
        </div>

        <input #fileDropRef type="file" (change)="selectFile($event)" [multiple]="false" [disabled]="disabled" [attr.title]="''">

        <div #body [ngClass]="{'mt-4': progressInfo != null}">
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
