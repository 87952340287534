/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WidgetsLabelsWlRead } from '../models/widgets-labels-wl-read';
import { WidgetsLabelsWlWrite } from '../models/widgets-labels-wl-write';
@Injectable({
  providedIn: 'root',
})
class WidgetsLabelsService extends __BaseService {
  static readonly getWidgetsLabelsCollectionPath = '/api/widgets-labels';
  static readonly postWidgetsLabelsCollectionPath = '/api/widgets-labels';
  static readonly getWidgetsLabelsItemPath = '/api/widgets-labels/{id}';
  static readonly putWidgetsLabelsItemPath = '/api/widgets-labels/{id}';
  static readonly deleteWidgetsLabelsItemPath = '/api/widgets-labels/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of WidgetsLabels resources.
   * @param params The `WidgetsLabelsService.GetWidgetsLabelsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return WidgetsLabels collection response
   */
  getWidgetsLabelsCollectionResponse(params: WidgetsLabelsService.GetWidgetsLabelsCollectionParams): __Observable<__StrictHttpResponse<Array<WidgetsLabelsWlRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/widgets-labels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WidgetsLabelsWlRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of WidgetsLabels resources.
   * @param params The `WidgetsLabelsService.GetWidgetsLabelsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return WidgetsLabels collection response
   */
  getWidgetsLabelsCollection(params: WidgetsLabelsService.GetWidgetsLabelsCollectionParams): __Observable<Array<WidgetsLabelsWlRead>> {
    return this.getWidgetsLabelsCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<WidgetsLabelsWlRead>)
    );
  }

  /**
   * Creates a WidgetsLabels resource.
   * @param widgetsLabels The new WidgetsLabels resource
   * @return WidgetsLabels resource created
   */
  postWidgetsLabelsCollectionResponse(widgetsLabels?: WidgetsLabelsWlWrite): __Observable<__StrictHttpResponse<WidgetsLabelsWlRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = widgetsLabels;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/widgets-labels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsLabelsWlRead>;
      })
    );
  }
  /**
   * Creates a WidgetsLabels resource.
   * @param widgetsLabels The new WidgetsLabels resource
   * @return WidgetsLabels resource created
   */
  postWidgetsLabelsCollection(widgetsLabels?: WidgetsLabelsWlWrite): __Observable<WidgetsLabelsWlRead> {
    return this.postWidgetsLabelsCollectionResponse(widgetsLabels).pipe(
      __map(_r => _r.body as WidgetsLabelsWlRead)
    );
  }

  /**
   * retrieves specific widget labels data
   * @param id undefined
   * @return WidgetsLabels resource response
   */
  getWidgetsLabelsItemResponse(id: string): __Observable<__StrictHttpResponse<WidgetsLabelsWlRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/widgets-labels/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsLabelsWlRead>;
      })
    );
  }
  /**
   * retrieves specific widget labels data
   * @param id undefined
   * @return WidgetsLabels resource response
   */
  getWidgetsLabelsItem(id: string): __Observable<WidgetsLabelsWlRead> {
    return this.getWidgetsLabelsItemResponse(id).pipe(
      __map(_r => _r.body as WidgetsLabelsWlRead)
    );
  }

  /**
   * update specific widget labels data
   * @param params The `WidgetsLabelsService.PutWidgetsLabelsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `widgetsLabels`: The updated WidgetsLabels resource
   *
   * @return WidgetsLabels resource updated
   */
  putWidgetsLabelsItemResponse(params: WidgetsLabelsService.PutWidgetsLabelsItemParams): __Observable<__StrictHttpResponse<WidgetsLabelsWlRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.widgetsLabels;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/widgets-labels/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsLabelsWlRead>;
      })
    );
  }
  /**
   * update specific widget labels data
   * @param params The `WidgetsLabelsService.PutWidgetsLabelsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `widgetsLabels`: The updated WidgetsLabels resource
   *
   * @return WidgetsLabels resource updated
   */
  putWidgetsLabelsItem(params: WidgetsLabelsService.PutWidgetsLabelsItemParams): __Observable<WidgetsLabelsWlRead> {
    return this.putWidgetsLabelsItemResponse(params).pipe(
      __map(_r => _r.body as WidgetsLabelsWlRead)
    );
  }

  /**
   * Delete specific widget labels data
   * @param id undefined
   */
  deleteWidgetsLabelsItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/widgets-labels/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific widget labels data
   * @param id undefined
   */
  deleteWidgetsLabelsItem(id: string): __Observable<null> {
    return this.deleteWidgetsLabelsItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WidgetsLabelsService {

  /**
   * Parameters for getWidgetsLabelsCollection
   */
  export interface GetWidgetsLabelsCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putWidgetsLabelsItem
   */
  export interface PutWidgetsLabelsItemParams {
    id: string;

    /**
     * The updated WidgetsLabels resource
     */
    widgetsLabels?: WidgetsLabelsWlWrite;
  }
}

export { WidgetsLabelsService }
