import {Injectable} from '@angular/core';
import {BaseService, CONSOLE_LOG, CONSOLE_ERROR} from './base.service';
import {AuthModel} from '../models/auth.model';
import {STORAGE_KEY_LOGIN,STORAGE_KEY_AUTH} from '../hooks/constants';
export {AuthModel}


@Injectable()
export class LocalAuthService extends BaseService {

  constructor() {
    super();
  }


  storeLogin(username: string, password: string) {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      lthis.setLocal(STORAGE_KEY_LOGIN, {
        'username': username,
        'password': password,
      }).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLogin() {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      lthis.getLocal(STORAGE_KEY_LOGIN).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLocalAuth() {
    let lthis = this;
    return new Promise<AuthModel>(function (resolve, reject) {
      lthis.getLocal(STORAGE_KEY_AUTH).then(
        (res) => {
          lthis.log(CONSOLE_LOG, null, "Getting auth from native storage", res);
          if (res) {
            let obj: AuthModel = new AuthModel();
            obj.initialize(res);
            resolve(obj);
          } else {
            resolve(null);
          }
        },
        (err) => {
          lthis.log(CONSOLE_ERROR, null, "Unable to get auth from native storage", err);
          reject(err);
        }
      );
    });
  }

  setLocalAuth(auth: AuthModel) {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      lthis.setLocal(STORAGE_KEY_AUTH, auth).then(
        (res) => {
          //lthis.events.publish(USER_EVENT_CHANGE);
          resolve(res);
        },
        (err) => {
          //lthis.events.publish(USER_EVENT_CHANGE);
          reject(err);
        }
      );
    });
  }

  isGranted() {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      lthis.getLocalAuth().then(
        (res: AuthModel) => {
          if (res) {
            if (res.getToken() && !res.tokenExpired()) {
              //token is not expired, check on server;
              resolve(true);
            } else {
              reject();
            }
          } else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  }
}
