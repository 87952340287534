import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import data from "../../../assets/json/lang.json";
import {UsersUserRead} from "../../../swagger/models/users-user-read";
import {UsersService} from "../../../swagger/services/users.service";
import {WidgetsLabelsService} from "../../../swagger/services/widgets-labels.service";
import {WidgetsLabelsWlRead} from "../../../swagger/models/widgets-labels-wl-read";
import {ApikeysService} from "../../../swagger/services/apikeys.service";
import {ApikeysApikeyRead} from "../../../swagger/models/apikeys-apikey-read";
import {SpinnerService} from "../../services/spinner.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {WidgetsLabelsWlWrite} from "../../../swagger/models/widgets-labels-wl-write";
import {ApikeysApikeyWrite} from "../../../swagger/models/apikeys-apikey-write";

export interface Lang {
  id: number;
  code: string;
}

@Component({
  selector: 'app-booking-online',
  templateUrl: './booking-online.component.html',
  styleUrls: ['./booking-online.component.scss']
})
export class BookingOnlineComponent implements OnInit {
  public envProd = environment.production;
  public langList: Lang[] = data as Lang[];
  public currentUser: UsersUserRead;
  public widgetLabel: WidgetsLabelsWlRead;
  public apiKeys: ApikeysApikeyRead[] = [];
  public loading = false;

  constructor(
    private userService: UsersService,
    private widgetLabelService: WidgetsLabelsService,
    private apiKeyService: ApikeysService,
    private spinnerService: SpinnerService,
    private toast: ToastrService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.loadWidgetLabelData();
    this.loadApiKey()
  }

  getCurrentUser(): void {
    const id = null;
    this.userService.meUsersItem(id).subscribe((resp) => {
      this.currentUser = resp;
    }, error => {
      console.log(error);
    });
  }

  loadWidgetLabelData(): void {
    let params: WidgetsLabelsService.GetWidgetsLabelsCollectionParams = {} as WidgetsLabelsService.GetWidgetsLabelsCollectionParams;
    this.widgetLabelService.getWidgetsLabelsCollection(params).subscribe((response) => {
      if (response[0] != undefined) {
        this.widgetLabel = response[0];
      } else {
        this.widgetLabel = {} as WidgetsLabelsWlRead;
      }
    }, error => {
      console.error(error);
    });
  }

  itsReadyToShow(): boolean {
    return this.currentUser != undefined && this.widgetLabel != undefined;
  }

  public loadApiKey(): void {
    const params: ApikeysService.GetApikeysCollectionParams = {} as ApikeysService.GetApikeysCollectionParams;
    params.page = 1;
    params.itemsPerPage = 50;
    this.apiKeyService.getApikeysCollection(params).subscribe((resp) => {
      this.apiKeys = resp;
    }, error => {
      console.log(error);
    });
  }

  showSpinnerAction(): void {
    this.spinnerService.showSpinner();
    this.loading = true;
  }

  prepareWidgetLabelData($event): void {
    this.widgetLabel = $event;
    let widgetLabelData: WidgetsLabelsService.PutWidgetsLabelsItemParams = {} as WidgetsLabelsService.PutWidgetsLabelsItemParams;
    widgetLabelData.widgetsLabels = {} as WidgetsLabelsWlWrite;
    widgetLabelData.widgetsLabels.labelUsersComments = this.widgetLabel.labelUsersComments;
    widgetLabelData.widgetsLabels.languagesId = this.widgetLabel.languagesId;
    widgetLabelData.widgetsLabels.extraInfo = this.widgetLabel.extraInfo;
    widgetLabelData.widgetsLabels.showAt = parseInt(this.widgetLabel.showAt.toString());
    widgetLabelData.widgetsLabels.showExtraInfo = this.widgetLabel.showExtraInfo;
    widgetLabelData.widgetsLabels.privacyPolicyShow = this.widgetLabel.privacyPolicyShow;
    widgetLabelData.widgetsLabels.privacyPolicyText = this.widgetLabel.privacyPolicyText;
    widgetLabelData.widgetsLabels.privacyPolicyUrl = this.widgetLabel.privacyPolicyUrl;
    console.log(widgetLabelData)
    if (this.widgetLabel.id != undefined) {
      widgetLabelData.id = this.widgetLabel.id.toString();
      this.saveWidgetLabelData(widgetLabelData);
    } else {
      this.createWidgetLabelData(widgetLabelData.widgetsLabels);
    }

  }

  saveWidgetLabelData(data: WidgetsLabelsService.PutWidgetsLabelsItemParams): void {
    this.widgetLabelService.putWidgetsLabelsItem(data).subscribe((response) => {
      this.widgetLabel = response;
      this.finishRequestProcess();
    }, error => {
      console.error(error);
    });
  }

  createWidgetLabelData(data: WidgetsLabelsWlRead): void {
    this.widgetLabelService.postWidgetsLabelsCollection(data).subscribe((response) => {
      this.widgetLabel = response;
      this.finishRequestProcess();
    }, error => {
      console.error(error);
    });
  }

  finishRequestProcess(): void {
    if (this.loading) {
      this.spinnerService.closeSpinner();
      this.toast.success(this.translateService.instant('customer_track.update_msg'));
      this.loading = false;
    }
  }

  createApiKey(data: ApikeysApikeyWrite): void {
    this.apiKeyService.postApikeysCollection(data).subscribe((response) => {
      this.loadApiKey();
      this.finishRequestProcess();
    }, error => {
      console.error(error);
    });
  }

  deleteApiKey(data: ApikeysApikeyRead): void {
    this.apiKeyService.deleteApikeysItemResponse(data.id.toString()).subscribe((response) => {
      this.loadApiKey();
      this.finishRequestProcess();
    }, error => {
      console.error(error);
    })
  }
}
