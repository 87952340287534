/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DocumentDocumentList } from '../models/document-document-list';
import { DocumentDocumentRead } from '../models/document-document-read';
import { DocumentNewDocumentDTODocumentWrite } from '../models/document-new-document-dtodocument-write';
import { DocumentDocumentWrite } from '../models/document-document-write';
import { DocumentDownloadDocumentDTODocumentRead } from '../models/document-download-document-dtodocument-read';
@Injectable({
  providedIn: 'root',
})
class DocumentService extends __BaseService {
  static readonly getDocumentCollectionPath = '/api/documents';
  static readonly postDocumentCollectionPath = '/api/documents';
  static readonly getDocumentItemPath = '/api/documents/{id}';
  static readonly putDocumentItemPath = '/api/documents/{id}';
  static readonly deleteDocumentItemPath = '/api/documents/{id}';
  static readonly downloadDocumentItemPath = '/api/documents/{id}/download';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Document resources.
   * @param params The `DocumentService.GetDocumentCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clients.id`:
   *
   * @return Document collection response
   */
  getDocumentCollectionResponse(params: DocumentService.GetDocumentCollectionParams): __Observable<__StrictHttpResponse<Array<DocumentDocumentList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.clientsId != null) __params = __params.set('clients.id', params.clientsId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DocumentDocumentList>>;
      })
    );
  }
  /**
   * Retrieves the collection of Document resources.
   * @param params The `DocumentService.GetDocumentCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clients.id`:
   *
   * @return Document collection response
   */
  getDocumentCollection(params: DocumentService.GetDocumentCollectionParams): __Observable<Array<DocumentDocumentList>> {
    return this.getDocumentCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<DocumentDocumentList>)
    );
  }

  /**
   * Creates a Document resource.
   * @param document The new Document resource
   * @return Document resource created
   */
  postDocumentCollectionResponse(document?: DocumentNewDocumentDTODocumentWrite): __Observable<__StrictHttpResponse<DocumentDocumentRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = document;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentDocumentRead>;
      })
    );
  }
  /**
   * Creates a Document resource.
   * @param document The new Document resource
   * @return Document resource created
   */
  postDocumentCollection(document?: DocumentNewDocumentDTODocumentWrite): __Observable<DocumentDocumentRead> {
    return this.postDocumentCollectionResponse(document).pipe(
      __map(_r => _r.body as DocumentDocumentRead)
    );
  }

  /**
   * retrieves specific document data
   * @param id undefined
   * @return Document resource response
   */
  getDocumentItemResponse(id: string): __Observable<__StrictHttpResponse<DocumentDocumentRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documents/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentDocumentRead>;
      })
    );
  }
  /**
   * retrieves specific document data
   * @param id undefined
   * @return Document resource response
   */
  getDocumentItem(id: string): __Observable<DocumentDocumentRead> {
    return this.getDocumentItemResponse(id).pipe(
      __map(_r => _r.body as DocumentDocumentRead)
    );
  }

  /**
   * update specific document
   * @param params The `DocumentService.PutDocumentItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `document`: The updated Document resource
   *
   * @return Document resource updated
   */
  putDocumentItemResponse(params: DocumentService.PutDocumentItemParams): __Observable<__StrictHttpResponse<DocumentDocumentRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.document;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/documents/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentDocumentRead>;
      })
    );
  }
  /**
   * update specific document
   * @param params The `DocumentService.PutDocumentItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `document`: The updated Document resource
   *
   * @return Document resource updated
   */
  putDocumentItem(params: DocumentService.PutDocumentItemParams): __Observable<DocumentDocumentRead> {
    return this.putDocumentItemResponse(params).pipe(
      __map(_r => _r.body as DocumentDocumentRead)
    );
  }

  /**
   * removes specific document
   * @param id undefined
   */
  deleteDocumentItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/documents/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * removes specific document
   * @param id undefined
   */
  deleteDocumentItem(id: string): __Observable<null> {
    return this.deleteDocumentItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * download document
   * @param id undefined
   * @return DownloadDocumentDTO resource response
   */
  downloadDocumentItemResponse(id: string): __Observable<__StrictHttpResponse<DocumentDownloadDocumentDTODocumentRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/documents/${encodeURIComponent(String(id))}/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentDownloadDocumentDTODocumentRead>;
      })
    );
  }
  /**
   * download document
   * @param id undefined
   * @return DownloadDocumentDTO resource response
   */
  downloadDocumentItem(id: string): __Observable<DocumentDownloadDocumentDTODocumentRead> {
    return this.downloadDocumentItemResponse(id).pipe(
      __map(_r => _r.body as DocumentDownloadDocumentDTODocumentRead)
    );
  }
}

module DocumentService {

  /**
   * Parameters for getDocumentCollection
   */
  export interface GetDocumentCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    clientsId?: any;
  }

  /**
   * Parameters for putDocumentItem
   */
  export interface PutDocumentItemParams {
    id: string;

    /**
     * The updated Document resource
     */
    document?: DocumentDocumentWrite;
  }
}

export { DocumentService }
