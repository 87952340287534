<div class="widget-container">
  <div class="row">
    <div class="col-12">
      <div class="button-group">
        <span class="mr-2 font-weight-bold header-style">{{historyItem.created|date:'dd-MM-YYYY, h:mm a'}}</span>
        <span class="mr-2 font-italic header-style">{{'customer_track.created_by'|translate}} {{historyItem.createdBy.name}}</span>
        <div class="icon-img" (click)="addDocumentAction()" #tooltip="matTooltip" matTooltip="{{ 'documents.add.button'|translate }}"
             matTooltipPosition=above matTooltipHideDelay="100">
          <img src="../../../assets/images/documents/attach_black.svg">
        </div>
        <div class="icon-img" (click)="toogleEditAction()" #tooltip="matTooltip" matTooltip="{{ 'widget-history.edit_button'|translate }}"
             matTooltipPosition=above matTooltipHideDelay="100">
          <img src="assets/images/icons/icono-editar.svg">
        </div>
        <div class="icon-img" (click)="deleteItemAction()" #tooltip2="matTooltip" matTooltip="{{ 'widget-history.delete_button'|translate }}" matTooltipPosition="above" matTooltipHideDelay="100">
          <img src="assets/images/icons/icono-borrar.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12" *ngIf="!toggleEdit">
      <p class="font-weight-light-type2">{{historyItem.note}}</p>
    </div>
    <div class="col-12" *ngIf="toggleEdit">
      <div class="form-group textarea-field">
        <mat-form-field appearance="outline" class="font-weight-normal-type2">
          <textarea matInput [formControl]="noteControl" [(ngModel)]="historyItem.note"></textarea>
        </mat-form-field>
      </div>
      <div class="button-container mb-4">
        <button type="button" (click)="toggleEdit=false"
                class="btn btn-danger ml-2">{{'widget-history.cancel_button'| translate}}</button>
        <button type="button" (click)="editItemAction()"
                class="btn btn-inverse-success ml-2">{{'customer_track.save'| translate}}</button>
      </div>
    </div>
  </div>
  <app-document-table [documents]="historyItem?.documents" (onDocumentClicked)="documentClickedAction($event)" (onDeleteClicked)="deleteDocumentAction($event)"></app-document-table>
</div>
