import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProgressDialogComponent} from '../progress-dialog/progress-dialog.component';

@Component({
  selector: 'app-step-progress-cmp',
  templateUrl: './step-progress-cmp.component.html',
  styleUrls: ['./step-progress-cmp.component.scss']
})
export class StepProgressCmpComponent implements OnInit {
  public state: number;
  modalRef: MatDialogRef<ProgressDialogComponent>;

  @Input()
  set loadingState(value: any) {
    this.state = value;
    this.updateModalData();
  }

  @Input()
  set showModal(value: boolean) {
    if (value) {
      this.showModalView();
    } else {
      this.closeModalView();
    }
  }


  constructor(public modalService: MatDialog) {
  }

  ngOnInit(): void {

  }

  showModalView(): void {
    this.modalRef = this.modalService.open(ProgressDialogComponent, {
      data: {loadingState: this.state}
    });
  }

  closeModalView(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  updateModalData(): void {
    if (this.modalRef) {
      this.modalRef.componentInstance.updateProgressBar(this.state);
    }
  }
}
