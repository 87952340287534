import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  title: string;
  content: string;
  buttonText: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  public title: string = "Title";
  public content: string = "Content";
  public buttonText: string = "Button"

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.title = data.title;
    this.content = data.content;
    this.buttonText = data.buttonText;
  }

  ngOnInit(): void {
  }

  deleteClick(): void {
    this.dialogRef.close(true);
  }

}
