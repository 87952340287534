import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

declare var google: any;

@Injectable()
export class GoogleLoginService {

  constructor() {
  }

  loadGoogleScript(
    onload: any,
    parentElement = null
  ): void {
    const scriptId = 'gsi-client';

    if (typeof document !== 'undefined' && !document.getElementById(scriptId)) {
      const signInJS = document.createElement('script');

      signInJS.id = scriptId;
      signInJS.async = true;
      signInJS.defer = true;
      signInJS.src = 'https://accounts.google.com/gsi/client';
      signInJS.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(signInJS);
    } else {
      // already loaded
      onload();
    }
  }

  doGoogleLogin(offlineMode?: boolean, externalId?: string): Promise<any> {
    const scope = 'email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive.file';
    return new Promise<any>((resolve, reject) => {
      if (offlineMode) {
        const client = google.accounts.oauth2.initCodeClient({
          client_id: environment.googleCredentials,
          access_type: 'offline',
          include_granted_scopes: true,
          login_hint: externalId,
          scope,
          callback: (tokenResponse) => {
            console.log('Token Callback Response', tokenResponse);
            resolve(tokenResponse);
          },
          error_callback: (err) => {
            console.log('Google Error Callback', err);
            reject(err);
          }
        });
        client.requestCode();
      } else {
/*        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
          .then((user: SocialUser) => {
            resolve(user);
          }, error => {
            reject(error);
          }).catch((error) => {

          reject(error);
        });*/
      }
    });
  }

  doGoogleLogout(): void {
//    this.authService.signOut();
  }
}
