import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalAuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(protected auth_service: LocalAuthService,
              protected router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.logged().then((logged) => {
      if (!logged) {
        this.router.navigate(['/logout']);
      }
      return logged;
    });

  }

  logged() {
    let lthis = this;
    return new Promise<boolean>(function (resolve) {
        lthis.auth_service.isGranted().then(
          () => {
            resolve(true);
          },
          () => {
            resolve(false);

          }
        );
      }
    );
  }
}
