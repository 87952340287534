<div class="container mt-5 mb-5">
  <div class="lang-container">
    <h6 class="header-style font-weight-bold">{{'booking-online.widget_lang_select'|translate}}</h6>
    <mat-form-field appearance="fill" class="ml-4">
      <mat-select class="bkt-select" [value]="selectedLang" (selectionChange)="selectLang($event.value)">
        <mat-option *ngFor="let lang of langList" [value]="lang">
          {{'lang.'+lang.code|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="url-container mb-3" *ngIf="!loading && getWidgetUrl()!=undefined">
    <h6 class="mr-2 header-style font-weight-bold">{{'booking-online.widget_link'|translate}}</h6>
    <div class="mr-2 btn btn-bkt__secondary">{{getWidgetUrl(false)}}</div>
    <button class="btn btn-inverse-success" (click)="copyTextToClipboard(getWidgetUrl(false))">{{'booking-online.button_copy'|translate}}</button>
  </div>
  <div class="code-container mt-3" *ngIf="!loading && getWidgetUrl()!=undefined">
    <div class="code mr-5">
      <h6 class="header-style font-weight-bold">{{'booking-online.code_box_title'|translate}}</h6>
      <div class="btn btn-bkt__secondary" (click)="copyTextToClipboard(getIntegrationCode())"><p>
        {{getIntegrationCode()}}
      </p></div>
      <div class="upload-box mt-5">
        <div class="image-container">
          <h5 class="header-style font-weight-bold mr-4">{{'booking-online.load_image_text'|translate}}</h5>
          <button class="btn btn-bkt__outline-dark"
                  (click)="imageInput.click()">{{'booking-online.upload_image'|translate}}</button>
          <input #imageInput type="file" accept="image/*" (change)="selectFile($event)">
        </div>
        <div class="img-preview ml-3" *ngIf="imgUrl!=undefined">
          <img [src]="imgUrl">
        </div>
      </div>
      <div class="color-container mt-5 upload-box">
        <label class="header-style font-weight-bold mr-3">{{'booking-online.color_text'|translate}}</label>
        <ngx-colors ngx-colors-trigger [(ngModel)]="color"></ngx-colors>
      </div>
    </div>
    <div class="example-image">
      <h6 class="text-secondary font-weight-bold">{{'booking-online.example_title'|translate}}</h6>
      <div class="img-fluid">
        <img src="assets/images/booking-online/widget_ejemplo.svg">
      </div>
    </div>
  </div>
  <div class="mt-5">
    <h5>{{'booking-online.link_title'|translate}}</h5>
    <div class="link-container mt2">
      <div class="link-box">
        <a class="a-container mr-2">
          <button class="btn btn-bkt__facebook">
            <mat-icon class="icon-bkt__wd48" svgIcon="facebook"></mat-icon>
          </button>
          <span class="mt-2 font-weight-bold header-style text-small">{{'booking-online.facebook'|translate}}</span>
        </a>
        <a class="a-container mr-2">
          <button class="btn btn-bkt__instagram">
            <mat-icon class="icon-bkt__wd48" svgIcon="instagram"></mat-icon>
          </button>
          <span class="mt-2 font-weight-bold header-style text-small">{{'booking-online.instagram'|translate}}</span>
        </a>
        <a class="a-container mr-2">
          <button class="btn btn-bkt__secondary">
            <mat-icon class="icon-bkt__wd48" svgIcon="google"></mat-icon>
          </button>
          <span class="mt-2 font-weight-bold header-style text-small">{{'booking-online.google'|translate}}</span>
        </a>
      </div>
      <div class="button-container">
        <button class="btn btn-inverse-success">{{'general.save'|translate}}</button>
      </div>
    </div>
  </div>
  <div class="advanced-container mt-5">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'booking-online.advanced_title'|translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="text-container">
        <p class="text-secondary">
          <span>{{'booking-online.text_expansion_1'|translate}}</span>
          <br>
          <span>{{'booking-online.text_expansion_2'|translate}}</span>
          <br>
          <span>{{'booking-online.text_expansion_3'|translate}}</span>
        </p>
      </div>
      <div class="services-container">
        <div class="services-box">
          <div class="services-title mt-3">
            <h6>{{'booking-online.services_title'|translate}}</h6>
          </div>
          <div class="services-body mt-3">
            <div class="checkbox-item" *ngFor="let serv of servicesList">
              <mat-checkbox class="mr-2" (change)="selectService($event.checked,serv)">{{serv.name}}</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="agenda-box">
          <div class="agenda-title mt-3">
            <h6>{{'booking-online.agenda_title'|translate}}</h6>
          </div>
          <div class="agenda-body mt-3">
            <div class="checkbox-item" *ngFor="let agend of agendaList">
              <mat-checkbox class="mr-2" [disabled]="checkAgendaAvailability(agend)" (change)="selectAgenda($event.checked,agend)">{{agend.name}}</mat-checkbox>
            </div>
          </div>
        </div>
        <div class="date-box mt-3">
          <div class="date-title">
            <h6>{{'booking-online.radio_button_date'|translate}}</h6>
          </div>
          <div class="date-body">
            <mat-radio-group class="radio-group">
              <mat-radio-button value="1"
                                class="radio-button">{{'booking-online.radio_button_date'|translate}}</mat-radio-button>
              <mat-radio-button value="2" class="radio-button">
                <mat-form-field color="accent" appearance="outline" class="ml-2">
                  <mat-label>Desde</mat-label>
                  <input matInput [matDatepicker]="picker1">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 color="primary"></mat-datepicker>
                </mat-form-field>
                <mat-form-field color="accent" appearance="outline" class="ml-2">
                  <mat-label>Hasta</mat-label>
                  <input matInput [matDatepicker]="picker2">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="code-box" *ngIf="widgetUrl!=undefined">
          <div class="code-widget-body">
            <div class="code-widget-title">
              <h6 class="header-style font-weight-bold">{{'booking-online.code_widget_title'|translate}}</h6>
            </div>
            <div class="wd-100 btn btn-bkt__primary" (click)="copyTextToClipboard(getWidgetUrl(true))">{{getWidgetUrl(true)}}</div>
          </div>
          <div class="code-integration-body ml-2">
            <div class="code-integration-title">
              <h6 class="header-style font-weight-bold">{{'booking-online.code_integration_title'|translate}}</h6>
            </div>
            <div class="wd-100 btn btn-bkt__primary" (click)="copyTextToClipboard(getIntegrationCode(true))">
              {{getIntegrationCode(true)}}
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
