<div class="container-fluid bg-white">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="page-title p-4">
        {{getHeaderText()}}
      </h1>
    </div>
  </div>
  <app-spinner *ngIf="loading"></app-spinner>
  <div class="row" *ngIf="!loading">
    <div class="col-12 text-center">
      <div class="card" *ngIf="!hasCalendarItem && !syncing">
        <div class="card-body">
          <div class="form-group mat-primary" *ngIf="isLoggedin">
            <mat-vertical-stepper class="bg-white">
              <mat-step [stepControl]="firstFormGroup" [completed]="completed" [optional]="false">
                <form [formGroup]="firstFormGroup">
                  <ng-template matStepLabel>{{'google_sync.step1_header'|translate}}</ng-template>
                  <mat-radio-group
                    class="example-radio-group"
                    [(ngModel)]="selectedCalendar"
                    formControlName="calendarCtrl"
                    required>
                    <mat-radio-button class="example-radio-button" *ngFor="let calItem of calendarList"
                                      [value]="calItem" [disabled]="calItem.agenda!=undefined"
                                      (change)="calendarSelectEvent(calItem)">
                      <span>{{calItem.summary}} </span><span *ngIf="calItem.agenda!=undefined" class="text-danger">{{'google_sync.text_calendar_agenda'|translate}}:{{calItem.agenda.name}}</span>
                    </mat-radio-button>
                  </mat-radio-group>
                  <div class="button-group">
                    <button class="btn btn-inverse-success btn-fw" matStepperNext
                            [disabled]="!firstFormGroup.valid">{{'google_sync.next'|translate}}</button>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="secondFormGroup" [optional]="false">
                <form [formGroup]="secondFormGroup">
                  <div class="form-step">
                    <ng-template matStepLabel>{{'google_sync.step2_header'|translate}}</ng-template>
                    <mat-form-field appearance="outline">
                      <mat-label>{{'google_sync.month'|translate}}</mat-label>
                      <mat-select formControlName="timeCtrl" (selectionChange)="timeSelectEvent($event.value)" required>
                        <mat-option *ngFor="let time of timeList" [value]="time.value">
                          {{time.text}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="button-group">
                      <button class="btn btn-outline-dark btn-fw mr-2"
                              matStepperPrevious>{{'google_sync.back'|translate}}</button>
                      <button class="btn btn-inverse-success btn-fw" (click)="createCalendarItem()"
                              matStepperNext>{{'google_sync.next'|translate}}</button>
                    </div>
                  </div>
                </form>
              </mat-step>
            </mat-vertical-stepper>
          </div>
          <div *ngIf="isLoggedin === false">
            <div>
              <button type="button" (click)="loginWithGoogle()"
                      class="btn login-with-google-btn">{{'google_sync.google_sign'| translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-container" *ngIf="hasCalendarItem && !syncing">
        <div class="container-card">
          <div class="card" *ngFor="let cal of activeCalendars">
            <div class="card-body">
              <div class="card-group">
                <mat-card class="mt-2 card-info bg-white">
                  <mat-card-content>
                    <div class="icon-content mb-2">
                      <img src="assets/images/gcalendar.svg"><h5>{{"google_sync.card_title"|translate}}</h5>
                    </div>
                    <div class="card-text-group">
                      <p>{{"google_sync.card_text1"|translate}}: <span class="text-ternary">{{cal.summary}}</span></p>
                      <p>{{"google_sync.card_text2"|translate}}: <span class="text-ternary">{{cal.syncTime}}</span></p>
                      <p>{{"google_sync.card_text3"|translate}}: <span class="text-ternary">{{cal.agenda.name}}</span></p>
                    </div>
                    <div class="button-group">
                      <button type="button" (click)="deleteGoogleCalendarSync(cal.id)"
                              class="btn btn-outline-dark">{{"google_sync.button_disconnect"|translate}}
                      </button>
                      <button type="button" (click)="callReSync()" *ngIf="!currentCalendarActive.syncSuccess"
                              class="btn btn-outline-dark-bkt ml-2">{{'google_sync.button_resync'| translate}}</button>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
        <div class="msg-container">
          <div class="success-msg alert alert-success" *ngIf="currentCalendarActive.syncStatus ==='success'">
            <span>{{'google_sync.sync_success_msg'|translate}}</span>
          </div>
          <div class="failed-msg alert alert-danger" *ngIf="currentCalendarActive.syncStatus === 'failed'">
            <span>{{'google_sync.sync_failed_msg1'|translate}}</span><br>
            <span>{{'google_sync.sync_failed_msg2'|translate}}</span>
          </div>
          <div class="failed-msg alert alert-warning" *ngIf="currentCalendarActive.syncStatus === 'pending'">
            <span>{{'google_sync.sync_warning_msg'|translate}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="syncing" class="mb-5 mt-5">
        <div class="syncing-container">
          <div class="icon-content bkt-icon mb-2 mr-3">
            <img src="assets/images/icono_bookitit.svg">
          </div>
          <div class="dots-container">
            <span class="dots-cont">
              <span class="dot dot-1"></span> <span class="dot dot-2"></span><span class="dot dot-3"></span>
              <span class="dot dot-3"></span> <span class="dot dot-2"></span> <span class="dot dot-1"></span>
              <span class="dot dot-1"></span> <span class="dot dot-2"></span> <span class="dot dot-3"> </span>
            </span>
          </div>
          <div class="icon-content bkt-icon mb-2 ml-3">
            <img src="assets/images/gcalendar.svg">
          </div>
        </div>
        <div class="text-container mt-3">
          <span class="font-weight-bold">{{'google_sync.text_calendar_sync'|translate}}</span>
        </div>
        <div class="text-container mt-5">
          <span class="font-italic">{{'google_sync.text_calendar_sync2'|translate}}</span><br>
          <span class="font-italic">{{'google_sync.text_calendar_sync3'|translate}}</span><br>
          <span class="font-italic font-weight-bold">{{'google_sync.text_calendar_sync4'|translate}}</span>
        </div>
        <div class="text-container mt-5">
          <span class="font-weight-bold">{{'google_sync.text_calendar_sync5'|translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
