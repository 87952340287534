export const environment = {
  production: false,
  log: true,
  lang: 'es',
  apiUrl: 'https://preapi.bookitit.com',
  ssoUrl: 'https://presso.bookitit.com', // 'http://TestBalancer-39712954.eu-west-3.elb.amazonaws.com',
  auth_endpoint: 'api/login_check',
  widget_url_part: 'hosteds/widgetdefault',
  expire_time: 7200,
  bookititUrl: 'https://prenuvol.bookitit.com',
  info_email: 'info@bookitit.com',
  // googleCredentials:"891453863514-ccqalol3jnulsi2vf73fjsv624548l5p.apps.googleusercontent.com",
  googleCredentials: '616284929188-su0nk9pbg560m1vqvd0unlt54q6gcbei.apps.googleusercontent.com',
  allowedOrigins: [
    'https://preapp.bookitit.com',
    'https://preapi.bookitit.com',
    'https://prenuvol.bookitit.com',
    'http://localhost:5900',
    'http://localhost:5500',
    'http://localhost',
    'http://localhost:4200',
  ]
};
