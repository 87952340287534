/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientsClientList } from '../models/clients-client-list';
import { ClientsClientRead } from '../models/clients-client-read';
import { ClientsClientWrite } from '../models/clients-client-write';
@Injectable({
  providedIn: 'root',
})
class ClientsService extends __BaseService {
  static readonly getClientsCollectionPath = '/api/clients';
  static readonly postClientsCollectionPath = '/api/clients';
  static readonly getClientsItemPath = '/api/clients/{id}';
  static readonly putClientsItemPath = '/api/clients/{id}';
  static readonly deleteClientsItemPath = '/api/clients/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Clients resources.
   * @param params The `ClientsService.GetClientsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clientOf`:
   *
   * @return Clients collection response
   */
  getClientsCollectionResponse(params: ClientsService.GetClientsCollectionParams): __Observable<__StrictHttpResponse<Array<ClientsClientList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.clientOf != null) __params = __params.set('clientOf', params.clientOf.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientsClientList>>;
      })
    );
  }
  /**
   * Retrieves the collection of Clients resources.
   * @param params The `ClientsService.GetClientsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clientOf`:
   *
   * @return Clients collection response
   */
  getClientsCollection(params: ClientsService.GetClientsCollectionParams): __Observable<Array<ClientsClientList>> {
    return this.getClientsCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<ClientsClientList>)
    );
  }

  /**
   * Creates a Clients resource.
   * @param clients The new Clients resource
   * @return Clients resource created
   */
  postClientsCollectionResponse(clients?: ClientsClientWrite): __Observable<__StrictHttpResponse<ClientsClientRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = clients;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientsClientRead>;
      })
    );
  }
  /**
   * Creates a Clients resource.
   * @param clients The new Clients resource
   * @return Clients resource created
   */
  postClientsCollection(clients?: ClientsClientWrite): __Observable<ClientsClientRead> {
    return this.postClientsCollectionResponse(clients).pipe(
      __map(_r => _r.body as ClientsClientRead)
    );
  }

  /**
   * retrives specific client  data
   * @param id undefined
   * @return Clients resource response
   */
  getClientsItemResponse(id: string): __Observable<__StrictHttpResponse<ClientsClientRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clients/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientsClientRead>;
      })
    );
  }
  /**
   * retrives specific client  data
   * @param id undefined
   * @return Clients resource response
   */
  getClientsItem(id: string): __Observable<ClientsClientRead> {
    return this.getClientsItemResponse(id).pipe(
      __map(_r => _r.body as ClientsClientRead)
    );
  }

  /**
   * retrives specific client  data
   * @param params The `ClientsService.PutClientsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clients`: The updated Clients resource
   *
   * @return Clients resource updated
   */
  putClientsItemResponse(params: ClientsService.PutClientsItemParams): __Observable<__StrictHttpResponse<ClientsClientRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.clients;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/clients/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientsClientRead>;
      })
    );
  }
  /**
   * retrives specific client  data
   * @param params The `ClientsService.PutClientsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clients`: The updated Clients resource
   *
   * @return Clients resource updated
   */
  putClientsItem(params: ClientsService.PutClientsItemParams): __Observable<ClientsClientRead> {
    return this.putClientsItemResponse(params).pipe(
      __map(_r => _r.body as ClientsClientRead)
    );
  }

  /**
   * retrives specific client  data
   * @param id undefined
   */
  deleteClientsItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/clients/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * retrives specific client  data
   * @param id undefined
   */
  deleteClientsItem(id: string): __Observable<null> {
    return this.deleteClientsItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ClientsService {

  /**
   * Parameters for getClientsCollection
   */
  export interface GetClientsCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    clientOf?: any;
  }

  /**
   * Parameters for putClientsItem
   */
  export interface PutClientsItemParams {
    id: string;

    /**
     * The updated Clients resource
     */
    clients?: ClientsClientWrite;
  }
}

export { ClientsService }
