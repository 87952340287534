/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientHistoryDataHistoryRead } from '../models/client-history-data-history-read';
import { ClientHistoryDataNewHistoryDataDTOHistoryWrite } from '../models/client-history-data-new-history-data-dtohistory-write';
import { ClientHistoryDataHistoryWrite } from '../models/client-history-data-history-write';
@Injectable({
  providedIn: 'root',
})
class ClientHistoryDataService extends __BaseService {
  static readonly getClientHistoryDataCollectionPath = '/api/client-history-datas';
  static readonly postClientHistoryDataCollectionPath = '/api/client-history-datas';
  static readonly getClientHistoryDataItemPath = '/api/client-history-datas/{id}';
  static readonly putClientHistoryDataItemPath = '/api/client-history-datas/{id}';
  static readonly deleteClientHistoryDataItemPath = '/api/client-history-datas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of ClientHistoryData resources.
   * @param params The `ClientHistoryDataService.GetClientHistoryDataCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clientHistory.id`:
   *
   * @return ClientHistoryData collection response
   */
  getClientHistoryDataCollectionResponse(params: ClientHistoryDataService.GetClientHistoryDataCollectionParams): __Observable<__StrictHttpResponse<Array<ClientHistoryDataHistoryRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.clientHistoryId != null) __params = __params.set('clientHistory.id', params.clientHistoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/client-history-datas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientHistoryDataHistoryRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of ClientHistoryData resources.
   * @param params The `ClientHistoryDataService.GetClientHistoryDataCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clientHistory.id`:
   *
   * @return ClientHistoryData collection response
   */
  getClientHistoryDataCollection(params: ClientHistoryDataService.GetClientHistoryDataCollectionParams): __Observable<Array<ClientHistoryDataHistoryRead>> {
    return this.getClientHistoryDataCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<ClientHistoryDataHistoryRead>)
    );
  }

  /**
   * Creates a ClientHistoryData resource.
   * @param clientHistoryData The new ClientHistoryData resource
   * @return ClientHistoryData resource created
   */
  postClientHistoryDataCollectionResponse(clientHistoryData?: ClientHistoryDataNewHistoryDataDTOHistoryWrite): __Observable<__StrictHttpResponse<ClientHistoryDataHistoryRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = clientHistoryData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/client-history-datas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryDataHistoryRead>;
      })
    );
  }
  /**
   * Creates a ClientHistoryData resource.
   * @param clientHistoryData The new ClientHistoryData resource
   * @return ClientHistoryData resource created
   */
  postClientHistoryDataCollection(clientHistoryData?: ClientHistoryDataNewHistoryDataDTOHistoryWrite): __Observable<ClientHistoryDataHistoryRead> {
    return this.postClientHistoryDataCollectionResponse(clientHistoryData).pipe(
      __map(_r => _r.body as ClientHistoryDataHistoryRead)
    );
  }

  /**
   * retrives specific history data
   * @param id undefined
   * @return ClientHistoryData resource response
   */
  getClientHistoryDataItemResponse(id: string): __Observable<__StrictHttpResponse<ClientHistoryDataHistoryRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/client-history-datas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryDataHistoryRead>;
      })
    );
  }
  /**
   * retrives specific history data
   * @param id undefined
   * @return ClientHistoryData resource response
   */
  getClientHistoryDataItem(id: string): __Observable<ClientHistoryDataHistoryRead> {
    return this.getClientHistoryDataItemResponse(id).pipe(
      __map(_r => _r.body as ClientHistoryDataHistoryRead)
    );
  }

  /**
   * retrives specific history data
   * @param params The `ClientHistoryDataService.PutClientHistoryDataItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientHistoryData`: The updated ClientHistoryData resource
   *
   * @return ClientHistoryData resource updated
   */
  putClientHistoryDataItemResponse(params: ClientHistoryDataService.PutClientHistoryDataItemParams): __Observable<__StrictHttpResponse<ClientHistoryDataHistoryRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.clientHistoryData;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/client-history-datas/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryDataHistoryRead>;
      })
    );
  }
  /**
   * retrives specific history data
   * @param params The `ClientHistoryDataService.PutClientHistoryDataItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientHistoryData`: The updated ClientHistoryData resource
   *
   * @return ClientHistoryData resource updated
   */
  putClientHistoryDataItem(params: ClientHistoryDataService.PutClientHistoryDataItemParams): __Observable<ClientHistoryDataHistoryRead> {
    return this.putClientHistoryDataItemResponse(params).pipe(
      __map(_r => _r.body as ClientHistoryDataHistoryRead)
    );
  }

  /**
   * retrives specific history data
   * @param id undefined
   */
  deleteClientHistoryDataItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/client-history-datas/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * retrives specific history data
   * @param id undefined
   */
  deleteClientHistoryDataItem(id: string): __Observable<null> {
    return this.deleteClientHistoryDataItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ClientHistoryDataService {

  /**
   * Parameters for getClientHistoryDataCollection
   */
  export interface GetClientHistoryDataCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    clientHistoryId?: any;
  }

  /**
   * Parameters for putClientHistoryDataItem
   */
  export interface PutClientHistoryDataItemParams {
    id: string;

    /**
     * The updated ClientHistoryData resource
     */
    clientHistoryData?: ClientHistoryDataHistoryWrite;
  }
}

export { ClientHistoryDataService }
