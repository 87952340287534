import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseModel} from './base.model';
import {environment} from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AuthModel extends BaseModel {

  private token: string = null;
  private expires_in: number = null;
  private currentDate: number = null;
  private compact: boolean = false;

  public getCompact(): boolean {
    return this.compact;
  }

  public setCompact(value: boolean) {
    this.compact = value;
  }

  public constructor() {
    super();
    this.setCurrentDate();
  }

  public getToken(): string {
    return this.token;
  }

  public setToken(token: string): this {
    this.token = token;
    return this;
  }

  public getExpires_in(): number {
    return this.expires_in;
  }

  public setExpires_in(expiresIn: number): this {

    if (!expiresIn) {
      expiresIn = this.getCurrentDate() + (1000 * environment.expire_time);
    }
    this.expires_in = expiresIn;
    return this;
  }

  public getCurrentDate(): number {
    return this.currentDate;
  }

  public setCurrentDate(seconds: number = null): this {
    //if seconds is null, get actual timestamp
    if (!seconds) {
      seconds = new Date().getTime();
    }
    this.currentDate = seconds;
    return this;
  }

  public tokenExpired(): boolean {
    //temporal
    var expired = true;
    if (this.getExpires_in() && this.getCurrentDate()) {
      var currentTime = new Date().getTime();
      //            console.log("Auth date", new Date(this.getCurrentDate()));
      //            console.log("Expire date", new Date((this.getExpires_in() * 1000) + this.getCurrentDate()));
      //            console.log("Now date", new Date(currentTime));

      //multiplicate by 1000 to get milisecons
      if (+((this.getExpires_in() * 1000) + this.getCurrentDate()) > currentTime) {
        expired = false;
      }
    }
    return expired;
  }

}
