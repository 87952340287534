import {AfterContentInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AgendasService} from '../../../swagger/services/agendas.service';
import {AgendasAgendaRead} from '../../../swagger/models/agendas-agenda-read';
import {GoogleLoginService} from '../../providers/google-login.service';
import {GoogleSyncService} from '../../../swagger/services/google-sync.service';
import {GoogleSyncGoogleWrite} from '../../../swagger/models/google-sync-google-write';
import {GoogleSyncGoogleRead} from '../../../swagger/models/google-sync-google-read';
import {GCalendarItemService} from '../../../swagger/services/gcalendar-item.service';
import {AgendasGcWrite, GCalendarItemGcRead, GCalendarItemGcWrite, GoogleSyncGcWrite} from '../../../swagger/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

interface Time {
  text: string;
  value: number;
}

@Component({
  selector: 'app-google-sync',
  templateUrl: './google-sync.component.html',
  styleUrls: ['./google-sync.component.scss']
})
export class GoogleSyncComponent implements OnInit {
  public id: string;
  public agenda: AgendasAgendaRead;
  protected googleSync: GoogleSyncGoogleRead;
  public calendarList: GCalendarItemGcRead[];
  public activeCalendars: GCalendarItemGcRead[];
  public currentCalendarActive: GCalendarItemGcRead;
  public selectedCalendar: GCalendarItemGcRead;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public completed = false;
  public syncing = false;
  public timeList: Time[] = [
    {text: '3M', value: 3},
    {text: '6M', value: 6},
    {text: '12M', value: 12}
  ];
  isLoggedin = false;
  loading = false;
  hasCalendarItem = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private agendaService: AgendasService,
    private googleService: GoogleLoginService,
    private googleSyncService: GoogleSyncService,
    private googleCalendarService: GCalendarItemService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) {
  }

  ngOnInit(): void {
    const ctx = this;
    ctx.id = ctx.route.snapshot.paramMap.get('id');
    ctx.activeCalendars = [];
    ctx.loading = true;
    ctx.googleService.loadGoogleScript(() => {
      if (ctx.id != null) {
        ctx.getAgendaById(this.id);
      } else {
        ctx.getGCItemCollection();
      }
      ctx.firstFormGroup = ctx.formBuilder.group({
        calendarCtrl: ['', Validators.required]
      });
      ctx.secondFormGroup = ctx.formBuilder.group({
        timeCtrl: ['', Validators.required]
      });
    });
  }

  getAgendaById(id: string): void {
    const ctx = this;

    ctx.agendaService.getAgendasItem(id).subscribe((resp) => {
      ctx.agenda = resp;
      ctx.loading = false;
      if (ctx.agenda.activeCalendarConf.length !== 0) {
        const calendarItem = ctx.agenda.activeCalendarConf.pop();
        ctx.getCalendarItem(calendarItem.id);
      }
    }, error => {
      console.log(error);
    });
  }

  loginWithGoogle(): void {
    const ctx = this;
    this.googleService.doGoogleLogin(true).then(resp => {
      ctx.createGoogleSync(resp.code);
    }, reason => {
      console.error(reason);
    });
  }

  logOut(): void {
    this.googleService.doGoogleLogout();
  }

  createGoogleSync(code: string): void {
    const ctx = this;

    const data: GoogleSyncGoogleWrite = {
      authorizeCode: code,
    };
    ctx.googleSyncService.postGoogleSyncCollection(data).subscribe((resp) => {
      ctx.googleSync = resp;
      ctx.getCalendarList();
    }, error => {
      console.error(error);
    });
  }

  getGCItemCollection(): void {
    const ctx = this;

    ctx.activeCalendars = [];
    ctx.googleCalendarService.getGCalendarItemCollection().subscribe((resp) => {
      ctx.activeCalendars = resp;
      ctx.hasCalendarItem = true;
    }, error => {
      ctx.hasCalendarItem = false;
    });
  }

  getCalendarList(): void {
    const ctx = this;
    ctx.googleCalendarService.listCalendarGCalendarItemCollection(this.googleSync.id.toString()).subscribe((data) => {
      ctx.calendarList = data;
      if (Array.isArray(ctx.calendarList) && ctx.calendarList.length) {
        ctx.isLoggedin = true;
      }
      ctx.hasCalendarItem = false;
    }, error => {
      console.error(error);
    });
  }

  calendarSelectEvent($event): void {
    this.selectedCalendar = $event;
  }

  timeSelectEvent($event): void {
    this.selectedCalendar.syncTime = $event;
  }

  createCalendarItem(): void {
    const ctx = this;
    if (ctx.secondFormGroup.valid) {
      ctx.completed = true;
      ctx.showSpiner();
      const data: GCalendarItemGcWrite = {} as GCalendarItemGcWrite;
      data.backgroundColor = ctx.selectedCalendar.backgroundColor;
      data.calendarId = ctx.selectedCalendar.calendarId;
      data.foregroundColor = ctx.selectedCalendar.foregroundColor;
      data.summary = ctx.selectedCalendar.summary;
      data.syncTime = ctx.selectedCalendar.syncTime;
      data.credentials = ({} as GoogleSyncGcWrite);
      data.credentials.id = ctx.googleSync.id;
      data.agenda = ({} as AgendasGcWrite);
      data.agenda.id = ctx.agenda.id;
      this.googleCalendarService.postGCalendarItemCollection(data).subscribe((resp) => {
        ctx.currentCalendarActive = resp;
        ctx.activeCalendars.push(this.currentCalendarActive);
        ctx.callSyncronization(this.currentCalendarActive.id.toString());
      }, error => {
        const msg = ctx.translateService.instant('google_sync.error_msg');
        ctx.toast.error(msg);
        ctx.spinner.hide();
        ctx.loading = false;
      });
    }
  }

  getCalendarItem(id: number): void {
    const ctx = this;
    ctx.googleCalendarService.getGCalendarItemItem(id.toString()).subscribe((resp) => {
      ctx.currentCalendarActive = resp;
      ctx.activeCalendars.push(ctx.currentCalendarActive);
      ctx.hasCalendarItem = true;
    }, error => {
      console.error(error);
    });
  }

  callReSync(): void {
    this.showSpiner();
    this.callSyncronization(this.currentCalendarActive.id.toString());
  }

  callSyncronization(id: string): void {
    const ctx = this;
    ctx.googleCalendarService.syncCalGCalendarItemItem(id).subscribe((resp) => {
      const msg = this.translateService.instant('google_sync.success_msg');
      ctx.spinner.hide();
      ctx.loading = false;
      ctx.hasCalendarItem = true;
      ctx.toast.success(msg);
      ctx.syncing = true;
    }, error => {
      const msg = ctx.translateService.instant('google_sync.error_msg');
      ctx.toast.error(msg);
    });
  }

  getHeaderText(): any {
    if (this.hasCalendarItem) {
      return this.translateService.instant('google_sync.title2');
    } else {
      return this.translateService.instant('google_sync.title');
    }
  }

  showSpiner(): void {
    this.loading = true;
    this.spinner.show(undefined,
      {
        type: 'ball-fall',
        size: 'medium',
        bdColor: 'rgba(255,254,255, .8)',
        color: '#64adb7',
        fullScreen: false
      });
  }

  deleteGoogleCalendarSync(id: number): void {
    const ctx = this;
    ctx.showSpiner();
    ctx.googleCalendarService.deleteGCalendarItemItem(id.toString()).subscribe((resp) => {
      const msg = this.translateService.instant('google_sync.disconnect_msg');
      ctx.spinner.hide();
      ctx.loading = false;
      ctx.toast.success(msg);
      ctx.hasCalendarItem = false;
      ctx.isLoggedin = false;
    }, error => {
      const msg = ctx.translateService.instant('google_sync.error_msg');
      ctx.toast.error(msg);
    });
  }
}
