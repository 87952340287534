<div class="document-item row" style="width: fit-content;">
  <div class="document-icon-container col-12 mx-auto" style="max-width: 100px; margin-bottom: 2px;"(click)="clickAction(onDocumentClicked, document)">
<!--    <mat-icon [inline]="true" class="document-icon">file_present</mat-icon>-->
    <img src="assets/images/documents/attach.svg">
  </div>
  <div class="text-center col-11 px-0" (click)="clickAction(onDocumentClicked, document)">{{ document.name }}</div>
  <div class="text-left col-1 px-0">
    <span class="text-left icon-img" (click)="clickAction(onDeleteClicked, document)" matTooltip="Eliminar" matTooltipPosition="above" matTooltipHideDelay="100">
          <img src="assets/images/icons/icono-borrar.svg">
    </span>
  </div>
</div>
