import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {AgendasAgendaWrite} from '../../../swagger/models/agendas-agenda-write';
import {ScheduleModel} from '../../models/schedule.model';
import {
  TimezonesTimeZoneRead
} from '../../../swagger/models/timezones-time-zone-read';
import {TimezonesService} from '../../../swagger/services/timezones.service';
import {UsersUserRead} from '../../../swagger/models/users-user-read';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../../swagger/services/users.service';
import {SpinnerService} from '../../services/spinner.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {
  ServicesServicesRead
} from '../../../swagger/models/services-services-read';
import {AgendasAgendaRead} from '../../../swagger/models/agendas-agenda-read';
import {AgendasService} from '../../../swagger/services/agendas.service';
import {SchedulesService} from '../../../swagger/services/schedules.service';
import {ServicesService} from '../../../swagger/services/services.service';
import PutUsersItemParams = UsersService.PutUsersItemParams;
import {MatStepper} from '@angular/material/stepper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';
import {UsersUserInputUserWrite} from '../../../swagger/models/users-user-input-user-write';
import {ClipboardService} from 'ngx-clipboard';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ApikeysService} from '../../../swagger/services/apikeys.service';
import {ApikeysApikeyRead} from '../../../swagger/models/apikeys-apikey-read';
import {LocalAuthService} from '../../services/auth.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  @ViewChild('stepper') private myStepper: MatStepper;
  public agenda: AgendasAgendaRead = {} as AgendasAgendaRead;
  public serviceList: ServicesServicesRead[] = [];
  public currentService: ServicesServicesRead = {} as ServicesServicesRead;
  public loading = false;
  public timeZoneList: TimezonesTimeZoneRead[] = [];
  public selectedTimeZone: TimezonesTimeZoneRead = {} as TimezonesTimeZoneRead;
  public filteredTimeZoneList: Observable<TimezonesTimeZoneRead[]>;
  public currentUser: UsersUserRead;
  public scheduleList: ScheduleModel[] = [];
  public stepNumber = 1;
  public loadingState = 0;
  public showModalPRogress = false;
  public widgetUrl = 'http//localhost:5900';
  public apiKeys: ApikeysApikeyRead[];
  agendaFormGroup: FormGroup;
  scheduleFormGroup: FormGroup;
  serviceFormGroup: FormGroup;
  summaryFormGroup: FormGroup;
  step1Svg = 'step1';

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UsersService,
    private apiKeyService: ApikeysService,
    private authService: LocalAuthService,
    private router: Router,
    private spinnerService: SpinnerService,
    private timeZoneService: TimezonesService,
    private agendaService: AgendasService,
    private scheduleServices: SchedulesService,
    private servicesService: ServicesService,
    private modalService: NgbModal,
    private clipBoardService: ClipboardService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private toast: ToastrService
  ) {
    this.matIconRegistry.addSvgIcon('active', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/wizard/Step_02.svg'));
    this.matIconRegistry.addSvgIcon('success', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/wizard/Step_03.svg'));
    this.matIconRegistry.addSvgIcon('inactive', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/wizard/Step_01.svg'));
    this.currentUser = this.router.getCurrentNavigation()?.extras.state?.currentUser;
    this.scheduleFormGroup = this._formBuilder.group({
      hiddenCtrl: ['', Validators.required]
    });
    this.agendaFormGroup = this._formBuilder.group({
      agendaCtrl: ['', Validators.required],
      timeZoneControl: ['', Validators.required]
    });
    this.serviceFormGroup = this._formBuilder.group({
      nameCtrl: ['', Validators.required],
      timeCtrl: ['', [Validators.required, validationTimeCtrl]]
    });

  }


  ngOnInit(): void {
    if (this.currentUser === undefined) {
      this.getCurrentUser();
    }

    this.loadAllTimeZone();
    this.filteredTimeZone();
    this.getApiKeys();

  }

  public filteredTimeZone(): void {
    if (typeof (this.agendaFormGroup) !== undefined) {
      this.filteredTimeZoneList = this.agendaFormGroup.get('timeZoneControl').valueChanges.pipe(
        startWith(''),
        map((timeZone) => {
            return this._filter(this.displayFn(timeZone));
          }
        ));
    }
  }

  displayFn(timeZone: TimezonesTimeZoneRead): string {
    return timeZone && timeZone.timezone ? timeZone.timezone : '';
  }

  private _filter(value: string): TimezonesTimeZoneRead[] {
    const filterValue = value.toLowerCase();

    return this.timeZoneList.filter(item => item.timezone.toLowerCase().includes(filterValue));
  }

  public isMobile(): boolean {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width <= 768;
  }

  public getApiKeys(): void {
    const params: ApikeysService.GetApikeysCollectionParams = {} as ApikeysService.GetApikeysCollectionParams;
    params.page = 1;
    params.itemsPerPage = 50;
    this.apiKeyService.getApikeysCollection(params).subscribe((resp) => {
      this.apiKeys = resp;
      this.updateWidgetUrl();
    }, error => {
      console.log(error);
    });
  }

  public loadAllTimeZone(): void {
    const params: TimezonesService.GetTimezonesCollectionParams = {} as TimezonesService.GetTimezonesCollectionParams;
    params.page = 1;
    params.itemsPerPage = 50;
    this.timeZoneService.getTimezonesCollection(params).subscribe((resp) => {
      this.timeZoneList = resp;
    }, error => {
      console.error(error);
    });
  }

  public updateSchedule($event): void {
    this.scheduleList = $event;
    if (this.scheduleList.length > 0) {
      this.scheduleFormGroup.get('hiddenCtrl').setValue('asd');
    } else {
      this.scheduleFormGroup.get('hiddenCtrl').setValue('');
    }
  }

  getCurrentUser(): void {
    const id = null;
    this.loading = true;
    this.spinnerService.showSpinner();
    this.userService.meUsersItem(id).subscribe((resp) => {
      this.loading = false;
      this.spinnerService.closeSpinner();
      this.currentUser = resp;
    }, error => {
      console.log(error);
    });

  }

  changeStepNumber(action: number): void {
    if (action === 1) {
      this.stepNumber++;
    } else {
      this.stepNumber--;
    }
  }

  timeZoneSelectedChange(event): void {
    this.selectedTimeZone = event.option.value;
    this.currentUser.timezonesId = this.selectedTimeZone.id;
  }

  getTimeZoneString(): string {
    const offTime = this.selectedTimeZone.currentGmtoff / 3600;
    // return this.selectedTimeZone.timezone + " " + offTime;
    return this.selectedTimeZone.timezone;
  }

  addNewService(): void {
    this.serviceList.push(this.currentService);
    this.currentService = {} as ServicesServicesRead;
    this.currentService.name = ' ';
    this.currentService.time = 5;
  }

  saveServiceAndFinish(): void {
    this.changeStepNumber(1);
    this.addNewService();
    this.saveAgendaStep();
    // this.openModal();
  }

  // use only on testing purpose
  openModal(): void {
    this.showModalPRogress = true;
    this.loadingState = 1;
    const THIS = this;
    const i = setInterval(() => {
      THIS.loadingState++;
      if (THIS.loadingState === 5) {
        clearInterval(i);
        THIS.showModalPRogress = false;

      }
    }, 1000);
  }

  saveAgendaStep(): void {
    this.loadingState = 1;
    this.myStepper.next();
    this.showModalPRogress = true;
    const bodyParams: AgendasAgendaWrite = {} as AgendasAgendaWrite;
    bodyParams.name = this.agenda.name;
    this.agendaService.postAgendasCollection(bodyParams).subscribe((resp) => {
      this.loadingState = 2;
      this.agenda = resp;
      this.saveScheduleState();
    }, error => {
      console.error(error);
    });
  }

  copyTextToClipboard(): void {
    this.clipBoardService.copyFromContent(this.widgetUrl);
    this.toast.success(this.translateService.instant('wizard.copy_text'));
  }

  saveScheduleState(): void {
    this.scheduleServices.wizardSchedulesCollection({
      agenda: this.agenda.id,
      schedule: this.scheduleList,
    }).subscribe((resp) => {
      this.loadingState = 3;
      this.saveServicesState();
    }, error => {
      console.error(error);
    });
  }

  saveServicesState(): void {
    this.servicesService.wizardServicesCollection({
      agenda: this.agenda.id,
      services: this.serviceList,
    }).subscribe((resp) => {
      this.loadingState = 4;
      this.saveUserState();
    }, error => {
      console.error(error);
    });
  }

  saveUserState(): void {
    const bodyParams: PutUsersItemParams = {} as PutUsersItemParams;
    const userWrite: UsersUserInputUserWrite = {} as UsersUserInputUserWrite;
    userWrite.wizardCompleted = true;
    userWrite.timezonesId = this.selectedTimeZone.id.toString();
    userWrite.limitOptions = this.currentUser.limitOptions;
    userWrite.limitTime = this.currentUser.limitTime;
    userWrite.limitAppointments = this.currentUser.limitAppointments;
    bodyParams.id = this.currentUser.id.toString();
    bodyParams.users = userWrite;
    this.userService.putUsersItem(bodyParams).subscribe((resp) => {
      this.currentUser = resp;
      this.loadingState = 5;
      this.showModalPRogress = false;
      this.loading = false;
      this.getApiKeys();

    }, error => {
      console.error(error);
    });
  }

  updateWidgetUrl(): void {
    const widgetApiKey = this.apiKeys.filter((item) => {
      // tslint:disable-next-line:triple-equals
      return item.type == 2;
    });
    if (this.currentUser !== undefined) {
      this.widgetUrl = environment.bookititUrl + '/' + this.currentUser.langCode + '/' + environment.widget_url_part + '/' + widgetApiKey[0].widgetkeypub + '#services';
    }

  }

  serviceListUpdate($event): void {
    this.serviceList = $event;
  }

  mailto(): string {
    return 'mailto:' + environment.info_email + '?subject=' + this.translateService.instant('wizard.subject');
  }

  sendPostMessageToParent(): void {
    // check if page its open from iframe in legacy
     let iframeMode = false;
     this.authService.getLocalAuth().then((local) => {
       iframeMode = local.getCompact();
       if (iframeMode){
           const parent = window.parent;
           let returnPayload;
           returnPayload = {
             method: 'storage#get',
             data: {wizardComplete: true}
           };
           parent.postMessage(JSON.stringify(returnPayload), '*');
       }else{
         // here the logic for normal use
       }
     });
  }

}

function validationTimeCtrl(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && (control.value % 5 !== 0)) {
    return {timeCtrlValid: true};
  }
  return null;
}
