import {Component, OnInit} from '@angular/core';
import {AngularAuthModule, AuthService, AuthConfigInterface} from "@kresnik87/angular-auth";
import {environment} from "../../../../environments/environment";
import {AuthModel} from "../../../models/auth.model";
import {LocalAuthService} from '../../../services/auth.service';
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public showRegister = false;
  public email: string;
  public password: string;

  constructor(
    private authService: AuthService,
    private localauthService: LocalAuthService,
    private router: Router,
    private toast: ToastrService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }

  public login() {
    let dataConfig: AuthConfigInterface = <AuthConfigInterface>{};
    let body = {"email": this.email, "password": this.password, type:"3", encoded:false};
    let url = environment.auth_endpoint;
    dataConfig.body = body;
    dataConfig.endpoint = url;
    dataConfig.customBody = true;
    dataConfig.options = false;
    dataConfig.customModel = true;
    this.authService.login(this.email, this.password, dataConfig).then((resp) => {
      let auth: AuthModel = new AuthModel();
      auth.initialize(resp);
      auth.setExpires_in(null);
      this.localauthService.setLocalAuth(auth);
      let msg = this.translateService.instant('login_page.success_msg')
      this.toast.success(msg);
      this.router.navigate(['/dashboard']);
    }, reason => {
      console.log(reason);
      let msg = "";
      if (reason.status == 401) {
        msg = this.translateService.instant('login_page.error_msg_bad_credentials');
      } else {
        msg = this.translateService.instant('general.general_error_msg');
      }
      this.toast.error(msg);
    })
  }
}
