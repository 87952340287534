/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WidgetsConfigurationsWcRead } from '../models/widgets-configurations-wc-read';
import { WidgetsConfigurationsWcWrite } from '../models/widgets-configurations-wc-write';
@Injectable({
  providedIn: 'root',
})
class WidgetsConfigurationsService extends __BaseService {
  static readonly getWidgetsConfigurationsCollectionPath = '/api/widgets-configurations';
  static readonly postWidgetsConfigurationsCollectionPath = '/api/widgets-configurations';
  static readonly getWidgetsConfigurationsItemPath = '/api/widgets-configurations/{id}';
  static readonly putWidgetsConfigurationsItemPath = '/api/widgets-configurations/{id}';
  static readonly deleteWidgetsConfigurationsItemPath = '/api/widgets-configurations/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of WidgetsConfigurations resources.
   * @param params The `WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return WidgetsConfigurations collection response
   */
  getWidgetsConfigurationsCollectionResponse(params: WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams): __Observable<__StrictHttpResponse<Array<WidgetsConfigurationsWcRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/widgets-configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WidgetsConfigurationsWcRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of WidgetsConfigurations resources.
   * @param params The `WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return WidgetsConfigurations collection response
   */
  getWidgetsConfigurationsCollection(params: WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams): __Observable<Array<WidgetsConfigurationsWcRead>> {
    return this.getWidgetsConfigurationsCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<WidgetsConfigurationsWcRead>)
    );
  }

  /**
   * Creates a WidgetsConfigurations resource.
   * @param widgetsConfigurations The new WidgetsConfigurations resource
   * @return WidgetsConfigurations resource created
   */
  postWidgetsConfigurationsCollectionResponse(widgetsConfigurations?: WidgetsConfigurationsWcWrite): __Observable<__StrictHttpResponse<WidgetsConfigurationsWcRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = widgetsConfigurations;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/widgets-configurations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsConfigurationsWcRead>;
      })
    );
  }
  /**
   * Creates a WidgetsConfigurations resource.
   * @param widgetsConfigurations The new WidgetsConfigurations resource
   * @return WidgetsConfigurations resource created
   */
  postWidgetsConfigurationsCollection(widgetsConfigurations?: WidgetsConfigurationsWcWrite): __Observable<WidgetsConfigurationsWcRead> {
    return this.postWidgetsConfigurationsCollectionResponse(widgetsConfigurations).pipe(
      __map(_r => _r.body as WidgetsConfigurationsWcRead)
    );
  }

  /**
   * retrives specific widget Config data
   * @param id undefined
   * @return WidgetsConfigurations resource response
   */
  getWidgetsConfigurationsItemResponse(id: string): __Observable<__StrictHttpResponse<WidgetsConfigurationsWcRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/widgets-configurations/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsConfigurationsWcRead>;
      })
    );
  }
  /**
   * retrives specific widget Config data
   * @param id undefined
   * @return WidgetsConfigurations resource response
   */
  getWidgetsConfigurationsItem(id: string): __Observable<WidgetsConfigurationsWcRead> {
    return this.getWidgetsConfigurationsItemResponse(id).pipe(
      __map(_r => _r.body as WidgetsConfigurationsWcRead)
    );
  }

  /**
   * update specific widget Config data
   * @param params The `WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `widgetsConfigurations`: The updated WidgetsConfigurations resource
   *
   * @return WidgetsConfigurations resource updated
   */
  putWidgetsConfigurationsItemResponse(params: WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams): __Observable<__StrictHttpResponse<WidgetsConfigurationsWcRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.widgetsConfigurations;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/widgets-configurations/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WidgetsConfigurationsWcRead>;
      })
    );
  }
  /**
   * update specific widget Config data
   * @param params The `WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `widgetsConfigurations`: The updated WidgetsConfigurations resource
   *
   * @return WidgetsConfigurations resource updated
   */
  putWidgetsConfigurationsItem(params: WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams): __Observable<WidgetsConfigurationsWcRead> {
    return this.putWidgetsConfigurationsItemResponse(params).pipe(
      __map(_r => _r.body as WidgetsConfigurationsWcRead)
    );
  }

  /**
   * Delete specific widget Config data
   * @param id undefined
   */
  deleteWidgetsConfigurationsItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/widgets-configurations/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific widget Config data
   * @param id undefined
   */
  deleteWidgetsConfigurationsItem(id: string): __Observable<null> {
    return this.deleteWidgetsConfigurationsItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WidgetsConfigurationsService {

  /**
   * Parameters for getWidgetsConfigurationsCollection
   */
  export interface GetWidgetsConfigurationsCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putWidgetsConfigurationsItem
   */
  export interface PutWidgetsConfigurationsItemParams {
    id: string;

    /**
     * The updated WidgetsConfigurations resource
     */
    widgetsConfigurations?: WidgetsConfigurationsWcWrite;
  }
}

export { WidgetsConfigurationsService }
