<div class="d-flex align-items-center auth px-0">
  <div class="row w-100 mx-0">
    <div class="col-lg-6 mx-auto">
      <div class="auth-form-light text-left py-5 px-4 px-sm-5">
        <div class="brand-logo">
          <img src="assets/images/logo_bookitit.svg" alt="logo">
        </div>
        <h4>Hello! let's get started</h4>
        <h6 class="font-weight-light">Sign in to continue.</h6>
        <div class="pt-3">
          <div class="form-group">
            <input type="email" class="form-control form-control-lg" id="inputEmail" placeholder="Email" [(ngModel)]="email">
          </div>
          <div class="form-group">
            <input type="password"  (keydown.enter)="login()" class="form-control form-control-lg" id="inputPassword" placeholder="Password" [(ngModel)]="password">
          </div>
          <div class="mt-3">
            <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"   (click)="login()">SIGN IN</a>
          </div>
          <div class="my-2 d-flex justify-content-between align-items-center">
            <div class="form-check">
              <label class="form-check-label text-muted">
                <input type="checkbox" class="form-check-input">
                <i class="input-helper"></i>
                Keep me signed in
              </label>
            </div>
            <a href="javascript:(void)" class="auth-link text-black">Forgot password?</a>
          </div>
          <div class="text-center mt-4 font-weight-light" *ngIf="showRegister">
            Don't have an account? <a routerLink="/register" class="text-primary">Create</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
