/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AgendasService } from './services/agendas.service';
import { ApikeysService } from './services/apikeys.service';
import { BlocksService } from './services/blocks.service';
import { CalendarConfService } from './services/calendar-conf.service';
import { GCalendarItemService } from './services/gcalendar-item.service';
import { ClientHistoryService } from './services/client-history.service';
import { ClientHistoryDataService } from './services/client-history-data.service';
import { ClientsService } from './services/clients.service';
import { DocumentService } from './services/document.service';
import { EventsService } from './services/events.service';
import { EventslogsService } from './services/eventslogs.service';
import { ExternalAccountService } from './services/external-account.service';
import { GoogleSyncService } from './services/google-sync.service';
import { UsersService } from './services/users.service';
import { SchedulesService } from './services/schedules.service';
import { ServicesService } from './services/services.service';
import { TimezonesService } from './services/timezones.service';
import { WidgetsConfigurationsService } from './services/widgets-configurations.service';
import { WidgetsLabelsService } from './services/widgets-labels.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AgendasService,
    ApikeysService,
    BlocksService,
    CalendarConfService,
    GCalendarItemService,
    ClientHistoryService,
    ClientHistoryDataService,
    ClientsService,
    DocumentService,
    EventsService,
    EventslogsService,
    ExternalAccountService,
    GoogleSyncService,
    UsersService,
    SchedulesService,
    ServicesService,
    TimezonesService,
    WidgetsConfigurationsService,
    WidgetsLabelsService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
