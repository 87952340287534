import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Lang} from "../../../pages/booking-online/booking-online.component";
import {UsersUserRead} from "../../../../swagger/models/users-user-read";
import {UsersService} from "../../../../swagger/services/users.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersUserInputUserWrite} from "../../../../swagger/models/users-user-input-user-write";
import {WidgetsConfigurationsService} from "../../../../swagger/services/widgets-configurations.service";
import {WidgetsConfigurationsWcRead} from "../../../../swagger/models/widgets-configurations-wc-read";
import {WidgetsConfigurationsWcWrite} from "../../../../swagger/models/widgets-configurations-wc-write";
import {SpinnerService} from "../../../services/spinner.service";
import {ToastrService} from "ngx-toastr";
import {WidgetsLabelsService} from "../../../../swagger/services/widgets-labels.service";
import {WidgetsLabelsWlRead} from "../../../../swagger/models/widgets-labels-wl-read";
import {WidgetsLabelsWlWrite} from "../../../../swagger/models/widgets-labels-wl-write";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-tab2-cmp',
  templateUrl: './tab2-cmp.component.html',
  styleUrls: ['./tab2-cmp.component.scss']
})
export class Tab2CmpComponent implements OnInit {
  public registrationType: boolean = false;
  public widgetConfig: WidgetsConfigurationsWcRead;
  @Input() widgetLabel: WidgetsLabelsWlRead;
  @Input() langList: Lang[];
  @Input() currentUser: UsersUserRead;
  @Output() saveWidgetLabel: EventEmitter<WidgetsLabelsWlRead> = new EventEmitter();
  @Output() showSpinner:EventEmitter<any> = new EventEmitter();
  public currenUserLang: Lang = {} as Lang;
  @Input() loading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private userService: UsersService,
    private widgetConfigService: WidgetsConfigurationsService
  ) {

  }

  ngOnInit(): void {
    this.currenUserLang.code = this.currentUser.langCode;
    this.registrationType = this.currentUser.limitAppointments > 0;
    this.loadWidgetConfig();
  }

  getSourceImage(image: string) {
    return "../../../../assets/images/booking-online/advanced/" + this.currenUserLang.code + "/" + image;
  }


  saveData(): void {
    this.showSpinner.emit(true);
    this.loading = true;
    this.prepareUserData();
    this.prepareWidgetConfigData();
    this.saveWidgetLabel.emit(this.widgetLabel);
  }

  prepareUserData(): void {
    let userPostData: UsersService.PutUsersItemParams = {} as UsersService.PutUsersItemParams;
    userPostData.users = {} as UsersUserInputUserWrite;
    userPostData.id = this.currentUser.id.toString();
    userPostData.users.limitAppointments = this.currentUser.limitAppointments;
    userPostData.users.limitTime = this.currentUser.limitTime;
    userPostData.users.limitOptions = this.currentUser.limitOptions ? 2 : 0;
    userPostData.users.timezonesId = this.currentUser.timezonesId.toString();
    userPostData.users.wizardCompleted = this.currentUser.wizardCompleted;
    this.saveUserData(userPostData);
  }

  prepareWidgetConfigData(): void {
    let widgetPostData: WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams = {} as WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams;
    widgetPostData.widgetsConfigurations = {} as WidgetsConfigurationsWcWrite;
    widgetPostData.widgetsConfigurations.registrationType = parseInt(this.widgetConfig.registrationType.toString());
    widgetPostData.widgetsConfigurations.mandatoryComments = this.widgetConfig.mandatoryComments;
    widgetPostData.widgetsConfigurations.showComments = this.widgetConfig.showComments;
    widgetPostData.widgetsConfigurations.showFreeSlots = parseInt(this.widgetConfig.showFreeSlots.toString());
    widgetPostData.widgetsConfigurations.anyAgenda = parseInt(this.widgetConfig.anyAgenda.toString());
    if (this.widgetConfig.id != undefined) {
      widgetPostData.id = this.widgetConfig.id.toString();
      this.saveWidgetConfigData(widgetPostData);
    } else {
      this.createWidgetConfigData(widgetPostData.widgetsConfigurations);
    }
  }



  saveUserData(userData: UsersService.PutUsersItemParams): void {
    this.userService.putUsersItem(userData).subscribe((resp) => {
      console.log(resp);
      this.currentUser = resp;
    }, error => {
      console.error(error);
    });
  }

  saveWidgetConfigData(widgetData: WidgetsConfigurationsService.PutWidgetsConfigurationsItemParams): void {
    this.widgetConfigService.putWidgetsConfigurationsItem(widgetData).subscribe((response) => {
      console.log(response);
      this.widgetConfig = response;
    }, error => {
      console.error(error);
    });
  }

  createWidgetConfigData(widgetData: WidgetsConfigurationsWcWrite): void {
    this.widgetConfigService.postWidgetsConfigurationsCollection(widgetData).subscribe((response) => {
      this.widgetConfig = response;
    }, error => {
      console.error(error);
    });
  }

  loadWidgetConfig(): void {
    let params: WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams = {} as WidgetsConfigurationsService.GetWidgetsConfigurationsCollectionParams;
    this.widgetConfigService.getWidgetsConfigurationsCollection(params).subscribe((resp) => {
      if (resp[0] != undefined) {
        this.widgetConfig = resp[0];
      } else {
        this.widgetConfig = {} as WidgetsConfigurationsWcRead;
      }
      this.loading = false;
    }, error => {
      console.error(error);
    })
  }


  selectLang($event): void {
    this.currenUserLang = $event;
    this.widgetLabel.languagesId = $event.id;
  }

  compareNumbers(numb1: number, numb2: number) {
    return numb1 === numb2;
  }

  compareLang(lang1: Lang, lang2: Lang) {
    return lang1.id === lang2.id;
  }


}
