/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TimezonesTimeZoneRead } from '../models/timezones-time-zone-read';
@Injectable({
  providedIn: 'root',
})
class TimezonesService extends __BaseService {
  static readonly getTimezonesCollectionPath = '/api/timezones';
  static readonly getTimezonesItemPath = '/api/timezones/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Timezones resources.
   * @param params The `TimezonesService.GetTimezonesCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Timezones collection response
   */
  getTimezonesCollectionResponse(params: TimezonesService.GetTimezonesCollectionParams): __Observable<__StrictHttpResponse<Array<TimezonesTimeZoneRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/timezones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TimezonesTimeZoneRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Timezones resources.
   * @param params The `TimezonesService.GetTimezonesCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Timezones collection response
   */
  getTimezonesCollection(params: TimezonesService.GetTimezonesCollectionParams): __Observable<Array<TimezonesTimeZoneRead>> {
    return this.getTimezonesCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<TimezonesTimeZoneRead>)
    );
  }

  /**
   * retrives specific client  data
   * @param id undefined
   * @return Timezones resource response
   */
  getTimezonesItemResponse(id: string): __Observable<__StrictHttpResponse<TimezonesTimeZoneRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/timezones/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TimezonesTimeZoneRead>;
      })
    );
  }
  /**
   * retrives specific client  data
   * @param id undefined
   * @return Timezones resource response
   */
  getTimezonesItem(id: string): __Observable<TimezonesTimeZoneRead> {
    return this.getTimezonesItemResponse(id).pipe(
      __map(_r => _r.body as TimezonesTimeZoneRead)
    );
  }
}

module TimezonesService {

  /**
   * Parameters for getTimezonesCollection
   */
  export interface GetTimezonesCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }
}

export { TimezonesService }
