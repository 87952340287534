<div class="card bg-gradient-danger card-img-holder text-white">
  <div class="card-body">
    <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>
    <h4 class="font-weight-normal mb-3">{{title}}
      <i [ngClass]="'mdi '+icon+' mdi-24px'"></i>
    </h4>
    <h2 class="mb-5">{{dataNumber}}</h2>
    <h6 class="card-text">{{dataText}}</h6>
  </div>
</div>
