/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ApikeysApikeyRead } from '../models/apikeys-apikey-read';
import { ApikeysApikeyWrite } from '../models/apikeys-apikey-write';
@Injectable({
  providedIn: 'root',
})
class ApikeysService extends __BaseService {
  static readonly getApikeysCollectionPath = '/api/apikeys';
  static readonly postApikeysCollectionPath = '/api/apikeys';
  static readonly getApikeysItemPath = '/api/apikeys/{id}';
  static readonly deleteApikeysItemPath = '/api/apikeys/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Apikeys resources.
   * @param params The `ApikeysService.GetApikeysCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Apikeys collection response
   */
  getApikeysCollectionResponse(params: ApikeysService.GetApikeysCollectionParams): __Observable<__StrictHttpResponse<Array<ApikeysApikeyRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/apikeys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ApikeysApikeyRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Apikeys resources.
   * @param params The `ApikeysService.GetApikeysCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Apikeys collection response
   */
  getApikeysCollection(params: ApikeysService.GetApikeysCollectionParams): __Observable<Array<ApikeysApikeyRead>> {
    return this.getApikeysCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<ApikeysApikeyRead>)
    );
  }

  /**
   * Creates a Apikeys resource.
   * @param apikeys The new Apikeys resource
   * @return Apikeys resource created
   */
  postApikeysCollectionResponse(apikeys?: ApikeysApikeyWrite): __Observable<__StrictHttpResponse<ApikeysApikeyRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = apikeys;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/apikeys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApikeysApikeyRead>;
      })
    );
  }
  /**
   * Creates a Apikeys resource.
   * @param apikeys The new Apikeys resource
   * @return Apikeys resource created
   */
  postApikeysCollection(apikeys?: ApikeysApikeyWrite): __Observable<ApikeysApikeyRead> {
    return this.postApikeysCollectionResponse(apikeys).pipe(
      __map(_r => _r.body as ApikeysApikeyRead)
    );
  }

  /**
   * retrives specific event bkt data
   * @param id undefined
   * @return Apikeys resource response
   */
  getApikeysItemResponse(id: string): __Observable<__StrictHttpResponse<ApikeysApikeyRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/apikeys/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApikeysApikeyRead>;
      })
    );
  }
  /**
   * retrives specific event bkt data
   * @param id undefined
   * @return Apikeys resource response
   */
  getApikeysItem(id: string): __Observable<ApikeysApikeyRead> {
    return this.getApikeysItemResponse(id).pipe(
      __map(_r => _r.body as ApikeysApikeyRead)
    );
  }

  /**
   * Delete specific event bkt data
   * @param id undefined
   */
  deleteApikeysItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/apikeys/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific event bkt data
   * @param id undefined
   */
  deleteApikeysItem(id: string): __Observable<null> {
    return this.deleteApikeysItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ApikeysService {

  /**
   * Parameters for getApikeysCollection
   */
  export interface GetApikeysCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }
}

export { ApikeysService }
