import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import data from "../../../assets/json/weekDay.json";
import {ScheduleModel} from "../../models/schedule.model";
import moment from 'moment';

interface WeekDay {
  dayName: string;
  dayNumber: number;
}

const START_TIME = "00:00";
const END_TIME = "24:00";
const TIME_INTERVAL = 60;
const TIME_MIN_INTERVAL = 15;

@Component({
  selector: 'app-schedule-cmp',
  templateUrl: './schedule-cmp.component.html',
  styleUrls: ['./schedule-cmp.component.scss']
})
export class ScheduleCmpComponent implements OnInit {
  public weekDays: WeekDay[] = data as WeekDay[];
  public selectedInterval: number = TIME_INTERVAL;
  public hourList: string[] = [];
  public timeIntervalList: number[] = [];
  public scheduleList: ScheduleModel[] = [];
  @Input() showMobile = false;
  @Output() scheduleChange: EventEmitter<ScheduleModel[]> = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {
    this.fillHours();
    this.fillTimeIntervalList();
    if (this.showMobile) {
      this.fillScheduleList();
    }
  }

  public emitScheduleChange(): void {
    this.scheduleChange.emit(this.scheduleList);
  }

  // tslint:disable-next-line:typedef
  public fillHours() {
    this.hourList = [];
    const startDate = moment(START_TIME, 'HH:mm');
    const endDate = moment(END_TIME, 'HH:mm');
    while (startDate < endDate) {
      this.hourList.push(startDate.format('HH:mm'));
      startDate.add(this.selectedInterval, 'minutes').format('HH:mm');
    }
    this.hourList.push(END_TIME);
    console.log(this.hourList);
  }

  private fillTimeIntervalList(): void {
    this.timeIntervalList = [];
    this.selectedInterval = TIME_INTERVAL;
    let interval = TIME_MIN_INTERVAL;
    while (interval <= TIME_INTERVAL) {
      this.timeIntervalList.push(interval);
      interval += TIME_MIN_INTERVAL;
    }
  }

  public fillScheduleList(): void {
    this.scheduleList = [];
    if (this.scheduleList.length === 0) {
      this.weekDays.forEach((item) => {
        const schedule: ScheduleModel = {} as ScheduleModel;
        schedule.day = item.dayNumber;
        schedule.type = 1;
        this.scheduleList.push(schedule);
      });
    }
  }

  public addSchedule(day: WeekDay): void {
    const schedule: ScheduleModel = {} as ScheduleModel;
    schedule.day = day.dayNumber;
    schedule.startTime = undefined;
    schedule.endTime = undefined;
    schedule.type = 1;
    this.scheduleList.push(schedule);
  }

  public removeSchedule(index: number): void {
    this.scheduleList.splice(index, 1);
  }

  public showRemoveIcon(dayNumber: number): boolean {
    let count = 0;
    this.scheduleList.find((item) => {
      if (item.day === dayNumber) {
        count++;
      }
    });
    return count > 1;
  }

  public isDayChecked(day: number): boolean {
    const found = this.scheduleList.findIndex((item) => {
      return (item.day === day && item.type === 1 && item.startTime !== undefined && item.endTime !== undefined);
    });
    return found !== undefined && found !== -1;
  }

  public checkDay(checked: boolean, day: number): void {
    this.scheduleList.forEach((item) => {
      if (day === item.day) {
        item.type = checked ? 1 : 3;
      }
    });
  }

  public disableHours(startTime: string, hour: string): boolean {
    const start = moment(startTime, 'HH:mm');
    const hourMoment = moment(hour, 'HH:mm');
    return hourMoment <= start;
  }

  public changeInterval(event:any): void {
    this.selectedInterval = event;
    this.fillHours();
  }

  public foundScheduleInlist(schedule: ScheduleModel): boolean {
    const found = this.scheduleList.find(item => (item.day === schedule.day && item.startTime === schedule.startTime && item.endTime === schedule.endTime));
    return found === undefined;
  }

  public addTimeSlotForAllDay(checked: boolean, indexHour: number): void {
    if (checked) {
      this.weekDays.forEach((item) => {
        const schedule: ScheduleModel = {} as ScheduleModel;
        schedule.day = item.dayNumber;
        schedule.startTime = this.hourList[indexHour];
        schedule.endTime = this.hourList[indexHour + 1];
        schedule.type = 1;
        if (this.foundScheduleInlist(schedule)) {
          this.scheduleList.push(schedule);
        }
      });
    } else {
      this.scheduleList = this.scheduleList.filter((item) => {
        return (item.startTime !== this.hourList[indexHour] && item.endTime !== this.hourList[indexHour + 1]);
      });
    }
    this.emitScheduleChange();
  }

  public addAllTimeSlotForOneDay(checked: boolean, day: number): void {
    if (checked) {
      let i = 0;
      while ((i + 1) < this.hourList.length) {
        const schedule: ScheduleModel = {} as ScheduleModel;
        schedule.day = day;
        schedule.startTime = this.hourList[i];
        schedule.endTime = this.hourList[i + 1];
        schedule.type = 1;
        if (this.foundScheduleInlist(schedule)) {
          this.scheduleList.push(schedule);
        }
        i++;
      }
    } else {
      this.scheduleList = this.scheduleList.filter((item) => {
        return (item.day !== day);
      });
    }
    this.emitScheduleChange();
  }

  public timeSlotDayCheck(indexHour: number, day: number): boolean {
    const find = this.scheduleList.findIndex((item) => {
      return ((item.day === day) && (item.startTime === this.hourList[indexHour] && item.endTime === this.hourList[indexHour + 1]));
    });
    return find !== undefined && find !== -1;
  }

  public dayCheck(day: number): boolean {
    const elements = this.scheduleList.filter((item) => {
      return (item.day === day);
    });
    return (elements.length + 1) === this.hourList.length;
  }

  public timeSlotCheck(indexHour: number): boolean {
    const elements = this.scheduleList.filter((item) => {
      return (item.startTime === this.hourList[indexHour] && item.endTime === this.hourList[indexHour + 1]);
    });
    return (elements.length) === this.weekDays.length;
  }

  public addTimeSlotForSelectedDay(checked: boolean, indexHour: number, day: number): void {
    if (checked) {
      const schedule: ScheduleModel = {} as ScheduleModel;
      schedule.day = day;
      schedule.startTime = this.hourList[indexHour];
      schedule.endTime = this.hourList[indexHour + 1];
      schedule.type = 1;
      if (this.foundScheduleInlist(schedule)) {
        this.scheduleList.push(schedule);
      }
    } else {
      const found = this.scheduleList.findIndex((item) => {
        return ((item.day === day) && (item.startTime === this.hourList[indexHour] && item.endTime === this.hourList[indexHour + 1]));
      });
      if (found !== undefined && found !== -1) {
        this.scheduleList.splice(found, 1);
      }
    }
    this.emitScheduleChange();

  }

  public fillAllScheduleList(checked: boolean): void {
    this.weekDays.forEach((item) => {
      this.addAllTimeSlotForOneDay(checked, item.dayNumber);
    });
    this.emitScheduleChange();
  }


}
