import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

export const CONSOLE_LOG = "log"
export const CONSOLE_INFO = "info"
export const CONSOLE_ERROR = "error"


@Injectable()
export class BaseService {
  private logger: boolean = environment.log;

  constructor() {
  }


  log(type: string = CONSOLE_LOG, force: boolean = false, ...data: any[]): any {
    if (this.logger || force) {
      if (console.hasOwnProperty(type) && typeof console[type] == 'function') {
        console[type](this.getClassName() + " log:", data);
      }
    }
  }


  getLocal(key: string) {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      resolve(JSON.parse(localStorage.getItem(key)));
    });
  }

  setLocal(key: string, item: any) {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      localStorage.setItem(key, JSON.stringify(item))
      resolve(true);
    });
  }

  clearLocal() {
    let lthis = this;
    return new Promise(function (resolve) {
        let returned = [];
        let todelete = [
          "current_auth", "current_user"
        ];
        for (let item of todelete) {
          lthis.removeLocal(item).then(
            (res) => {
              returned.push(res);
              if (returned.length >= todelete.length) {
                resolve(returned);
              }
            },
            (err) => {
              returned.push(err);
              if (returned.length >= todelete.length) {
                resolve(returned);
              }
            }
          );

        }
      }
    );
  }

  removeLocal(key: string) {
    let lthis = this;
    return new Promise(function (resolve, reject) {
      localStorage.remove(key).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          lthis.log(CONSOLE_ERROR, null, "Unable remove from native storage", err);
          reject(err);
        }
      );
    });
  }

  getClassName() {
    return this.constructor.name;
  }
}
