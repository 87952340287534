import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentTableComponent} from './document-table.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatTooltipModule,
        MatIconModule,
        MatTableModule,
    ],
  exports: [DocumentTableComponent],
  declarations: [DocumentTableComponent]
})
export class DocumentTableModule { }
