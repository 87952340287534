import {Component, Input, OnInit} from '@angular/core';
import data from "../../../../assets/json/lang.json";
import {ApikeysApikeyRead} from "../../../../swagger/models/apikeys-apikey-read";
import {ApikeysService} from "../../../../swagger/services/apikeys.service";
import {environment} from "../../../../environments/environment";
import {UsersService} from "../../../../swagger/services/users.service";
import {UsersUserRead} from "../../../../swagger/models/users-user-read";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ServicesService} from "../../../../swagger/services/services.service";
import {ServicesServicesList} from "../../../../swagger/models/services-services-list";
import {AgendasService} from "../../../../swagger/services/agendas.service";
import {AgendasAgendaList} from "../../../../swagger/models/agendas-agenda-list";
import {Lang} from "../../../pages/booking-online/booking-online.component";
import {ClipboardService} from "ngx-clipboard";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-tab1-cmp',
  templateUrl: './tab1-cmp.component.html',
  styleUrls: ['./tab1-cmp.component.scss']
})
export class Tab1CmpComponent implements OnInit {
  @Input() langList: Lang[];
  @Input() apiKeys: ApikeysApikeyRead[] = []
  public selectedLang: Lang;
  @Input() currentUser: UsersUserRead;
  public widgetUrl: string;
  public servicesList: ServicesServicesList[] = [];
  public agendaList: AgendasAgendaList[] = [];
  public selectedServices: ServicesServicesList[] = [];
  public servicesParseList: any[] = [];
  public selectedAgendas: AgendasAgendaList[] = [];
  public agendaParseList: any[] = [];
  public loading: boolean = false;
  public color: string = "red";
  public imgUrl: any;
  public msg: string;


  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private servicesApiService: ServicesService,
    private agendaService: AgendasService,
    private clipBoardService: ClipboardService,
    private translateService: TranslateService,
    private toast: ToastrService
  ) {
    this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/booking-online/logo_facebook.svg'));
    this.matIconRegistry.addSvgIcon('google', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/booking-online/logo_mybusiness.svg'));
    this.matIconRegistry.addSvgIcon('instagram', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/booking-online/logo_instagram.svg'));
  }

  ngOnInit(): void {
    this.findLang(this.currentUser.langCode);
    this.loadServices();
    this.loadAgendas();
  }


  public getWidgetUrl(extras: boolean = false): string {
    this.widgetUrl = environment.bookititUrl + "/" + this.selectedLang.code + "/" + environment.widget_url_part + "/" + this.apiKeys[0].widgetkeypub;
    if (!extras) {
      this.widgetUrl += "#services";
    } else {
      if (this.selectedServices.length > 0) {
        this.widgetUrl += "/";
        console.log(this.servicesParseList);
        this.servicesParseList.forEach((item, idx) => {
          if ((idx+1) != this.servicesParseList.length) {
            this.widgetUrl += item + "-";
          } else {
            this.widgetUrl += item;
          }
        });
      }
      if (this.selectedAgendas.length > 0) {
        this.widgetUrl += "/";
        this.agendaParseList.forEach((item, idx) => {
          if ((idx+1) != this.agendaParseList.length) {
            this.widgetUrl += item + "-";
          } else {
            this.widgetUrl += item;
          }
        });
      }
    }
    return this.widgetUrl;
  }

  public selectLang($event) {
    this.selectedLang = $event;
  }


  findLang(langCode: string): void {
    this.selectedLang = this.langList.find((item) => {
      return item.code === langCode;
    });
  }

  loadServices(): void {
    const params: ServicesService.GetServicesCollectionParams = {} as ServicesService.GetServicesCollectionParams;
    this.servicesApiService.getServicesCollection(params).subscribe((resp) => {
      this.servicesList = resp;
    }, error => {
      console.error(error);
    });
  }

  loadAgendas(): void {
    const params: AgendasService.GetAgendasCollectionParams = {} as AgendasService.GetAgendasCollectionParams;
    this.agendaService.getAgendasCollection(params).subscribe((resp) => {
      this.agendaList = resp;
    }, error => {
      console.error(error);
    });
  }

  public getIntegrationCode(showExtra: boolean = false): string {
    return ` <div id="idBktWidgetBody"></div>

        <script type="text/javascript">
            var bkt_init_widget = {
                type: "default",
                srvsrc: "${environment.bookititUrl}",
                publickey: "${this.apiKeys[0].widgetkeypub}",
                lang: "${this.selectedLang ? this.selectedLang.code : ''}",
                scroll: false,
                services: ${showExtra ? JSON.stringify(this.servicesParseList) : [""]},
                agendas:${showExtra ? JSON.stringify(this.agendaParseList) : [""]},
            };

            var oScriptHtmlElement = document.createElement("script");
            oScriptHtmlElement.setAttribute("type", "text/javascript");
            oScriptHtmlElement.setAttribute("src", bkt_init_widget.srvsrc+"/js/widgets/loader.js?v="+(new Date().getFullYear())+(new Date().getMonth())+(new Date().getDate())+(new Date()).getHours());
            (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(oScriptHtmlElement);
        </script>`
  }

  public selectService(checked: boolean, serv: ServicesServicesList) {
    this.servicesParseList = [];
    if (checked) {
      this.selectedServices.push(serv);
    } else {
      const found = this.selectedServices.findIndex((item) => {
        return (item.id === serv.id);
      });
      if (found !== undefined && found !== -1) {
        this.selectedServices.splice(found, 1);
      }
    }
    this.selectedServices.forEach(item => {
      this.servicesParseList.push(item.id.toString());
    });
    console.log(this.selectedServices);
  }

  public selectAgenda(checked: boolean, agenda: AgendasAgendaList) {
    this.agendaParseList = [];
    if (checked) {
      this.selectedAgendas.push(agenda);
    } else {
      const found = this.selectedAgendas.findIndex((item) => {
        return (item.id === agenda.id);
      });
      if (found !== undefined && found !== -1) {
        this.selectedAgendas.splice(found, 1);
      }
    }
    this.selectedAgendas.forEach(item => {
      this.agendaParseList.push(item.id);
    });
    console.log(this.selectedAgendas);
  }

  public checkAgendaAvailability(agenda: AgendasAgendaList) {
    let tempAgendaList: AgendasAgendaList[] = [];
    this.selectedServices.forEach((item) => {
      item.agendas.forEach((next) => {
        tempAgendaList.push(next)
      });
    });
    const found = tempAgendaList.findIndex((item) => {
      return item.id == agenda.id
    })
    if (found !== undefined && found !== -1) {
      return true
    }
    return false;

  }


  selectFile(event: any) { //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.imgUrl = reader.result;
    }
  }

  copyTextToClipboard(text:string) {
    this.clipBoardService.copyFromContent(text);
    this.toast.success(this.translateService.instant('wizard.copy_text'));
  }

}
