/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CalendarConfCalRead } from '../models/calendar-conf-cal-read';
import { CalendarConfCalWrite } from '../models/calendar-conf-cal-write';
@Injectable({
  providedIn: 'root',
})
class CalendarConfService extends __BaseService {
  static readonly getCalendarConfCollectionPath = '/api/calendar-confs';
  static readonly postCalendarConfCollectionPath = '/api/calendar-confs';
  static readonly getCalendarConfItemPath = '/api/calendar-confs/{id}';
  static readonly putCalendarConfItemPath = '/api/calendar-confs/{id}';
  static readonly deleteCalendarConfItemPath = '/api/calendar-confs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of CalendarConf resources.
   * @param params The `CalendarConfService.GetCalendarConfCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return CalendarConf collection response
   */
  getCalendarConfCollectionResponse(params: CalendarConfService.GetCalendarConfCollectionParams): __Observable<__StrictHttpResponse<Array<CalendarConfCalRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendar-confs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CalendarConfCalRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of CalendarConf resources.
   * @param params The `CalendarConfService.GetCalendarConfCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return CalendarConf collection response
   */
  getCalendarConfCollection(params: CalendarConfService.GetCalendarConfCollectionParams): __Observable<Array<CalendarConfCalRead>> {
    return this.getCalendarConfCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<CalendarConfCalRead>)
    );
  }

  /**
   * Creates a CalendarConf resource.
   * @param calendarConf The new CalendarConf resource
   * @return CalendarConf resource created
   */
  postCalendarConfCollectionResponse(calendarConf?: CalendarConfCalWrite): __Observable<__StrictHttpResponse<CalendarConfCalRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = calendarConf;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/calendar-confs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CalendarConfCalRead>;
      })
    );
  }
  /**
   * Creates a CalendarConf resource.
   * @param calendarConf The new CalendarConf resource
   * @return CalendarConf resource created
   */
  postCalendarConfCollection(calendarConf?: CalendarConfCalWrite): __Observable<CalendarConfCalRead> {
    return this.postCalendarConfCollectionResponse(calendarConf).pipe(
      __map(_r => _r.body as CalendarConfCalRead)
    );
  }

  /**
   * retrives specific calendar conf data
   * @param id undefined
   * @return CalendarConf resource response
   */
  getCalendarConfItemResponse(id: string): __Observable<__StrictHttpResponse<CalendarConfCalRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendar-confs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CalendarConfCalRead>;
      })
    );
  }
  /**
   * retrives specific calendar conf data
   * @param id undefined
   * @return CalendarConf resource response
   */
  getCalendarConfItem(id: string): __Observable<CalendarConfCalRead> {
    return this.getCalendarConfItemResponse(id).pipe(
      __map(_r => _r.body as CalendarConfCalRead)
    );
  }

  /**
   * Update specific calendar conf data
   * @param params The `CalendarConfService.PutCalendarConfItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `calendarConf`: The updated CalendarConf resource
   *
   * @return CalendarConf resource updated
   */
  putCalendarConfItemResponse(params: CalendarConfService.PutCalendarConfItemParams): __Observable<__StrictHttpResponse<CalendarConfCalRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.calendarConf;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/calendar-confs/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CalendarConfCalRead>;
      })
    );
  }
  /**
   * Update specific calendar conf data
   * @param params The `CalendarConfService.PutCalendarConfItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `calendarConf`: The updated CalendarConf resource
   *
   * @return CalendarConf resource updated
   */
  putCalendarConfItem(params: CalendarConfService.PutCalendarConfItemParams): __Observable<CalendarConfCalRead> {
    return this.putCalendarConfItemResponse(params).pipe(
      __map(_r => _r.body as CalendarConfCalRead)
    );
  }

  /**
   * Delete specific calendar conf data
   * @param id undefined
   */
  deleteCalendarConfItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/calendar-confs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete specific calendar conf data
   * @param id undefined
   */
  deleteCalendarConfItem(id: string): __Observable<null> {
    return this.deleteCalendarConfItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CalendarConfService {

  /**
   * Parameters for getCalendarConfCollection
   */
  export interface GetCalendarConfCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putCalendarConfItem
   */
  export interface PutCalendarConfItemParams {
    id: string;

    /**
     * The updated CalendarConf resource
     */
    calendarConf?: CalendarConfCalWrite;
  }
}

export { CalendarConfService }
