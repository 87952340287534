/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UsersUserRead } from '../models/users-user-read';
import { UsersUserWrite } from '../models/users-user-write';
import { UsersUserInputUserWrite } from '../models/users-user-input-user-write';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly meUsersItemPath = '/api/me';
  static readonly registerUsersCollectionPath = '/api/register';
  static readonly getUsersCollectionPath = '/api/users';
  static readonly postUsersCollectionPath = '/api/users';
  static readonly getUsersItemPath = '/api/users/{id}';
  static readonly putUsersItemPath = '/api/users/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns current user data
   * @param id undefined
   * @return Users resource response
   */
  meUsersItemResponse(id: string): __Observable<__StrictHttpResponse<UsersUserRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersUserRead>;
      })
    );
  }
  /**
   * Returns current user data
   * @param id undefined
   * @return Users resource response
   */
  meUsersItem(id: string): __Observable<UsersUserRead> {
    return this.meUsersItemResponse(id).pipe(
      __map(_r => _r.body as UsersUserRead)
    );
  }

  /**
   * Creates a Users resource.
   * @param users The new Users resource
   * @return Users resource created
   */
  registerUsersCollectionResponse(users?: UsersUserWrite): __Observable<__StrictHttpResponse<UsersUserRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = users;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersUserRead>;
      })
    );
  }
  /**
   * Creates a Users resource.
   * @param users The new Users resource
   * @return Users resource created
   */
  registerUsersCollection(users?: UsersUserWrite): __Observable<UsersUserRead> {
    return this.registerUsersCollectionResponse(users).pipe(
      __map(_r => _r.body as UsersUserRead)
    );
  }

  /**
   * Retrieves the collection of Users resources.
   * @param params The `UsersService.GetUsersCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Users collection response
   */
  getUsersCollectionResponse(params: UsersService.GetUsersCollectionParams): __Observable<__StrictHttpResponse<Array<UsersUserRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsersUserRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Users resources.
   * @param params The `UsersService.GetUsersCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * @return Users collection response
   */
  getUsersCollection(params: UsersService.GetUsersCollectionParams): __Observable<Array<UsersUserRead>> {
    return this.getUsersCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<UsersUserRead>)
    );
  }

  /**
   * Creates a Users resource.
   * @param users The new Users resource
   * @return Users resource created
   */
  postUsersCollectionResponse(users?: UsersUserWrite): __Observable<__StrictHttpResponse<UsersUserRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = users;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersUserRead>;
      })
    );
  }
  /**
   * Creates a Users resource.
   * @param users The new Users resource
   * @return Users resource created
   */
  postUsersCollection(users?: UsersUserWrite): __Observable<UsersUserRead> {
    return this.postUsersCollectionResponse(users).pipe(
      __map(_r => _r.body as UsersUserRead)
    );
  }

  /**
   * retrives specific user data
   * @param id undefined
   * @return Users resource response
   */
  getUsersItemResponse(id: string): __Observable<__StrictHttpResponse<UsersUserRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/users/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersUserRead>;
      })
    );
  }
  /**
   * retrives specific user data
   * @param id undefined
   * @return Users resource response
   */
  getUsersItem(id: string): __Observable<UsersUserRead> {
    return this.getUsersItemResponse(id).pipe(
      __map(_r => _r.body as UsersUserRead)
    );
  }

  /**
   * Update specific user data
   * @param params The `UsersService.PutUsersItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `users`: The updated Users resource
   *
   * @return Users resource updated
   */
  putUsersItemResponse(params: UsersService.PutUsersItemParams): __Observable<__StrictHttpResponse<UsersUserRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.users;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/users/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsersUserRead>;
      })
    );
  }
  /**
   * Update specific user data
   * @param params The `UsersService.PutUsersItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `users`: The updated Users resource
   *
   * @return Users resource updated
   */
  putUsersItem(params: UsersService.PutUsersItemParams): __Observable<UsersUserRead> {
    return this.putUsersItemResponse(params).pipe(
      __map(_r => _r.body as UsersUserRead)
    );
  }
}

module UsersService {

  /**
   * Parameters for getUsersCollection
   */
  export interface GetUsersCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
  }

  /**
   * Parameters for putUsersItem
   */
  export interface PutUsersItemParams {
    id: string;

    /**
     * The updated Users resource
     */
    users?: UsersUserInputUserWrite;
  }
}

export { UsersService }
