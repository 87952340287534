import {Injectable} from '@angular/core';
import {DialogData} from '../components/delete-dialog/delete-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
import {DocumentAddComponent} from '../components/document-add/document-add.component';

@Injectable()
export class DialogService {

  constructor(
    public dialog: MatDialog,
  ) {
  }

  showDialog(component: ComponentType<any>, data: DialogData): Promise<boolean> {
    const dialogRef = this.dialog.open(component, {
      width: '32vw',
      height: '25vh',
      minWidth: '275px',
      minHeight: '275px',
      maxHeight: '230px',
      data
    });
    return new Promise<boolean>((resolve, reject) => {
      dialogRef.afterClosed().subscribe({
        next: result => {
          resolve(result);
        }, error: error => {
          reject(error);
        }
      });
    });
  }

  showAddDocumentDialog(clientId?: string, historyDataId?: string): Promise<boolean> {
    const dialogRef = this.dialog.open(DocumentAddComponent, {
      width: '30vw',
      height: '160px',
      minWidth: '275px',
      minHeight: '185px',
      data: {
        clientId,
        historyDataId,
      }
    });

    return new Promise<boolean>((resolve, reject) => {
      dialogRef.afterClosed().subscribe({
        next: result => {
          resolve(result);
        }, error: error => {
          reject(error);
        }
      });
    });
  }
}
