import {Injectable} from '@angular/core';
import {DocumentService} from '../../swagger/services/document.service';
import {ApiConfiguration as __Configuration} from '../../swagger/api-configuration';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable as __Observable} from 'rxjs/internal/Observable';

// TDP: Apaño de crear un servicio heredando del creado por Swagger, ya que aparentemente no hay forma de
// que Swagger nos genere el servicio con el código necesario para los eventos de HTTP y otras funcionalidades "custom"

@Injectable({
    providedIn: 'root',
})
export class DocumentServiceWithEvents extends DocumentService {

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  checkAccess(): __Observable<any> {
    const params = this.newParams();

    return this.http.get(
      this.rootUrl + `/api/documents_access`,
      {
        responseType: 'json',
      }
    );
  }

  uploadNewDocument(document: FormData): __Observable<HttpEvent<any>> {
    const params = this.newParams();

    // TDP: set multipart header to avoid AuthInterceptor to overwrite it with app/json
    return this.http.post(
      this.rootUrl + `/api/documents`,
      document,
      {
        headers: {
//          'Content-Type': 'multipart/form-data',
        },
        params,
        observe: 'events',
        reportProgress: true,
        responseType: 'json',
      }
    );
  }
}
