/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SchedulesSchedulesRead } from '../models/schedules-schedules-read';
import { SchedulesSchedulesWrite } from '../models/schedules-schedules-write';
@Injectable({
  providedIn: 'root',
})
class SchedulesService extends __BaseService {
  static readonly getSchedulesCollectionPath = '/api/schedules';
  static readonly postSchedulesCollectionPath = '/api/schedules';
  static readonly getSchedulesItemPath = '/api/schedules/{id}';
  static readonly putSchedulesItemPath = '/api/schedules/{id}';
  static readonly wizardSchedulesCollectionPath = '/api/wizard/schedule';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of Schedules resources.
   * @return Schedules collection response
   */
  getSchedulesCollectionResponse(): __Observable<__StrictHttpResponse<Array<SchedulesSchedulesRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SchedulesSchedulesRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of Schedules resources.
   * @return Schedules collection response
   */
  getSchedulesCollection(): __Observable<Array<SchedulesSchedulesRead>> {
    return this.getSchedulesCollectionResponse().pipe(
      __map(_r => _r.body as Array<SchedulesSchedulesRead>)
    );
  }

  /**
   * Creates a Schedules resource.
   * @param schedules The new Schedules resource
   * @return Schedules resource created
   */
  postSchedulesCollectionResponse(schedules?: SchedulesSchedulesWrite): __Observable<__StrictHttpResponse<SchedulesSchedulesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = schedules;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulesSchedulesRead>;
      })
    );
  }
  /**
   * Creates a Schedules resource.
   * @param schedules The new Schedules resource
   * @return Schedules resource created
   */
  postSchedulesCollection(schedules?: SchedulesSchedulesWrite): __Observable<SchedulesSchedulesRead> {
    return this.postSchedulesCollectionResponse(schedules).pipe(
      __map(_r => _r.body as SchedulesSchedulesRead)
    );
  }

  /**
   * retrives specific schedules data
   * @param id undefined
   * @return Schedules resource response
   */
  getSchedulesItemResponse(id: string): __Observable<__StrictHttpResponse<SchedulesSchedulesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/schedules/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulesSchedulesRead>;
      })
    );
  }
  /**
   * retrives specific schedules data
   * @param id undefined
   * @return Schedules resource response
   */
  getSchedulesItem(id: string): __Observable<SchedulesSchedulesRead> {
    return this.getSchedulesItemResponse(id).pipe(
      __map(_r => _r.body as SchedulesSchedulesRead)
    );
  }

  /**
   * Update specific schedules data
   * @param params The `SchedulesService.PutSchedulesItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `schedules`: The updated Schedules resource
   *
   * @return Schedules resource updated
   */
  putSchedulesItemResponse(params: SchedulesService.PutSchedulesItemParams): __Observable<__StrictHttpResponse<SchedulesSchedulesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.schedules;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/schedules/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulesSchedulesRead>;
      })
    );
  }
  /**
   * Update specific schedules data
   * @param params The `SchedulesService.PutSchedulesItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `schedules`: The updated Schedules resource
   *
   * @return Schedules resource updated
   */
  putSchedulesItem(params: SchedulesService.PutSchedulesItemParams): __Observable<SchedulesSchedulesRead> {
    return this.putSchedulesItemResponse(params).pipe(
      __map(_r => _r.body as SchedulesSchedulesRead)
    );
  }

  /**
   * Create a schedule for wizard
   * @param newSchedule undefined
   * @return Schedules resource created
   */
  wizardSchedulesCollectionResponse(newSchedule?: {agenda?: number, schedule?: Array<any>}): __Observable<__StrictHttpResponse<SchedulesSchedulesRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = newSchedule;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/wizard/schedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulesSchedulesRead>;
      })
    );
  }
  /**
   * Create a schedule for wizard
   * @param newSchedule undefined
   * @return Schedules resource created
   */
  wizardSchedulesCollection(newSchedule?: {agenda?: number, schedule?: Array<any>}): __Observable<SchedulesSchedulesRead> {
    return this.wizardSchedulesCollectionResponse(newSchedule).pipe(
      __map(_r => _r.body as SchedulesSchedulesRead)
    );
  }
}

module SchedulesService {

  /**
   * Parameters for putSchedulesItem
   */
  export interface PutSchedulesItemParams {
    id: string;

    /**
     * The updated Schedules resource
     */
    schedules?: SchedulesSchedulesWrite;
  }
}

export { SchedulesService }
