<div class="mat-body">
  <mat-dialog-content>
    <div class="close-container" mat-dialog-close="false">
      <img src="assets/images/icons/icono-cerrar-gris.svg">
    </div>
    <section>
      <div class="dialog-container">
        <h5 class="text-title mt-4 font-weight-bold">{{title}}</h5>
        <span class="text-content">{{content}}</span>
      </div>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button class="btn delete-button" (click)="deleteClick()">{{buttonText}}</button>
  </mat-dialog-actions>
</div>
