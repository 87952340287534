<div class="row mt-2 mb-4" *ngIf="documents?.length>0">
  <div class="col-12">
    <table mat-table [dataSource]="documents" class="w-100">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'documents.list.name' | translate }}</th>
        <td mat-cell *matCellDef="let document"> {{document.name}} </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>{{ 'documents.list.created' | translate }}</th>
        <td mat-cell *matCellDef="let document"> {{document.created|date:'dd-MM-YYYY, h:mm a'}} </td>
      </ng-container>
      <ng-container matColumnDef="history">
        <th mat-header-cell *matHeaderCellDef>{{ 'documents.list.history' | translate }}</th>
        <td mat-cell *matCellDef="let document"> {{document.history?.clientHistory.name || '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'general.actions' | translate }}</th>
        <td mat-cell *matCellDef="let document">
          <div class="icon-img" (click)="clickAction(onDocumentClicked, document)" matTooltip="{{ 'documents.tooltip.download'|translate}}"
               matTooltipPosition=above matTooltipHideDelay="100">
            <img src="assets/images/icons/icono-descargar.svg">
          </div>
          <div class="icon-img ml-2" (click)="clickAction(onDeleteClicked, document)" matTooltip="{{ 'documents.tooltip.remove'|translate}}" matTooltipPosition="above" matTooltipHideDelay="100">
            <img src="assets/images/icons/icono-borrar.svg">
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  </div>
</div>
