<div class="container-md bg-white">
  <ngx-spinner></ngx-spinner>
  <div class="card">
    <div class="card-header">
      <app-spinner *ngIf="loading"></app-spinner>
      <div class="row mb-3" *ngIf="!loading">
        <h4 class="page-title">
          {{'customer_track.title'|translate}}
        </h4>
        <h4 class="page-title2 font-weight-bold">
          {{customer.name}}
        </h4>
      </div>
    </div>
    <div class="form-container" *ngIf="!loading">
      <div class="form-container card-body">
        <div class="row" *ngIf="listHistory.length>0">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="input-select wd-20">
                <mat-label>{{'customer_track.select_text'|translate}}</mat-label>
                <mat-select (selectionChange)="selectHistory($event.value)">
                  <mat-option *ngFor="let history of listHistory" [value]="history">
                    {{history.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button type="button"
                      class="btn btn-inverse-success ml-4"
                      (click)="showAddHistory=true">{{'customer_track.add_button2'| translate}}</button>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="showAddHistory">
          <div class="col-12">
            <div class="form-group">
              <mat-form-field appearance="outline" class="input-history wd-40">
                <mat-label>{{'customer_track.field_name'|translate}}</mat-label>
                <input matInput [formControl]="historyControl">
              </mat-form-field>
              <button mat-button type="button"
                      class="btn btn-inverse-success ml-2"
                      [disabled]="!historyControl.valid"
                      (click)="addCustomerHistory()">{{'customer_track.add_button'| translate}}</button>
            </div>
          </div>
        </div>
        <div class="row history-container mt-4" *ngIf="!!selectedHistory">
          <div class="col-12 mb-3">
            <div class="content-container" *ngIf="!editToogleHistory">
              <div class="title-container">
                <h2 class="history-text mr-2">{{selectedHistory.name}}</h2>
                <div class="icon-img ml-1" (click)="editSelectedHistory()">
                  <img class="icon-img mb-2" src="assets/images/icons/icono-editar.svg">
                </div>
                <div class="icon-img ml-1" (click)="deleteSelectedHistory()">
                  <img class="icon-img mb-2" src="assets/images/icons/icono-borrar.svg">
                </div>
              </div>
              <div class="button-container">
                <button mat-button type="button" class="btn btn-outline-dark-bkt ml-1" style="border: 1px solid black; border-radius: 5px;" (click)="downloadHistory()">{{'customer_track.download_history'| translate}}</button>
                <button mat-button type="button" class="btn btn-outline-dark-bkt ml-4" style="border: 1px solid black; border-radius: 5px;"(click)="addDocument()">{{'documents.add.button'| translate}}</button>
              </div>
            </div>
            <div class="edit-container form-group" *ngIf="editToogleHistory">
              <mat-form-field appearance="outline" class="input-history wd-20">
                <input matInput [formControl]="selectedHistoryControl">
              </mat-form-field>
              <button type="button"
                      class="btn btn-inverse-success ml-4"
                      (click)="updateSelectedHistory()"
                      [disabled]="!selectedHistoryControl.valid"
              >{{'customer_track.save'| translate}}</button>
              <button type="button"
                      class="btn btn-danger ml-2"
                      (click)="editToogleHistory=false">{{'widget-history.cancel_button'| translate}}</button>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group textarea-field">
              <mat-form-field appearance="fill">
                <mat-label>{{'customer_track.placeholder_textarea'|translate}}</mat-label>
                <textarea matInput [formControl]="dataControl" [errorStateMatcher]="matcher"></textarea>
              </mat-form-field>
            </div>
          </div>
          <input #fileDropRef type="file" (change)="selectFile($event)" [multiple]="false" accept="*/*" [attr.title]="''">
          <div class="col-3" *ngFor="let newDocument of newDocuments,let i=index" style="max-width: fit-content;margin-bottom: 10px;">
            <app-document-item [document]="newDocument" (onDeleteClicked)="removeNewDocument(i)"></app-document-item>
          </div>
          <div class="col-12">
            <div class="button-container">
              <button type="button" [disabled]="!dataControl.valid"
                      (click)="addHistoryData()"
                      class="btn btn-inverse-success ml-2">{{'customer_track.add_button'| translate}}</button>
            </div>
          </div>
        </div>
        <div class="row data-container mt-5" *ngIf="historyDataList.length>0">
          <mat-list class="w-100 ml-4 mt-2"
                    *ngFor="let item of historyDataList | paginate:{itemsPerPage:this.itemsPerPage,currentPage:this.page,totalItems:this.totalItem}  ">
            <app-history-widget [historyItem]="item" (editItem)="updateHistoryData($event)" (refreshItem)="refreshHistoryData($event)"
                                (deleteItem)="deleteHistoryData($event)" (downloadDocument)="downloadDocument($event)" (deleteDocument)="deleteDocument($event)"></app-history-widget>
            <mat-divider></mat-divider>
          </mat-list>
          <section class="section-container">
            <div class="pagination-container mt-4">
              <pagination-controls class="pagination-custom"
                                   (pageChange)="navigateHistoryDataList($event)"
                                   [nextLabel]="next_msg"
                                   [previousLabel]="prev_msg"
              ></pagination-controls>
            </div>
          </section>
        </div>
      </div>
      <div class="card-body mt-lg-5" *ngIf="listHistory.length == 0 && showAddHistory == false">
        <section>
          <div class="icon-content mb-2">
            <img src="assets/images/icons/icono-historial.svg">
            <h3 class="page-title mt-4">{{'customer_track.empty_msg_text'|translate}}</h3>
            <button type="button"
                    class="btn btn-inverse-success btn-empty mt-4"
                    (click)="showAddHistory=true">{{'customer_track.empty_button_text'| translate}}</button>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
