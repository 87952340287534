<div class="container mt-5 mb-5">
 <div class="text-container">
   <h3 class="mb-5">{{'booking-online.political_title'|translate}}</h3>
   <h6>{{'booking-online.privacy_text1'|translate}}</h6>
   <h6>{{'booking-online.privacy_text2'|translate}}</h6>
   <h6>{{'booking-online.privacy_text3'|translate}}</h6>
   <h6>{{'booking-online.privacy_text4'|translate}}</h6>
 </div>
  <div class="option-container mt-5">
    <mat-radio-group class="option-container" [(ngModel)]="widgetLabel.privacyPolicyShow">
      <mat-radio-button [value]=1> {{'booking-online.privacy_option1'|translate}}</mat-radio-button>
      <div class="option-container especial-option mt-3">
        <mat-radio-button [value]=2 class="mt-3">{{'booking-online.privacy_option2'|translate}}</mat-radio-button>
        <mat-form-field class="mt-3" appearance="fill">
          <textarea matInput [disabled]="widgetLabel.privacyPolicyShow!=2" placeholder="{{'booking-online.text_area_placeholder2'|translate}}" [(ngModel)]="widgetLabel.privacyPolicyText"></textarea>
        </mat-form-field>
      </div>
      <div class="option-container input-container mt-3">
        <mat-radio-button [value]=3> {{'booking-online.privacy_option3'|translate}}</mat-radio-button>
        <mat-form-field class="mt-3" appearance="fill" >
          <input matInput type="text" [disabled]="widgetLabel.privacyPolicyShow!=3" [(ngModel)]="widgetLabel.privacyPolicyUrl">
        </mat-form-field>
      </div>
    </mat-radio-group>
  </div>
  <div class="button-container mt-3">
    <button class="btn btn-bkt__outline-dark mr-3">{{'widget-history.cancel_button'|translate}}</button>
    <button class="btn btn-inverse-success" (click)="saveData()">{{'general.save'|translate}}</button>
  </div>
</div>
