import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/user-pages/login/login.component';
import {AuthGuard} from './guard/auth.guard';
import {IframeComponent} from './pages/iframe/iframe.component';
import {GoogleSyncComponent} from './pages/google-sync/google-sync.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {CustomerHistoryComponent} from './pages/customer-history/customer-history.component';
import {LogoutComponent} from './pages/user-pages/logout/logout.component';
import {WizardComponent} from './pages/wizard/wizard.component';
import {BookingOnlineComponent} from './pages/booking-online/booking-online.component';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'iframe',
    component: IframeComponent
  },
  {
    path: 'google-sync/:id',
    component: GoogleSyncComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-history/:id',
    component: CustomerHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'google-sync',
    component: GoogleSyncComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'wizard',
    component: WizardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-online',
    component: BookingOnlineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [AuthGuard]
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
