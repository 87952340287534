import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent {
  @Input() document: any;
  @Output() onDocumentClicked: EventEmitter<any>  = new EventEmitter();
  @Output() onDeleteClicked: EventEmitter<any>  = new EventEmitter();

  constructor() { }

  clickAction(ev: EventEmitter<any>, document: any): void {
    ev.emit(document);
  }
}
