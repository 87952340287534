/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClientHistoryClientHRead } from '../models/client-history-client-hread';
import { ClientHistoryClientHWrite } from '../models/client-history-client-hwrite';
@Injectable({
  providedIn: 'root',
})
class ClientHistoryService extends __BaseService {
  static readonly getClientHistoryCollectionPath = '/api/client-histories';
  static readonly postClientHistoryCollectionPath = '/api/client-histories';
  static readonly getClientHistoryItemPath = '/api/client-histories/{id}';
  static readonly putClientHistoryItemPath = '/api/client-histories/{id}';
  static readonly deleteClientHistoryItemPath = '/api/client-histories/{id}';
  static readonly exportClientHistoryItemPath = '/api/export/history/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Retrieves the collection of ClientHistory resources.
   * @param params The `ClientHistoryService.GetClientHistoryCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clients.id`:
   *
   * @return ClientHistory collection response
   */
  getClientHistoryCollectionResponse(params: ClientHistoryService.GetClientHistoryCollectionParams): __Observable<__StrictHttpResponse<Array<ClientHistoryClientHRead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.itemsPerPage != null) __params = __params.set('itemsPerPage', params.itemsPerPage.toString());
    if (params.clientsId != null) __params = __params.set('clients.id', params.clientsId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/client-histories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClientHistoryClientHRead>>;
      })
    );
  }
  /**
   * Retrieves the collection of ClientHistory resources.
   * @param params The `ClientHistoryService.GetClientHistoryCollectionParams` containing the following parameters:
   *
   * - `page`: The collection page number
   *
   * - `itemsPerPage`: The number of items per page
   *
   * - `clients.id`:
   *
   * @return ClientHistory collection response
   */
  getClientHistoryCollection(params: ClientHistoryService.GetClientHistoryCollectionParams): __Observable<Array<ClientHistoryClientHRead>> {
    return this.getClientHistoryCollectionResponse(params).pipe(
      __map(_r => _r.body as Array<ClientHistoryClientHRead>)
    );
  }

  /**
   * Creates a ClientHistory resource.
   * @param clientHistory The new ClientHistory resource
   * @return ClientHistory resource created
   */
  postClientHistoryCollectionResponse(clientHistory?: ClientHistoryClientHWrite): __Observable<__StrictHttpResponse<ClientHistoryClientHRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = clientHistory;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/client-histories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryClientHRead>;
      })
    );
  }
  /**
   * Creates a ClientHistory resource.
   * @param clientHistory The new ClientHistory resource
   * @return ClientHistory resource created
   */
  postClientHistoryCollection(clientHistory?: ClientHistoryClientHWrite): __Observable<ClientHistoryClientHRead> {
    return this.postClientHistoryCollectionResponse(clientHistory).pipe(
      __map(_r => _r.body as ClientHistoryClientHRead)
    );
  }

  /**
   * retrives specific client history data
   * @param id undefined
   * @return ClientHistory resource response
   */
  getClientHistoryItemResponse(id: string): __Observable<__StrictHttpResponse<ClientHistoryClientHRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/client-histories/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryClientHRead>;
      })
    );
  }
  /**
   * retrives specific client history data
   * @param id undefined
   * @return ClientHistory resource response
   */
  getClientHistoryItem(id: string): __Observable<ClientHistoryClientHRead> {
    return this.getClientHistoryItemResponse(id).pipe(
      __map(_r => _r.body as ClientHistoryClientHRead)
    );
  }

  /**
   * retrives specific client history data
   * @param params The `ClientHistoryService.PutClientHistoryItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientHistory`: The updated ClientHistory resource
   *
   * @return ClientHistory resource updated
   */
  putClientHistoryItemResponse(params: ClientHistoryService.PutClientHistoryItemParams): __Observable<__StrictHttpResponse<ClientHistoryClientHRead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.clientHistory;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/client-histories/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClientHistoryClientHRead>;
      })
    );
  }
  /**
   * retrives specific client history data
   * @param params The `ClientHistoryService.PutClientHistoryItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientHistory`: The updated ClientHistory resource
   *
   * @return ClientHistory resource updated
   */
  putClientHistoryItem(params: ClientHistoryService.PutClientHistoryItemParams): __Observable<ClientHistoryClientHRead> {
    return this.putClientHistoryItemResponse(params).pipe(
      __map(_r => _r.body as ClientHistoryClientHRead)
    );
  }

  /**
   * retrives specific client history data
   * @param id undefined
   */
  deleteClientHistoryItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/client-histories/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * retrives specific client history data
   * @param id undefined
   */
  deleteClientHistoryItem(id: string): __Observable<null> {
    return this.deleteClientHistoryItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * retrieves a xls export file
   * @param id undefined
   */
  exportClientHistoryItemResponse(id: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/export/history/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * retrieves a xls export file
   * @param id undefined
   */
  exportClientHistoryItem(id: string): __Observable<Blob> {
    return this.exportClientHistoryItemResponse(id).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ClientHistoryService {

  /**
   * Parameters for getClientHistoryCollection
   */
  export interface GetClientHistoryCollectionParams {

    /**
     * The collection page number
     */
    page?: number;

    /**
     * The number of items per page
     */
    itemsPerPage?: number;
    clientsId?: any;
  }

  /**
   * Parameters for putClientHistoryItem
   */
  export interface PutClientHistoryItemParams {
    id: string;

    /**
     * The updated ClientHistory resource
     */
    clientHistory?: ClientHistoryClientHWrite;
  }
}

export { ClientHistoryService }
