import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HistoryWidgetComponent} from './history-widget.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {DocumentTableModule} from '../document-table/document-table.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    DocumentTableModule,
  ],
  exports: [HistoryWidgetComponent],
  declarations: [HistoryWidgetComponent]
})
export class HistoryWidgetModule { }
