import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ClientHistoryDataHistoryRead} from '../../../swagger/models/client-history-data-history-read';
import {DocumentHistoryRead} from '../../../swagger/models/document-history-read';
import {DialogService} from '../../providers/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ClientHistoryDataService} from '../../../swagger/services/client-history-data.service';

@Component({
  selector: 'app-history-widget',
  templateUrl: './history-widget.component.html',
  styleUrls: ['./history-widget.component.scss']
})
export class HistoryWidgetComponent {
  @Input() historyItem: ClientHistoryDataHistoryRead;
  @Input() toggleEdit = false;
  @Output() refreshItem: EventEmitter<ClientHistoryDataHistoryRead> = new EventEmitter();
  @Output() editItem: EventEmitter<ClientHistoryDataHistoryRead> = new EventEmitter();
  @Output() deleteItem: EventEmitter<ClientHistoryDataHistoryRead> = new EventEmitter();
  @Output() downloadDocument: EventEmitter<{
    document: DocumentHistoryRead
  }> = new EventEmitter();
  @Output() deleteDocument: EventEmitter<{
    history: ClientHistoryDataHistoryRead,
    document: DocumentHistoryRead
  }> = new EventEmitter();
  public noteControl = new FormControl('', [Validators.required]);

  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private toast: ToastrService,
    private historyDataService: ClientHistoryDataService,
  ) {
  }

  addDocumentAction(): void {
    const ctx = this;
    const historyDataId = ctx.historyItem.id?.toString();
    ctx.dialogService.showAddDocumentDialog(undefined, historyDataId).then((result) => {
      if (result === true) {
        ctx.historyDataService.getClientHistoryDataItem(historyDataId).subscribe({
          next: (updatedItem) => {
            ctx.refreshItem.emit(updatedItem);
            ctx.historyItem = updatedItem;
          },
          error: (err: any) => {
            console.log('error reloading history data', err);
            ctx.toast.error(
              ctx.translateService.instant('documents.error.list'),
              ctx.translateService.instant('general.general_error_msg')
            );
          }
        });
      }
    }, reason => {
      ctx.toast.error(reason, ctx.translateService.instant('general.general_error_msg'));
    });
  }

  toogleEditAction(): void {
    this.toggleEdit = true;
  }

  editItemAction(): void {
    this.toggleEdit = false;
    this.editItem.emit(this.historyItem);
  }

  deleteItemAction(): void {
    this.deleteItem.emit(this.historyItem);
  }

  documentClickedAction(document: DocumentHistoryRead): void {
    this.downloadDocument.emit({document});
  }

  deleteDocumentAction(document: DocumentHistoryRead): void {
    this.deleteDocument.emit({
      history: this.historyItem,
      document,
    });
  }
}
